import { BaseService } from "./base.service";
import { MContact } from '../modeles';

class ContactService extends BaseService {

  PATH = "/contact/";

  async newContact(contact: MContact): Promise<boolean | null> {
    return await this._add(this.PATH + "new-contact", { ...contact });
  }
}

export const contactService = new ContactService();