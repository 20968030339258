import { useState, useEffect, useCallback } from "react";
import { MCampaignAsset } from "../../../../modeles";
import { adminAssetsService } from "../../../../services";

async function updateAssetStateBack(assetId: number | string, state: boolean) {
  if (state) {
    await adminAssetsService.validate(assetId.toString());
  } else {
    await adminAssetsService.devalidate(assetId.toString());
  }
}

export function useAssets() {
  const [assets, _setAssets] = useState<MCampaignAsset[]>([]);

  useEffect(() => {
    adminAssetsService.listing().then((data) => {
      if (data) {
        _setAssets(data);
      }
    });
  }, []);

  const updateAssetState = useCallback(async (asset: MCampaignAsset, state: boolean) => {
    if (asset.id) {
      await updateAssetStateBack(asset.id, state);
      asset.isValide = state;
      _setAssets([...assets]);
    }
  }, [assets]);

  const onValidate = useCallback(async (asset: MCampaignAsset) => {
    await updateAssetState(asset, true);
  }, [updateAssetState]);

  const onDevalidate = useCallback(async (asset: MCampaignAsset) => {
    await updateAssetState(asset, false);
  }, [updateAssetState]);

  return {
    assets,
    onValidate,
    onDevalidate,
  }
}