import { useEffect } from "react";
import ReactGA from 'react-ga4';

export function setPageTitle(title: string) {
  document.title = title;
}

export function usePageTitle(title: string) {
  ReactGA.send({
    hitType: 'pageView',
    pagePath: window.location.pathname,
    title: 'title'
  });

  useEffect(() => {
    if (window.location.pathname.startsWith("/superadmin/")) {
      setPageTitle("[SA] " + title + " - Yso Network");
    } else {
      setPageTitle(title + " - Yso Network");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
