/**
 * The following table lists the options for the various bid response protocols that could be supported by
 * an exchange.
 */
enum Protocols {
  VAST_1_0 = 1,
  VAST_2_0 = 2,
  VAST_3_0 = 3,
  VAST_1_0_WRAPPER = 4,
  VAST_2_0_WRAPPER = 5,
  VAST_3_0_WRAPPER = 6,
  VAST_4_0 = 7,
  VAST_4_0_WRAPPER = 8,
  DAAST_1_0 = 9,
  DAAST_1_0_WRAPPER = 10,

  /**
   * Custom extension of protocol
   * YSO_AD format is defined by Yso Corp
   */
  YSO_AD_1_0 = 101,
}

export default Protocols;
