import { Route, Switch, useRouteMatch } from "react-router-dom";

// styles
import './index.scss';

// pages
import { BidderConfigPage } from './pages';

export function BiddingModule() {

  const { path } = useRouteMatch();

  return (
    <div id="bidding">
      <Switch>
        <Route exact path={`${path}/bidder-config`}><BidderConfigPage /></Route>
      </Switch>
    </div>
  );

}