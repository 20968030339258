import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ProtectedComponent } from "@/components";

// styles
import './index.scss';

// pages
import {
  ReportingAdvertisingPage,
  ReportingPublishingPage,
  ReportingBiddingPage,
} from './pages';

export function ReportingModule() {

  const { path } = useRouteMatch();

  return (
    <div id="advertising">
      <Switch>
        <Route exact path={`${path}/advertising`}><ProtectedComponent permission="advertising/reports/read"><ReportingAdvertisingPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/publishing`}><ProtectedComponent permission="publishing/reports/read"><ReportingPublishingPage /></ProtectedComponent></Route>
        <Route exact path={`${path}/bidding`}><ProtectedComponent permission="bidding/reports/read"><ReportingBiddingPage /></ProtectedComponent></Route>
        <Route exact path={path}><Redirect to={`${path}/advertising`} /></Route>
      </Switch>
    </div>
  );

}