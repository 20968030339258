/* eslint-disable object-curly-newline */
interface CountryInterface {
  iso2: string;
  iso3: string;
  name: string;
  isUA: boolean;
  avgECPM?: number;
}

export const COUNTRIES: CountryInterface[] = [ // deploy
  { iso2: 'AD', iso3: 'AND', name: 'Andorra', isUA: true },
  { iso2: 'AE', iso3: 'ARE', name: 'United Arab Emirates', isUA: true, avgECPM: 4 },
  { iso2: 'AF', iso3: 'AFG', name: 'Afghanistan', isUA: true },
  { iso2: 'AG', iso3: 'ATG', name: 'Antigua and Barbuda', isUA: true },
  { iso2: 'AI', iso3: 'AIA', name: 'Anguilla', isUA: true },
  { iso2: 'AL', iso3: 'ALB', name: 'Albania', isUA: true },
  { iso2: 'AM', iso3: 'ARM', name: 'Armenia', isUA: true, avgECPM: 1 },
  { iso2: 'AO', iso3: 'AGO', name: 'Angola', isUA: true },
  { iso2: 'AQ', iso3: 'ATA', name: 'Antarctica', isUA: true },
  { iso2: 'AR', iso3: 'ARG', name: 'Argentina', isUA: true, avgECPM: 1 },
  { iso2: 'AS', iso3: 'ASM', name: 'American Samoa', isUA: true },
  { iso2: 'AT', iso3: 'AUT', name: 'Austria', isUA: true, avgECPM: 4.5 },
  { iso2: 'AU', iso3: 'AUS', name: 'Australia', isUA: true, avgECPM: 9 },
  { iso2: 'AW', iso3: 'ABW', name: 'Aruba', isUA: true },
  { iso2: 'AX', iso3: 'ALA', name: 'Åland Islands', isUA: true },
  { iso2: 'AZ', iso3: 'AZE', name: 'Azerbaijan', isUA: true, avgECPM: 0.75 },
  { iso2: 'BA', iso3: 'BIH', name: 'Bosnia and Herzegovina', isUA: true, avgECPM: 0.5 },
  { iso2: 'BB', iso3: 'BRB', name: 'Barbados', isUA: true },
  { iso2: 'BD', iso3: 'BGD', name: 'Bangladesh', isUA: true, avgECPM: 0.5 },
  { iso2: 'BE', iso3: 'BEL', name: 'Belgium', isUA: true, avgECPM: 2.5 },
  { iso2: 'BF', iso3: 'BFA', name: 'Burkina Faso', isUA: true },
  { iso2: 'BG', iso3: 'BGR', name: 'Bulgaria', isUA: true, avgECPM: 1 },
  { iso2: 'BH', iso3: 'BHR', name: 'Bahrain', isUA: true },
  { iso2: 'BI', iso3: 'BDI', name: 'Burundi', isUA: true },
  { iso2: 'BJ', iso3: 'BEN', name: 'Benin', isUA: true },
  { iso2: 'BL', iso3: 'BLM', name: 'Saint Barthélemy', isUA: true },
  { iso2: 'BM', iso3: 'BMU', name: 'Bermuda', isUA: true },
  { iso2: 'BN', iso3: 'BRN', name: 'Brunei Darussalam', isUA: true },
  { iso2: 'BO', iso3: 'BOL', name: 'Bolivia', isUA: true, avgECPM: 0.5 },
  { iso2: 'BQ', iso3: 'BES', name: 'Bonaire, Sint Eustatius and Saba', isUA: true },
  { iso2: 'BR', iso3: 'BRA', name: 'Brazil', isUA: true, avgECPM: 1.5 },
  { iso2: 'BS', iso3: 'BHS', name: 'Bahamas', isUA: true },
  { iso2: 'BT', iso3: 'BTN', name: 'Bhutan', isUA: true },
  { iso2: 'BV', iso3: 'BVT', name: 'Bouvet Island', isUA: true },
  { iso2: 'BW', iso3: 'BWA', name: 'Botswana', isUA: true },
  { iso2: 'BY', iso3: 'BLR', name: 'Belarus', isUA: true, avgECPM: 0.75 },
  { iso2: 'BZ', iso3: 'BLZ', name: 'Belize', isUA: true },
  { iso2: 'CA', iso3: 'CAN', name: 'Canada', isUA: true, avgECPM: 10 },
  { iso2: 'CC', iso3: 'CCK', name: 'Cocos (Keeling) Islands', isUA: true },
  { iso2: 'CD', iso3: 'COD', name: 'Congo (Democratic Republic of the)', isUA: true },
  { iso2: 'CF', iso3: 'CAF', name: 'Central African Republic', isUA: true },
  { iso2: 'CG', iso3: 'COG', name: 'Congo', isUA: true },
  { iso2: 'CH', iso3: 'CHE', name: 'Switzerland', isUA: true, avgECPM: 6 },
  { iso2: 'CI', iso3: 'CIV', name: "Côte d'Ivoire", isUA: true },
  { iso2: 'CK', iso3: 'COK', name: 'Cook Islands', isUA: true },
  { iso2: 'CL', iso3: 'CHL', name: 'Chile', isUA: true, avgECPM: 1.75 },
  { iso2: 'CM', iso3: 'CMR', name: 'Cameroon', isUA: true },
  { iso2: 'CN', iso3: 'CHN', name: 'China', isUA: true },
  { iso2: 'CO', iso3: 'COL', name: 'Colombia', isUA: true, avgECPM: 1 },
  { iso2: 'CR', iso3: 'CRI', name: 'Costa Rica', isUA: true, avgECPM: 1.25 },
  { iso2: 'CU', iso3: 'CUB', name: 'Cuba', isUA: true },
  { iso2: 'CV', iso3: 'CPV', name: 'Cabo Verde', isUA: true },
  { iso2: 'CW', iso3: 'CUW', name: 'Curaçao', isUA: true },
  { iso2: 'CX', iso3: 'CXR', name: 'Christmas Island', isUA: true },
  { iso2: 'CY', iso3: 'CYP', name: 'Cyprus', isUA: true },
  { iso2: 'CZ', iso3: 'CZE', name: 'Czechia', isUA: true, avgECPM: 1.5 },
  { iso2: 'DE', iso3: 'DEU', name: 'Germany', isUA: true, avgECPM: 4.5 },
  { iso2: 'DJ', iso3: 'DJI', name: 'Djibouti', isUA: true },
  { iso2: 'DK', iso3: 'DNK', name: 'Denmark', isUA: true, avgECPM: 5 },
  { iso2: 'DM', iso3: 'DMA', name: 'Dominica', isUA: true },
  { iso2: 'DO', iso3: 'DOM', name: 'Dominican Republic', isUA: true, avgECPM: 1 },
  { iso2: 'DZ', iso3: 'DZA', name: 'Algeria', isUA: true, avgECPM: 0.5 },
  { iso2: 'EC', iso3: 'ECU', name: 'Ecuador', isUA: true, avgECPM: 0.75 },
  { iso2: 'EE', iso3: 'EST', name: 'Estonia', isUA: true, avgECPM: 1.75 },
  { iso2: 'EG', iso3: 'EGY', name: 'Egypt', isUA: true, avgECPM: 1 },
  { iso2: 'EH', iso3: 'ESH', name: 'Western Sahara', isUA: true },
  { iso2: 'ER', iso3: 'ERI', name: 'Eritrea', isUA: true },
  { iso2: 'ES', iso3: 'ESP', name: 'Spain', isUA: true, avgECPM: 1.5 },
  { iso2: 'ET', iso3: 'ETH', name: 'Ethiopia', isUA: true },
  { iso2: 'FI', iso3: 'FIN', name: 'Finland', isUA: true, avgECPM: 1.5 },
  { iso2: 'FJ', iso3: 'FJI', name: 'Fiji', isUA: true },
  { iso2: 'FK', iso3: 'FLK', name: 'Falkland Islands', isUA: true },
  { iso2: 'FM', iso3: 'FSM', name: 'Micronesia', isUA: true },
  { iso2: 'FO', iso3: 'FRO', name: 'Faroe Islands', isUA: true },
  { iso2: 'FR', iso3: 'FRA', name: 'France', isUA: true, avgECPM: 2.5 },
  { iso2: 'GA', iso3: 'GAB', name: 'Gabon', isUA: true },
  { iso2: 'GB', iso3: 'GBR', name: 'United Kingdom', isUA: true, avgECPM: 5.5 },
  { iso2: 'GD', iso3: 'GRD', name: 'Grenada', isUA: true },
  { iso2: 'GE', iso3: 'GEO', name: 'Georgia', isUA: true, avgECPM: 1 },
  { iso2: 'GF', iso3: 'GUF', name: 'French Guiana', isUA: true },
  { iso2: 'GG', iso3: 'GGY', name: 'Guernsey', isUA: true },
  { iso2: 'GH', iso3: 'GHA', name: 'Ghana', isUA: true },
  { iso2: 'GI', iso3: 'GIB', name: 'Gibraltar', isUA: true },
  { iso2: 'GL', iso3: 'GRL', name: 'Greenland', isUA: true },
  { iso2: 'GM', iso3: 'GMB', name: 'Gambia', isUA: true },
  { iso2: 'GN', iso3: 'GIN', name: 'Guinea', isUA: true },
  { iso2: 'GP', iso3: 'GLP', name: 'Guadeloupe', isUA: true },
  { iso2: 'GQ', iso3: 'GNQ', name: 'Equatorial Guinea', isUA: true },
  { iso2: 'GR', iso3: 'GRC', name: 'Greece', isUA: true, avgECPM: 1 },
  { iso2: 'GS', iso3: 'SGS', name: 'South Georgia and the South Sandwich Islands', isUA: true },
  { iso2: 'GT', iso3: 'GTM', name: 'Guatemala', isUA: true, avgECPM: 0.75 },
  { iso2: 'GU', iso3: 'GUM', name: 'Guam', isUA: true },
  { iso2: 'GW', iso3: 'GNB', name: 'Guinea-Bissau', isUA: true },
  { iso2: 'GY', iso3: 'GUY', name: 'Guyana', isUA: true },
  { iso2: 'HK', iso3: 'HKG', name: 'Hong Kong', isUA: true, avgECPM: 10 },
  { iso2: 'HM', iso3: 'HMD', name: 'Heard Island and McDonald Islands', isUA: true },
  { iso2: 'HN', iso3: 'HND', name: 'Honduras', isUA: true, avgECPM: 0.75 },
  { iso2: 'HR', iso3: 'HRV', name: 'Croatia', isUA: true, avgECPM: 1 },
  { iso2: 'HT', iso3: 'HTI', name: 'Haiti', isUA: true },
  { iso2: 'HU', iso3: 'HUN', name: 'Hungary', isUA: true, avgECPM: 1 },
  { iso2: 'ID', iso3: 'IDN', name: 'Indonesia', isUA: true, avgECPM: 1 },
  { iso2: 'IE', iso3: 'IRL', name: 'Ireland', isUA: true },
  { iso2: 'IL', iso3: 'ISR', name: 'Israel', isUA: true, avgECPM: 2.5 },
  { iso2: 'IM', iso3: 'IMN', name: 'Isle of Man', isUA: true },
  { iso2: 'IN', iso3: 'IND', name: 'India', isUA: true, avgECPM: 0.75 },
  { iso2: 'IO', iso3: 'IOT', name: 'British Indian Ocean Territory', isUA: true },
  { iso2: 'IQ', iso3: 'IRQ', name: 'Iraq', isUA: true, avgECPM: 0.75 },
  { iso2: 'IR', iso3: 'IRN', name: 'Iran', isUA: false },
  { iso2: 'IS', iso3: 'ISL', name: 'Iceland', isUA: true },
  { iso2: 'IT', iso3: 'ITA', name: 'Italy', isUA: true, avgECPM: 1.5 },
  { iso2: 'JE', iso3: 'JEY', name: 'Jersey', isUA: true },
  { iso2: 'JM', iso3: 'JAM', name: 'Jamaica', isUA: true },
  { iso2: 'JO', iso3: 'JOR', name: 'Jordan', isUA: true, avgECPM: 0.75 },
  { iso2: 'JP', iso3: 'JPN', name: 'Japan', isUA: true, avgECPM: 10 },
  { iso2: 'KE', iso3: 'KEN', name: 'Kenya', isUA: true, avgECPM: 1.25 },
  { iso2: 'KG', iso3: 'KGZ', name: 'Kyrgyzstan', isUA: true, avgECPM: 1 },
  { iso2: 'KH', iso3: 'KHM', name: 'Cambodia', isUA: true, avgECPM: 1 },
  { iso2: 'KI', iso3: 'KIR', name: 'Kiribati', isUA: true },
  { iso2: 'KM', iso3: 'COM', name: 'Comoros', isUA: true },
  { iso2: 'KN', iso3: 'KNA', name: 'Saint Kitts and Nevis', isUA: true },
  { iso2: 'KP', iso3: 'PRK', name: "Korea (Democratic People's Republic of)", isUA: true },
  { iso2: 'KR', iso3: 'KOR', name: 'Korea', isUA: true, avgECPM: 8 },
  { iso2: 'KW', iso3: 'KWT', name: 'Kuwait', isUA: true },
  { iso2: 'KY', iso3: 'CYM', name: 'Cayman Islands', isUA: true },
  { iso2: 'KZ', iso3: 'KAZ', name: 'Kazakhstan', isUA: true, avgECPM: 1.25 },
  { iso2: 'LA', iso3: 'LAO', name: "Lao People's Democratic Republic", isUA: true, avgECPM: 0.5 },
  { iso2: 'LB', iso3: 'LBN', name: 'Lebanon', isUA: true, avgECPM: 0.5 },
  { iso2: 'LC', iso3: 'LCA', name: 'Saint Lucia', isUA: true },
  { iso2: 'LI', iso3: 'LIE', name: 'Liechtenstein', isUA: true },
  { iso2: 'LK', iso3: 'LKA', name: 'Sri Lanka', isUA: true },
  { iso2: 'LR', iso3: 'LBR', name: 'Liberia', isUA: true },
  { iso2: 'LS', iso3: 'LSO', name: 'Lesotho', isUA: true },
  { iso2: 'LT', iso3: 'LTU', name: 'Lithuania', isUA: true, avgECPM: 1.25 },
  { iso2: 'LU', iso3: 'LUX', name: 'Luxembourg', isUA: true },
  { iso2: 'LV', iso3: 'LVA', name: 'Latvia', isUA: true, avgECPM: 1.25 },
  { iso2: 'LY', iso3: 'LBY', name: 'Libya', isUA: true, avgECPM: 0.75 },
  { iso2: 'MA', iso3: 'MAR', name: 'Morocco', isUA: true, avgECPM: 1 },
  { iso2: 'MC', iso3: 'MCO', name: 'Monaco', isUA: true },
  { iso2: 'MD', iso3: 'MDA', name: 'Moldova', isUA: true, avgECPM: 1 },
  { iso2: 'ME', iso3: 'MNE', name: 'Montenegro', isUA: true, avgECPM: 0.5 },
  { iso2: 'MF', iso3: 'MAF', name: 'Saint Martin', isUA: true },
  { iso2: 'MG', iso3: 'MDG', name: 'Madagascar', isUA: true },
  { iso2: 'MH', iso3: 'MHL', name: 'Marshall Islands', isUA: true },
  { iso2: 'MK', iso3: 'MKD', name: 'North Macedonia', isUA: true, avgECPM: 0.75 },
  { iso2: 'ML', iso3: 'MLI', name: 'Mali', isUA: true },
  { iso2: 'MM', iso3: 'MMR', name: 'Myanmar', isUA: true, avgECPM: 1 },
  { iso2: 'MN', iso3: 'MNG', name: 'Mongolia', isUA: true },
  { iso2: 'MO', iso3: 'MAC', name: 'Macao', isUA: true },
  { iso2: 'MP', iso3: 'MNP', name: 'Northern Mariana Islands', isUA: true },
  { iso2: 'MQ', iso3: 'MTQ', name: 'Martinique', isUA: true },
  { iso2: 'MR', iso3: 'MRT', name: 'Mauritania', isUA: true },
  { iso2: 'MS', iso3: 'MSR', name: 'Montserrat', isUA: true },
  { iso2: 'MT', iso3: 'MLT', name: 'Malta', isUA: true },
  { iso2: 'MU', iso3: 'MUS', name: 'Mauritius', isUA: true },
  { iso2: 'MV', iso3: 'MDV', name: 'Maldives', isUA: true },
  { iso2: 'MW', iso3: 'MWI', name: 'Malawi', isUA: true },
  { iso2: 'MX', iso3: 'MEX', name: 'Mexico', isUA: true, avgECPM: 1.75 },
  { iso2: 'MY', iso3: 'MYS', name: 'Malaysia', isUA: true, avgECPM: 1.5 },
  { iso2: 'MZ', iso3: 'MOZ', name: 'Mozambique', isUA: true },
  { iso2: 'NA', iso3: 'NAM', name: 'Namibia', isUA: true },
  { iso2: 'NC', iso3: 'NCL', name: 'New Caledonia', isUA: true },
  { iso2: 'NE', iso3: 'NER', name: 'Niger', isUA: true },
  { iso2: 'NF', iso3: 'NFK', name: 'Norfolk Island', isUA: true },
  { iso2: 'NG', iso3: 'NGA', name: 'Nigeria', isUA: true, avgECPM: 1.5 },
  { iso2: 'NI', iso3: 'NIC', name: 'Nicaragua', isUA: true, avgECPM: 0.5 },
  { iso2: 'NL', iso3: 'NLD', name: 'Netherlands', isUA: true, avgECPM: 3 },
  { iso2: 'NO', iso3: 'NOR', name: 'Norway', isUA: true },
  { iso2: 'NP', iso3: 'NPL', name: 'Nepal', isUA: true, avgECPM: 0.5 },
  { iso2: 'NR', iso3: 'NRU', name: 'Nauru', isUA: true },
  { iso2: 'NU', iso3: 'NIU', name: 'Niue', isUA: true },
  { iso2: 'NZ', iso3: 'NZL', name: 'New Zealand', isUA: true },
  { iso2: 'OM', iso3: 'OMN', name: 'Oman', isUA: true },
  { iso2: 'PA', iso3: 'PAN', name: 'Panama', isUA: true, avgECPM: 1.25 },
  { iso2: 'PE', iso3: 'PER', name: 'Peru', isUA: true, avgECPM: 1 },
  { iso2: 'PF', iso3: 'PYF', name: 'French Polynesia', isUA: true },
  { iso2: 'PG', iso3: 'PNG', name: 'Papua New Guinea', isUA: true },
  { iso2: 'PH', iso3: 'PHL', name: 'Philippines', isUA: true, avgECPM: 1.5 },
  { iso2: 'PK', iso3: 'PAK', name: 'Pakistan', isUA: true, avgECPM: 0.5 },
  { iso2: 'PL', iso3: 'POL', name: 'Poland', isUA: true, avgECPM: 1.5 },
  { iso2: 'PM', iso3: 'SPM', name: 'Saint Pierre and Miquelon', isUA: true },
  { iso2: 'PN', iso3: 'PCN', name: 'Pitcairn', isUA: true },
  { iso2: 'PR', iso3: 'PRI', name: 'Puerto Rico', isUA: true },
  { iso2: 'PS', iso3: 'PSE', name: 'Palestine', isUA: true, avgECPM: 0.75 },
  { iso2: 'PT', iso3: 'PRT', name: 'Portugal', isUA: true, avgECPM: 1.25 },
  { iso2: 'PW', iso3: 'PLW', name: 'Palau', isUA: true },
  { iso2: 'PY', iso3: 'PRY', name: 'Paraguay', isUA: true, avgECPM: 0.75 },
  { iso2: 'QA', iso3: 'QAT', name: 'Qatar', isUA: true },
  { iso2: 'RE', iso3: 'REU', name: 'Réunion', isUA: true },
  { iso2: 'RO', iso3: 'ROU', name: 'Romania', isUA: true, avgECPM: 1.25 },
  { iso2: 'RS', iso3: 'SRB', name: 'Serbia', isUA: true, avgECPM: 0.75 },
  { iso2: 'RU', iso3: 'RUS', name: 'Russian Federation', isUA: true, avgECPM: 1.5 },
  { iso2: 'RW', iso3: 'RWA', name: 'Rwanda', isUA: true },
  { iso2: 'SA', iso3: 'SAU', name: 'Saudi Arabia', isUA: true, avgECPM: 3 },
  { iso2: 'SB', iso3: 'SLB', name: 'Solomon Islands', isUA: true },
  { iso2: 'SC', iso3: 'SYC', name: 'Seychelles', isUA: true },
  { iso2: 'SD', iso3: 'SDN', name: 'Sudan', isUA: true },
  { iso2: 'SE', iso3: 'SWE', name: 'Sweden', isUA: true, avgECPM: 3.5 },
  { iso2: 'SG', iso3: 'SGP', name: 'Singapore', isUA: true, avgECPM: 5.5 },
  { iso2: 'SH', iso3: 'SHN', name: 'Saint Helena Ascension Island Tristan da Cunha', isUA: true },
  { iso2: 'SI', iso3: 'SVN', name: 'Slovenia', isUA: true },
  { iso2: 'SJ', iso3: 'SJM', name: 'Svalbard and Jan Mayen', isUA: true },
  { iso2: 'SK', iso3: 'SVK', name: 'Slovakia', isUA: true, avgECPM: 1.5 },
  { iso2: 'SL', iso3: 'SLE', name: 'Sierra Leone', isUA: true },
  { iso2: 'SM', iso3: 'SMR', name: 'San Marino', isUA: true },
  { iso2: 'SN', iso3: 'SEN', name: 'Senegal', isUA: true },
  { iso2: 'SO', iso3: 'SOM', name: 'Somalia', isUA: true },
  { iso2: 'SR', iso3: 'SUR', name: 'Suriname', isUA: true },
  { iso2: 'SS', iso3: 'SSD', name: 'South Sudan', isUA: true },
  { iso2: 'ST', iso3: 'STP', name: 'Sao Tome and Principe', isUA: true },
  { iso2: 'SV', iso3: 'SLV', name: 'El Salvador', isUA: true, avgECPM: 0.75 },
  { iso2: 'SX', iso3: 'SXM', name: 'Sint Maarten', isUA: true },
  { iso2: 'SY', iso3: 'SYR', name: 'Syria', isUA: true },
  { iso2: 'SZ', iso3: 'SWZ', name: 'Eswatini', isUA: true },
  { iso2: 'TC', iso3: 'TCA', name: 'Turks and Caicos Islands', isUA: true },
  { iso2: 'TD', iso3: 'TCD', name: 'Chad', isUA: true },
  { iso2: 'TF', iso3: 'ATF', name: 'French Southern Territories', isUA: true },
  { iso2: 'TG', iso3: 'TGO', name: 'Togo', isUA: true },
  { iso2: 'TH', iso3: 'THA', name: 'Thailand', isUA: true, avgECPM: 1.25 },
  { iso2: 'TJ', iso3: 'TJK', name: 'Tajikistan', isUA: true },
  { iso2: 'TK', iso3: 'TKL', name: 'Tokelau', isUA: true },
  { iso2: 'TL', iso3: 'TLS', name: 'Timor-Leste', isUA: true },
  { iso2: 'TM', iso3: 'TKM', name: 'Turkmenistan', isUA: true },
  { iso2: 'TN', iso3: 'TUN', name: 'Tunisia', isUA: true, avgECPM: 0.75 },
  { iso2: 'TO', iso3: 'TON', name: 'Tonga', isUA: true },
  { iso2: 'TR', iso3: 'TUR', name: 'Turkey', isUA: true, avgECPM: 1 },
  { iso2: 'TT', iso3: 'TTO', name: 'Trinidad and Tobago', isUA: true },
  { iso2: 'TV', iso3: 'TUV', name: 'Tuvalu', isUA: true },
  { iso2: 'TW', iso3: 'TWN', name: 'Taiwan', isUA: true, avgECPM: 7 },
  { iso2: 'TZ', iso3: 'TZA', name: 'Tanzania', isUA: true },
  { iso2: 'UA', iso3: 'UKR', name: 'Ukraine', isUA: true, avgECPM: 0.75 },
  { iso2: 'UG', iso3: 'UGA', name: 'Uganda', isUA: true },
  { iso2: 'UM', iso3: 'UMI', name: 'United States Minor Outlying Islands', isUA: true },
  { iso2: 'US', iso3: 'USA', name: 'United States of America', isUA: true, avgECPM: 12 },
  { iso2: 'UY', iso3: 'URY', name: 'Uruguay', isUA: true, avgECPM: 1 },
  { iso2: 'UZ', iso3: 'UZB', name: 'Uzbekistan', isUA: true, avgECPM: 1 },
  { iso2: 'VA', iso3: 'VAT', name: 'Holy See', isUA: true },
  { iso2: 'VC', iso3: 'VCT', name: 'Saint Vincent and the Grenadines', isUA: true },
  { iso2: 'VE', iso3: 'VEN', name: 'Venezuela', isUA: true, avgECPM: 0.5 },
  { iso2: 'VG', iso3: 'VGB', name: 'Virgin Islands (British)', isUA: true },
  { iso2: 'VI', iso3: 'VIR', name: 'Virgin Islands (U.S.)', isUA: true },
  { iso2: 'VN', iso3: 'VNM', name: 'Viet Nam', isUA: true, avgECPM: 1 },
  { iso2: 'VU', iso3: 'VUT', name: 'Vanuatu', isUA: true },
  { iso2: 'WF', iso3: 'WLF', name: 'Wallis and Futuna', isUA: true },
  { iso2: 'WS', iso3: 'WSM', name: 'Samoa', isUA: true },
  { iso2: 'YE', iso3: 'YEM', name: 'Yemen', isUA: true },
  { iso2: 'YT', iso3: 'MYT', name: 'Mayotte', isUA: true },
  { iso2: 'ZA', iso3: 'ZAF', name: 'South Africa', isUA: true, avgECPM: 2.5 },
  { iso2: 'ZM', iso3: 'ZMB', name: 'Zambia', isUA: true },
  { iso2: 'ZW', iso3: 'ZWE', name: 'Zimbabwe', isUA: true },
];

export const COUNTRIES_ISO2_UA: { [code: string]: string } = Object.fromEntries(
  COUNTRIES.filter((e) => e.isUA).map((e) => [e.iso2, e.name]),
);

export const COUNTRIES_ISO2: { [code: string]: string } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso2, e.name]),
);

export const COUNTRIES_ISO2_LABEL_VALUE: { value: string; label: string }[] = COUNTRIES.map((c) => ({
  value: c.iso2,
  label: c.name,
}));

export const COUNTRIES_ISO3: { [code: string]: string } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso3, e.name]),
);

export const COUNTRIES_ISO2_AVG_ECPM: { [code: string]: number } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso2, e.avgECPM || null]),
);

export const COUNTRIES_ISO3_AVG_ECPM: { [code: string]: number } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso3, e.avgECPM || null]),
);

export const COUNTRY_CODES_ISO_2_TO_ISO_3: { [code: string]: string } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso2, e.iso3]),
);

export const COUNTRY_CODES_ISO_3_TO_ISO_2: { [code: string]: string } = Object.fromEntries(
  COUNTRIES.map((e) => [e.iso3, e.iso2]),
);
