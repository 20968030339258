import { useCallback, useEffect, useReducer, useState } from 'react';
import { adsService_SA } from '../../../../services';
import { appsService } from '../../../../services/apps.service';
import { YcCountries, YcEnums } from 'yc-interfaces';

import { uniques } from '../../../../modeles';

export interface AdminAd {
  id: number;
  key: string;
  adPhase: string;
  price: number;
  adPrice: number;
  percent: number;
  standardDeviationPercent: number;
  cpi: number;
  ir: number;
  bidIr: number;
  adIr: number;

  impressions: number;
  clicks: number;
  installs: number;
  gIr: number;
  gCtr: number;
  gCpm: number;
}

const FILTERS_OPTIONS = {
  countries: Object.entries(YcCountries.COUNTRIES_ISO2),
  placementTypes: Object.values(YcEnums.PlacementTypes),
  os: Object.values(YcEnums.OS),
  osVersions: [null, '15', '14', '13', '12', '11', '10', '9', '8', '7', '6', '5'],
  adPhases: [null, YcEnums.AdPhases.LEARNING, YcEnums.AdPhases.PRERUNNING, YcEnums.AdPhases.RUNNING],
  bidAbTests: Object.values(YcEnums.BidAbTests),
}

const INITIAL_STATE = {
  loading: false,
  country: 'US',
  city: 'New York City',
  bundle: 'Ninja Hands - com.YsoCorp.NinjaHands',
  placementType: YcEnums.PlacementTypes.INTERSTITIAL,
  os: YcEnums.OS.ANDROID,
  osVersion: null as string | null,
  hasIfa: true,
  adPhase: null as YcEnums.AdPhases | null,
  bidAbTest: YcEnums.BidAbTests.CONTROL,
  isTestDevice: false,
  ifa: '',
  deviceApps: [] as string[],
  deviceAds: {} as { [key: string]: { id: string; impressions: number; clicks: number; installs: number; } },
  applications: [] as string[],
  ads: [] as AdminAd[],
};

export enum ADS_ACTIONS {
  LOADING = 'LOADING',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
  BUNDLE = 'BUNDLE',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  OS = 'OS',
  OS_VERSION = 'OS_VERSION',
  HAS_IFA = 'HAS_IFA',
  AD_PHASE = 'AD_PHASE',
  BID_AB_TEST = 'BID_AB_TEST',
  IS_TEST_DEVICE = 'IS_TEST_DEVICE',
  IFA = 'IFA',
  DEVICE_APPS = 'DEVICE_APPS',
  DEVICE_ADS = 'DEVICE_ADS',
  APPLICATIONS = 'APPLICATIONS',
  ADS = 'ADS',
}

function reducer(state: typeof INITIAL_STATE, action: { type: ADS_ACTIONS, payload: any }): typeof INITIAL_STATE {
  switch (action.type) {
    case ADS_ACTIONS.LOADING:
      return { ...state, loading: action.payload };
    case ADS_ACTIONS.COUNTRY:
      return { ...state, country: action.payload };
    case ADS_ACTIONS.CITY:
      return { ...state, city: action.payload };
    case ADS_ACTIONS.BUNDLE:
      return { ...state, bundle: action.payload };
    case ADS_ACTIONS.PLACEMENT_TYPE:
      return { ...state, placementType: action.payload };
    case ADS_ACTIONS.OS:
      return { ...state, os: action.payload };
    case ADS_ACTIONS.OS_VERSION:
      return { ...state, osVersion: action.payload };
    case ADS_ACTIONS.HAS_IFA:
      return { ...state, hasIfa: action.payload };
    case ADS_ACTIONS.AD_PHASE:
      return { ...state, adPhase: action.payload };
    case ADS_ACTIONS.BID_AB_TEST:
      return { ...state, bidAbTest: action.payload };
    case ADS_ACTIONS.IS_TEST_DEVICE:
      return { ...state, isTestDevice: action.payload };
    case ADS_ACTIONS.IFA:
      return { ...state, ifa: action.payload };
    case ADS_ACTIONS.DEVICE_APPS:
      return { ...state, deviceApps: action.payload };
    case ADS_ACTIONS.DEVICE_ADS:
      return { ...state, deviceAds: action.payload };
    case ADS_ACTIONS.APPLICATIONS:
      return { ...state, applications: action.payload };
    case ADS_ACTIONS.ADS:
      return { ...state, ads: action.payload };
    default:
      return state;
  }
}

export function useAds() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [filters] = useState(FILTERS_OPTIONS);

  useEffect(() => {
    dispatch({ type: ADS_ACTIONS.LOADING, payload: true });
    appsService.listingBidder().then((data) => {
      dispatch({ type: ADS_ACTIONS.LOADING, payload: false });
      dispatch({ type: ADS_ACTIONS.APPLICATIONS, payload: uniques((data || []).map((app) => `${app.name} - ${app.bundleId}`)) });
      onLoadAds();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadAds = useCallback(async () => {
    dispatch({ type: ADS_ACTIONS.LOADING, payload: true });
    const data = await adsService_SA.listing({
      country: state.country,
      city: state.city,
      bundle: state.bundle.split(' - ')[1],
      placementType: state.placementType,
      os: state.os,
      osVersion: state.osVersion,
      hasIfa: state.hasIfa,
      adPhase: state.adPhase,
      bidAbTest: state.bidAbTest,
      isTestDevice: state.isTestDevice,
      ifa: state.ifa,
      deviceApps: state.deviceApps,
      deviceAds: Object.values(state.deviceAds),
    });
    if (data) {
      dispatch({ type: ADS_ACTIONS.ADS, payload: data });
    }
    dispatch({ type: ADS_ACTIONS.LOADING, payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [state, dispatch]);

  const onSelectCountry = useCallback((country: any) => dispatch({ type: ADS_ACTIONS.COUNTRY, payload: country }), [dispatch]);
  const onChangeCity = useCallback((city: any) => dispatch({ type: ADS_ACTIONS.CITY, payload: city.target.value }), [dispatch]);
  const onSelectBundle = useCallback((bundle: any) => dispatch({ type: ADS_ACTIONS.BUNDLE, payload: bundle }), [dispatch]);
  const onSelectPlacementType = useCallback((placementType: any) => dispatch({ type: ADS_ACTIONS.PLACEMENT_TYPE, payload: placementType }), [dispatch]);
  const onSelectOs = useCallback((os: any) => dispatch({ type: ADS_ACTIONS.OS, payload: os }), [dispatch]);
  const onSelectOsVersion = useCallback((osVersion: any) => dispatch({ type: ADS_ACTIONS.OS_VERSION, payload: osVersion }), [dispatch]);
  const onSelectHasIfa = useCallback((hasIfa: any) => dispatch({ type: ADS_ACTIONS.HAS_IFA, payload: hasIfa }), [dispatch]);
  const onSelectAdPhase = useCallback((adPhase: any) => dispatch({ type: ADS_ACTIONS.AD_PHASE, payload: adPhase }), [dispatch]);
  const onSelectBidAbTest = useCallback((bidAbTest: any) => dispatch({ type: ADS_ACTIONS.BID_AB_TEST, payload: bidAbTest }), [dispatch]);
  const onSelectIsTestDevice = useCallback((isTestDevice: any) => dispatch({ type: ADS_ACTIONS.IS_TEST_DEVICE, payload: isTestDevice }), [dispatch]);
  const onChangeIfa = useCallback((ifa: any) => dispatch({ type: ADS_ACTIONS.IFA, payload: ifa.target.value }), [dispatch]);
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  const onAddDeviceApp = useCallback((deviceApp: any) => dispatch({ type: ADS_ACTIONS.DEVICE_APPS, payload: [...state.deviceApps, deviceApp.split(' - ')[1]] }), [dispatch]);
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  const onRemoveDeviceApp = useCallback((deviceApp: any) => dispatch({ type: ADS_ACTIONS.DEVICE_APPS, payload: state.deviceApps.filter((app) => app !== deviceApp.split(' - ')[1]) }), [dispatch]);
  const onAdEvent = useCallback((id, event: 'impressions' | 'clicks' | 'installs', deviceAds) => {
    const adId = `${id}`;
    const dAds = { ...deviceAds };
    if (!dAds[adId]) {
      dAds[adId] = { id: adId, impressions: 1, clicks: 0, installs: 0 };
    }
    dAds[adId][event] += 1;
    dispatch({ type: ADS_ACTIONS.DEVICE_ADS, payload: dAds });
  }, [dispatch]);

  const onResetDeviceAd = useCallback(() => dispatch({ type: ADS_ACTIONS.DEVICE_ADS, payload: {} }), [dispatch]);

  return {
    state,
    filters,
    onLoadAds,
    onSelectCountry,
    onChangeCity,
    onSelectBundle,
    onSelectPlacementType,
    onSelectOs,
    onSelectOsVersion,
    onSelectHasIfa,
    onSelectAdPhase,
    onSelectBidAbTest,
    onSelectIsTestDevice,
    onChangeIfa,
    onAddDeviceApp,
    onRemoveDeviceApp,
    onAdEvent,
    onResetDeviceAd,
  };
}