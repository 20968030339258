import Bid from './Bid';

export enum Group {
  INDIVIDUALLY = 0,
  GROUP = 1,
}

/**
 * SeatBid:
 * A bid response can contain multiple SeatBid objects, each on behalf of a different bidder seat and each
 * containing one or more individual bids. If multiple impressions are presented in the request, the group
 * attribute can be used to specify if a seat is willing to accept any impressions that it can win (default) or if
 * it is only interested in winning any if it can win them all as a group.
 */
export default interface SeatBid {

  /** bid: Array of 1+ Bid objects each related to an impression. Multiple bids can relate to the same impression. */
  bid: Array<Bid>;

  /** seat: ID of the buyer seat (e.g., advertiser, agency) on whose behalf this bid is made. */
  seat?: string;

  /** group: 0 = impressions can be won individually. 1 = impressions must be won or lost as a group. */
  group?: Group;

  /** ext: Placeholder for exchange-specific extensions to OpenRTB. */
  ext?: any;
}
