import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

// styles
import './index.scss';

// pages
import { CampaignsPage, CampaignPage } from './pages';

export function AdvertisingModule() {

  const { path } = useRouteMatch();

  return (
    <div id="advertising">
      <Switch>
        <Route exact path={`${path}/campaigns`}><CampaignsPage /></Route>
        <Route exact path={`${path}/campaigns/campaign`}><CampaignPage /></Route>
        <Route path={`${path}/campaigns/campaign/:key`}><CampaignPage /></Route>
        <Route exact path={path}><Redirect to={`${path}/campaigns`} /></Route>
      </Switch>
    </div>
  );

}