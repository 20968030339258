import { Switch, Route } from "react-router-dom";

import { useAuths } from '@/contexts/auths.context';
import { ProtectedComponent, YcHeader } from '@/components';

import './app.scss';

import {
  SuperadminModule,
  AppsModule,
  AdvertisingModule,
  PublishingModule,
  BiddingModule,
  ReportingModule,
  RootModule,
  DocsModule,
} from './';

export function App() {
  const { isReady } = useAuths();

  return (
    <div id="app" className="contain">
      <YcHeader />
      {isReady() &&
        <div className="content">
          <Route
            render={({ location }) => (
              <Switch location={location}>
                <Route path="/apps"><ProtectedComponent permission="applications/read"><AppsModule /></ProtectedComponent></Route>
                <Route path="/advertising"><ProtectedComponent permission="advertising/campaigns/read"><AdvertisingModule /></ProtectedComponent></Route>
                <Route path="/publishing"><ProtectedComponent permission="publishing/placements/read"><PublishingModule /></ProtectedComponent></Route>
                <Route path="/bidding"><ProtectedComponent permission="bidding/settings/read"><BiddingModule /></ProtectedComponent></Route>
                <Route path="/superadmin"><ProtectedComponent superAdmin><SuperadminModule /></ProtectedComponent></Route>
                <Route path="/reporting"><ReportingModule /></Route>
                <Route path="/docs"><DocsModule /></Route>
                <Route path="/*"><RootModule /></Route>
              </Switch>
            )}
          />
        </div>
      }
    </div >
  );
}
