import Native from './Native';
import Pmp from './Pmp';
import Banner from './Banner';
import Metric from './Metric';
import Video from './Video';
import Audio from './Audio';

export enum Interstitial {
  YES = 1,
  NO = 0,
}

export enum Clickbrowser {
  EMBEDDED = 0,
  NATIVE = 1,
}

export enum Secure {
  NON_SECURE = 0,
  SECURE = 1,
}

/**
 * Imp:
 * This object describes an ad placement or impression being auctioned. A single bid request can include
 * multiple Imp objects, a use case for which might be an exchange that supports selling all ad positions on
 * a given page. Each Imp object has a required ID so that bids can reference them individually.
 * The presence of Banner, Video, and/or Native objects subordinate to the Imp object indicates the type of
 * impression being offered. The publisher can choose one such type which is the typical case or mix them at
 * their discretion. However, any given bid for the impression must conform to one of the offered types.
 */

export default interface Imp {
  /** id: A unique identifier for this impression within the context of the bid request (typically, starts with 1 and increments. */
  id: string;

  /** metric: An array of Metric object. */
  metric?: Array<Metric>;

  /** banner: A Banner object required if this impression is offered as a banner ad opportunity. */
  banner?: Banner;

  /** video: A Video object required if this impression is offered as a video ad opportunity. */
  video?: Video;

  /** audio: An Audio object required if this impression is offered as an audio ad opportunity. */
  audio?: Audio;

  /** native: A Native object required if this impression is offered as a native ad opportunity. */
  native?: Native;

  /** pmp: A Pmp object containing any private marketplace deals in effect for this impression. */
  pmp?: Pmp;

  /**
   * displaymanager: Name of ad mediation partner
   * SDK technology, or player responsible for rendering ad (typically video or mobile).
   * Used by some ad servers to customize ad code by partner.
   * Recommended for video and/or apps.
   */
  displaymanager?: string;

  /**
   * displaymanagerver: Version of ad mediation partner,
   * SDK technology, or player responsible for rendering ad (typically video or mobile).
   * Used by some ad servers to customize ad code by partner.
   * Recommended for video and/or apps.
   */
  displaymanagerver?: string;

  /** instl: 1 = the ad is interstitial or full screen, 0 = not interstitial. */
  instl?: Interstitial;

  /**
   * tagid: Identifier for specific ad placement or ad tag that was used to initiate the auction.
   * This can be useful for debugging of any issues, or for optimization by the buyer.
   */
  tagid?: string;

  /** bidfloor: Minimum bid for this impression expressed in CPM. */
  bidfloor?: number;

  /**
   * bidfloorcur: Currency specified using ISO-4217 alpha codes.
   * This may be different from bid currency returned by bidder if this is allowed by the exchange.
   */
  bidfloorcur?: string;

  /**
   * clickbrowser: Indicates the type of browser opened upon clicking the creative in an app,
   * where 0 = embedded, 1 = native.
   * Note that the Safari View Controller in iOS 9.x devices is considered a native browser for purposes of this attribute.
   */
  clickbrowser?: Clickbrowser;

  /**
   * secure: Flag to indicate if the impression requires secure HTTPS URL creative assets and markup,
   * where 0 = non-secure, 1 = secure.
   * If omitted, the secure state is unknown, but non-secure HTTP support can be assumed. */
  secure?: Secure;

  /** iframebuster: Array of exchange-specific names of supported iframe busters. */
  iframebuster?: Array<string>;

  /** exp: Advisory as to the number of seconds that may elapse between the auction and the actual impression. */
  exp?: number;

  /** ext: Placeholder for exchange-specific extensions to OpenRTB. */
  ext?: any;
}
