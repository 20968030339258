import { Route, Switch } from 'react-router-dom';

import { ProtectedComponent } from '@/components';
import { useAuths } from '@/contexts';
import {
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ProfilePage,
  UsersPage,
  UserPage,
  ResetPasswordPage,
  DashboardPage,
  P404Page,
  SignUpPage,
  Terms,
  Privacy,
  PoliciesAdvertisers,
  PoliciesPublishers,
  LegalMentions,
  DataProcessingAgreement,
  ContactUs,
  // Acquisition,
  // Monetization,
  // AdExchange,
  // AdsFormats,
  About, SdkOptOut,
} from './pages';

// styles
import './index.scss';

export function RootModule() {
  const uAuths = useAuths();

  const isLogin = () => {
    return uAuths.isLogin();
  }

  return (
    <div className="module-root">
      <Switch>
        <Route exact path="/"><HomePage /></Route>
        {!isLogin() && <Route path="/login"><LoginPage /></Route>}
        {!isLogin() && <Route path="/sign-up"><SignUpPage /></Route>}
        {<Route path="/terms"><Terms /></Route>}
        {<Route path="/privacy"><Privacy /></Route>}
        {<Route path="/sdk-opt-out"><SdkOptOut /></Route>}
        {<Route path="/policies-advertisers"><PoliciesAdvertisers /></Route>}
        {<Route path="/policies-publishers"><PoliciesPublishers /></Route>}
        {<Route path="/legal-mentions"><LegalMentions /></Route>}
        {<Route path="/data-processing-agreement"><DataProcessingAgreement /></Route>}
        {<Route path="/contact-us"><ContactUs /></Route>}
        {/* {<Route path="/acquisition"><Acquisition /></Route>}
        {<Route path="/monetization"><Monetization/></Route>}
        {<Route path="/ad-exchange"><AdExchange /></Route>}
        {<Route path="/ads-formats"><AdsFormats /></Route>} */}
        {<Route path="/about"><About /></Route>}
        {!isLogin() && <Route path="/forgot-password"><ForgotPasswordPage /></Route>}
        {!isLogin() && <Route path="/reset-password"><ResetPasswordPage /></Route>}
        {isLogin() && <Route path="/dashboard"><DashboardPage /></Route>}
        {isLogin() && <Route path="/profile"><ProfilePage /></Route>}
        {isLogin() && <Route path="/users" exact={true}>
          <ProtectedComponent permission="userManagment/read">
            <UsersPage />
          </ProtectedComponent>
        </Route>}
        {/* {isLogin() === true && <Route path="/platform-actions" exact={true}>
          <ProtectedComponent admin>
            <PlatformActionsPage />
          </ProtectedComponent>
        </Route>} */}
        {isLogin() && <Route path="/users/user/:id">
          <ProtectedComponent permission="userManagment/read">
            <UserPage />
          </ProtectedComponent>
        </Route>}
        {isLogin() && <Route path="/users/user" exact={true}>
          <ProtectedComponent permission="userManagment/read">
            <UserPage />
          </ProtectedComponent>
        </Route>}
        <Route path="*"><P404Page /></Route>
      </Switch>
    </div>
  );
}
