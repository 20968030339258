/**
 * The following table lists the options for an exchange to inform a bidder as to the reason why they did
 * not win an impression. ≥ 1000 EXCHANGE SPECIFIC (SHOULD BE COMMUNICATED TO BIDDERS A PRIORI)
 */
export enum LossReasonCodes {
  NO_ERROR = 0, // BID_WON = 0,
  INTERNAL_ERROR = 1,
  IMPRESSION_OPPORTUNITY_EXPIRED = 2,
  INVALID_BID_RESPONSE = 3,
  INVALID_DEAL_ID = 4,
  INVALID_AUCTION_ID = 5,
  INVALID_ADVERTISER_DOMAIN = 6,
  MISSING_MARKUP = 7,
  MISSING_CREATIVE_ID = 8,
  MISSING_BID_PRICE = 9,
  MISSING_MINIMUM_CREATIVE_APPROVAL_DATA = 10,
  BID_WAS_BELOW_AUCTION_FLOOR = 100,
  BID_WAS_BELOW_DEAL_FLOOR = 101,
  LOST_TO_HIGHER_BID = 102,
  LOST_TO_A_BID_FOR_A_PMP_DEAL = 103,
  BUYER_SEAT_BLOCKED = 104,
  CREATIVE_FILTERED_GENERAL_REASON_UNKNOWN = 200,
  CREATIVE_FILTERED_PENDING_PROCESSING_BY_EXCHANGE = 201,
  CREATIVE_FILTERED_DISAPPROVED_BY_EXCHANGE = 202,
  CREATIVE_FILTERED_SIZE_NOT_ALLOWED = 203,
  CREATIVE_FILTERED_INCORRECT_CREATIVE_FORMAT = 204,
  CREATIVE_FILTERED_ADVERTISER_EXCLUSIONS = 205,
  CREATIVE_FILTERED_APP_BUNDLE_EXCLUSIONS = 206,
  CREATIVE_FILTERED_NOT_SECURE = 207,
  CREATIVE_FILTERED_LANGUAGE_EXCLUSIONS = 208,
  CREATIVE_FILTERED_CATEGORY_EXCLUSIONS = 209,
  CREATIVE_FILTERED_CREATIVE_ATTRIBUTE_EXCLUSIONS = 210,
  CREATIVE_FILTERED_AD_TYPE_EXCLUSIONS = 211,
  CREATIVE_FILTERED_ANIMATION_TOO_LONG = 212,
  CREATIVE_FILTERED_NOT_ALLOWED_IN_PMP_DEAL = 213,
}
type LossReason = LossReasonCodes | number;

export default LossReason;
