import { BaseService } from "../base.service";
import { MControlPanel } from '@/modeles';

class ControlPanelService_SA extends BaseService {

  PATH = "superadmin/control-panel/";

  async view(period: string): Promise<MControlPanel | null> {
    return await super._view(this.PATH + "view?period=" + period);
  }

  async viewToday(): Promise<MControlPanel | null> {
    return await super._view(this.PATH + "view-today");
  }

}

export const controlPanelService_SA = new ControlPanelService_SA();
