export enum Coppa {
  NO = 0,
  YES = 1,
}

/**
 * Regs:
 * This object contains any legal, governmental, or industry regulations that apply to the request. The
 * coppa flag signals whether or not the request falls under the United States Federal Trade Commission’s
 * regulations for the United States Children’s Online Privacy Protection Act (“COPPA”).
 */
export default interface Regs {
  /**
   * coppa: Flag indicating if this request is subject to the
   * COPPA regulations established by the USA FTC,
   * where 0 = no, 1 = yes.
   */
  coppa?: Coppa;

  /**
   * ext: Placeholder for exchange-specific extensions to OpenRTB.
   */
  ext?: any;
}
