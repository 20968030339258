import { YcAssetPreview } from '../../../../components';
import { MCampaign, MCampaignAsset } from '../../../../modeles';
import { YcCampaignAssets } from '../a-assets';

// styles
import './campaign-images.scss';
import { YcCampaignAssetRequirements } from '../a-requirements';

export function CampaignImages(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {

  async function beforeUpload(file: File) {
    return true;
  }

  return (
    <div className="c-campaign-images">
      <YcCampaignAssets
        dataKey='Images'
        requirements={<YcCampaignAssetRequirements
          fileType='JPEG, PNG'
          size={{ max: 1 }}
          resolution={[{ name: '720x1280', isRecommended: true }, { name: '1280x720' }]}
        />}
        campaign={props.campaign}
        _setCampaign={props._setCampaign}
        beforeUpload={beforeUpload}
        path="image"
        render={(asset: MCampaignAsset) => (<div className="c-image">
          <YcAssetPreview src={asset?.Document?.url!} height={100} alt="Image" fileName={asset.Document?.originalFileName!}></YcAssetPreview>
        </div>)}
      ></YcCampaignAssets>
    </div >
  );
}