import { Line } from '@ant-design/charts';
import { useMemo } from 'react';

const COLORS = [
  '#5B8FF9',
  '#5AD8A6',
  '#5D7092',
  '#F6BD16',
  '#6F5EF9',
  '#6DC8EC',
  '#945FB9',
  '#FF9845',
  '#1E9493',
  '#FF99C3',
];

const COLORS_WITH_GLOBAL = [
  'black',
  '#5B8FF9',
  '#5AD8A6',
  '#5D7092',
  '#F6BD16',
  '#6F5EF9',
  '#6DC8EC',
  '#945FB9',
  '#FF9845',
  '#1E9493',
  '#FF99C3',
];

export interface ReportLineGraphProps<T> {
  data: T[];
  is: string[];
  fields: string[];
  height?: number;
  verticalLineKeys?: string[];
  repeatFields?: boolean;
  seriesNameOverride?: { [key: string]: string };
}

const MAPPING = {
  application: 'applicationKey',
  campaign: 'campaignKey',
  country: 'countryCode',
}

function toReadablePropertyName(propertyName: string): string {
  return propertyName
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
    .trim();
}

function computeCat(cat: string, field: string, repeatFields?: boolean) {
  if (cat.length && repeatFields) {
    return `${cat}; ${field}`;
  }
  if (cat.length) {
    return cat;
  }
  return toReadablePropertyName(field);
}

export function ReportLineGraph<T extends Record<string, any>>({
  data,
  is,
  fields,
  height = 400,
  verticalLineKeys,
  repeatFields,
  seriesNameOverride = {},
}: ReportLineGraphProps<T>) {
  const parsedData = useMemo(() => {
    const trueIs = is.filter(i => i !== 'granularity');
    const all: { key: string; value: number; cat: string; }[] = data.flatMap((d) => {
      const cat = trueIs.length ? trueIs.map(i => d[MAPPING[i] || i]).join('; ') : '';

      return fields.map(f => ({
        key: d.date,
        value: parseFloat((d[f] || 0).toFixed(3)),
        cat: computeCat(cat, f, repeatFields),
      }));
    });

    const tenBiggestCats = Object.entries(all.reduce((acc, next) => {
      if (acc[next.cat]) acc[next.cat] += next.value;
      else acc[next.cat] = next.value;

      return acc;
    }, {})).sort((a, b) => (b[1] as number) - (a[1] as number)).slice(0, 10).map(a => a[0]);

    return all.filter(a => tenBiggestCats.includes(a.cat));
  }, [data, is, fields, repeatFields]);

  const annotations = useMemo(() => {
    return verticalLineKeys && verticalLineKeys.length > 0
      ? verticalLineKeys.map((verticalLineKey) => ({
        type: 'line',
        start: [verticalLineKey, 'min'],
        end: [verticalLineKey, 'max'],
        style: {
          stroke: 'red',
          lineDash: [4, 4],
          lineWidth: 2,
        },
      }))
      : [];
  }, [verticalLineKeys]);

  const xAxis = {
    label: {
      formatter: (text: string) => text.split(' ').pop(),
    },
  };

  const legend = useMemo(() => {
    return {
      itemName: {
        formatter: (text: string) => seriesNameOverride[text] || text,
      },
    };
  }, [seriesNameOverride]);

  if (!data[0]?.date) {
    return <></>;
  }

  // @ts-ignore
  return parsedData[0]?.cat
    ? <Line
      data={parsedData}
      limitInPlot
      xField='key'
      yField='value'
      seriesField='cat'
      height={height}
      color={parsedData[0]?.cat.toLowerCase().includes('global') ? COLORS_WITH_GLOBAL : COLORS}
      // @ts-ignore
      annotations={annotations}
      xAxis={xAxis}
      legend={legend}
    />
    : <Line
      data={parsedData}
      xField='key'
      yField='value'
      height={height}
      // @ts-ignore
      annotations={annotations}
      xAxis={xAxis}
      legend={legend}
    />
}
