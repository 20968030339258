import { Form, Input, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { useAuths } from '@/contexts';
import { usePageTitle } from '@/hooks';
import { YcTitle } from '@/components';

import './login.page.scss';

export function LoginPage() {
  usePageTitle('Login');

  const [loading, _setLoading] = useState(false);

  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    _setLoading(true);
    await uAuths.login(data);
    _setLoading(false);
  };

  return (
    <div className="root-login">
      <Spin spinning={loading}>
        <div className="login-contain">
          <div className="m-auto">
            <div className="login-titleblock">
              <YcTitle label="Login"></YcTitle>
              <br />
              <div className="block-auth">
                <Form onFinish={onFinish} layout="vertical">
                  <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                    <Input placeholder="Email" type="email" />
                  </Form.Item>
                  <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                    <Input placeholder="Password" type="password" />
                  </Form.Item>

                  <div className="row justify-content-between">
                    <div className="col-lg-5 col-12">
                      <div className="row">
                        <div className="col-lg-5 col-12 mt-4">
                          <Button className="btn-primary-network w-100" type="primary" htmlType="submit">
                            Login
                          </Button>
                        </div>
                        <div className="col-lg-7 col-12 mt-4">
                          <Link className="btn-tertiary-network" to="/forgot-password">
                            Forgot Password
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-12 mt-4">
                      <div className="account-content">
                        {' '}
                        Don't have an account ?{' '}
                        <Link className="account-btn" to="/sign-up">
                          Create account{' '}
                        </Link>{' '}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="bkg-footer" />
        </footer>
      </Spin>
    </div>
  );
}
