import { useCallback, useEffect, useState } from "react";

import { YcEnums, YcCountries } from "yc-interfaces";

import { MBidderConfig } from "@/modeles";
import { appsService } from "@/services";
import { bidderConfigService } from "@/services/bidder-config.service";

function itemIsChecked({ items, item }) {
  const include = items?.includes?.includes(item) || false;
  const exclude = items?.excludes?.includes(item) || false;
  let checked: boolean | undefined = undefined;
  if (include) {
    checked = true;
  } else if (exclude) {
    checked = false;
  }
  return checked;
}

function getAdTypes(bidderConfig) {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = Object.values(YcEnums.PlacementTypes).filter(adType => adType !== YcEnums.PlacementTypes.UNKNOWN).map(adType => {
      return { value: adType, label: adType, checked: itemIsChecked({ items: bidderConfig?.endpoints?.[endpoint]?.filters?.adTypes, item: adType }) };
    });
    return acc;
  }, {}) as unknown as { [endpoint in YcEnums.ExEndpoints]: FCItem[]; };
}

function getPlatforms(bidderConfig) {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = Object.values(YcEnums.OS).filter(os => os !== YcEnums.OS.UNKNOWN).map(os => {
      return { value: os, label: os, checked: itemIsChecked({ items: bidderConfig?.endpoints?.[endpoint]?.filters?.platforms, item: os }) };
    });
    return acc;
  }, {}) as unknown as { [endpoint in YcEnums.ExEndpoints]: FCItem[]; };
}

function getCountries(bidderConfig) {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = YcCountries.COUNTRIES.map(c => {
      return { value: c.iso3, label: c.name, checked: itemIsChecked({ items: bidderConfig?.endpoints?.[endpoint]?.filters?.countries, item: c.iso3 }) };
    });
    return acc;
  }, {}) as unknown as { [endpoint in YcEnums.ExEndpoints]: FCItem[]; };
}

function getPublishers(bidderConfig) {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = ['Yso Corp'].map(publisher => {
      return { value: publisher, label: publisher, checked: itemIsChecked({ items: bidderConfig?.endpoints?.[endpoint]?.filters?.publishers, item: publisher }) };
    });
    return acc;
  }, {}) as unknown as { [endpoint in YcEnums.ExEndpoints]: FCItem[]; };
}

function getBundles(bidderConfig, applications) {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = applications.map(app => {
      return { value: app.bundleId, label: app.bundleId, checked: itemIsChecked({ items: bidderConfig?.endpoints?.[endpoint]?.filters?.bundles, item: app.bundleId }) };
    });
    return acc;
  }, {}) as unknown as { [endpoint in YcEnums.ExEndpoints]: FCItem[]; };
}

const endpoints = ['EU', 'US_EAST', 'US_WEST', 'APAC'] as YcEnums.ExEndpoints[];

export function useBidderConfig() {
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [bidderConfig, _setBidderConfig] = useState<MBidderConfig>(new MBidderConfig());

  const [adTypes, _setAdTypes] = useState<{ [endpoint in YcEnums.ExEndpoints]: FCItem[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [platforms, _setPlatforms] = useState<{ [endpoint in YcEnums.ExEndpoints]: FCItem[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [countries, _setCountries] = useState<{ [endpoint in YcEnums.ExEndpoints]: FCItem[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [publishers, _setPublishers] = useState<{ [endpoint in YcEnums.ExEndpoints]: FCItem[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [bundles, _setBundles] = useState<{ [endpoint in YcEnums.ExEndpoints]: FCItem[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [testIfas, _setTestIfas] = useState<{ [endpoint in YcEnums.ExEndpoints]: string[]; }>({ EU: [], US_EAST: [], US_WEST: [], APAC: [] });
  const [testIfa, _setTestIfa] = useState<{ [endpoint in YcEnums.ExEndpoints]: string; }>({ EU: '', US_EAST: '', US_WEST: '', APAC: '' });

  useEffect(() => {
    Promise.all([
      appsService.listingBidder(),
      bidderConfigService.view(),
    ]).then(([apps, config]) => {
      if (config) {
        _setBidderConfig(config);
        _setAdTypes(getAdTypes(config));
        _setPlatforms(getPlatforms(config));
        _setCountries(getCountries(config));
        _setPublishers(getPublishers(config));
        _setBundles(getBundles(config, apps));
        _setTestIfas(endpoints.reduce((acc: { [endpoint in YcEnums.ExEndpoints]: string[]; }, endpoint: YcEnums.ExEndpoints) => {
          acc[endpoint] = config.endpoints?.[endpoint]?.testIfas || [];
          return acc;
        }, { EU: [], US_EAST: [], US_WEST: [], APAC: [] }))
        _setIsReady(true);
      }
    })
  }, []);

  const onSetBidderFilters = useCallback(({ key, items, endpoint }) => {
    const includes = items.filter(i => i.checked === true).map(i => i.value);
    const excludes = items.filter(i => i.checked === false).map(i => i.value);
    const edited = {
      ...bidderConfig,
      endpoints: {
        ...bidderConfig.endpoints,
        [endpoint]: {
          ...bidderConfig.endpoints[endpoint],
          filters: {
            ...bidderConfig.endpoints[endpoint].filters,
            [key]: { includes, excludes },
          },
        },
      },
    };
    _setBidderConfig(edited);
  }, [bidderConfig]);

  const onChangeTestIfa = (ifa: any, endpoint: string) => {
    _setTestIfa({ ...testIfa, [endpoint]: ifa });
  };

  const onAddTestIfa = (ifa: string, endpoint: string) => {
    if (ifa && !testIfas[endpoint].includes(ifa)) {
      const ifas = { ...testIfas, [endpoint]: [...testIfas[endpoint], ifa] };
      _setTestIfas(ifas);
      _setBidderConfig({
        ...bidderConfig,
        endpoints: {
          ...bidderConfig.endpoints,
          [endpoint]: {
            ...bidderConfig.endpoints[endpoint],
            testIfas: ifas[endpoint],
          },
        },
      });
      _setTestIfa({ ...testIfa, [endpoint]: '' });
    }
  };

  const onRemoveTestIfa = (ifa: string, endpoint: string) => {
    const ifas = { ...testIfas, [endpoint]: [...(testIfas[endpoint].filter((i) => i !== ifa))] };
    _setTestIfas(ifas);
    _setBidderConfig({
      ...bidderConfig,
      endpoints: {
        ...bidderConfig.endpoints,
        [endpoint]: {
          ...bidderConfig.endpoints[endpoint],
          testIfas: ifas[endpoint],
        },
      },
    });
  };

  const onFinish = useCallback(async (values: any) => {
    const edited = {
      ...bidderConfig,
      ...values,
      endpoints: endpoints.reduce((acc, endpoint: YcEnums.ExEndpoints) => {
        acc[endpoint] = {
          ...(bidderConfig.endpoints?.[endpoint] || {}),
          ...(values.endpoints?.[endpoint] || {}),
        };
        return acc;
      }, {}),
    };
    const data = (!edited.id ? await bidderConfigService.add(edited) : await bidderConfigService.edit(edited));
    if (data) {
      _setBidderConfig(data);
    }
  }, [bidderConfig]);

  return {
    isReady,
    bidderConfig,
    endpoints,
    onFinish,
    onSetBidderFilters,
    onChangeTestIfa,
    onAddTestIfa,
    onRemoveTestIfa,
    adTypes,
    platforms,
    countries,
    publishers,
    bundles,
    testIfas,
    testIfa,
  };
}
