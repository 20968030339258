import { capitalize } from "./utils";

export interface ColumnLineGraphTooltipProps<Y1 extends string, Y2 extends string> {
  t: string;
  d: { data: { [key in (Y1 | Y2)]: number } }[];
  barKey: Y1;
  lineKey: Y2;
  formatXKey?: (v?: string | null) => string | null;
  formatBarKey?: (v?: number | null) => string | null;
  formatLineKey?: (v?: number | null) => string | null;
}

export function ColumnLineGraphTooltip<Y1 extends string, Y2 extends string>({
  t,
  d,
  barKey,
  lineKey,
  formatXKey,
  formatBarKey,
  formatLineKey,
}: ColumnLineGraphTooltipProps<Y1, Y2>) {
  return (
    <>
      <div className="g2-tooltip-title">{formatXKey ? formatXKey(t) : t}</div>
      <ul className="g2-tooltip-list">
        <li className="g2-tooltip-list-item">
          <span className="g2-tooltip-marker" style={{
            background: 'rgb(91, 143, 249)',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px'
          }}></span>
          <span className="g2-tooltip-name">{capitalize(barKey)}</span>:
          <span className="g2-tooltip-value">{formatBarKey ? formatBarKey(d[0]?.data?.[barKey]) : d[0]?.data?.[barKey]}</span>
        </li>
        <li className="g2-tooltip-list-item">
          <span className="g2-tooltip-marker" style={{
            background: 'rgb(0, 136, 0)',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px'
          }}></span>
          <span className="g2-tooltip-name">{capitalize(lineKey)}</span>:
          <span className="g2-tooltip-value">{formatLineKey ? formatLineKey(d[0]?.data?.[lineKey]) : d[0]?.data?.[lineKey]}</span>
        </li>
      </ul>
    </>
  )
}