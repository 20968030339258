import { YcAssetPreview } from '../../../../components';
import { MCampaign, MCampaignAsset } from '../../../../modeles';
import { YcCampaignAssets } from '../a-assets';

// styles
import './campaign-endcards.scss';
import { YcCampaignAssetRequirements } from '../a-requirements';

export function CampaignEndcards(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {

  async function beforeUpload(file: File) {
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  return (
    <div className="c-campaign-endcards">
      <YcCampaignAssets
        dataKey='Endcards'
        requirements={<YcCampaignAssetRequirements
          fileType='GIF'
          size={{ max: 5 }}
          resolution={[{ name: '720x1280', isRecommended: true }, { name: '1280x720' }]}
        />}
        campaign={props.campaign}
        _setCampaign={props._setCampaign}
        beforeUpload={beforeUpload}
        path="endcard"
        render={(asset: MCampaignAsset) => (<div className="c-endcard">
          <YcAssetPreview src={asset?.Document?.url!} height={100} alt="Endcard" fileName={asset.Document?.originalFileName!}></YcAssetPreview>
        </div>)}
      ></YcCampaignAssets>
    </div >
  );
}