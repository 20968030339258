import { BaseService } from "./base.service";
class ApiTryService extends BaseService {

  async tryGet(path: string, params: {}, headers: {}): Promise<{} | []> {
    return await super.get(path, params, {
      headers: headers
    });
  }

  async tryPost(path: string, data: {}, headers: {}): Promise<{} | []> {
    return await super.post(path, data, null, {
      headers: headers
    });
  }

}

export const apiTryService = new ApiTryService();