/**
 * The following table lists the options for content quality. These values are defined by the IAB; refer to
 * www.iab.com/wp-content/uploads/2015/03/long-form-video-final.pdf for more information.
 */
enum ProductionQuality {
  UNKNOWN = 0,
  PROFESSIONALLY_PRODUCED = 1,
  PROSUMER = 2,
  USER_GENERATED = 3,
}

export default ProductionQuality;
