import { usePageTitle } from '@/hooks/use-title';
import { YcFooter, YcHeaderTitle, YcDocReader } from '@/components';

import './policies-publishers.page.scss';

export function PoliciesPublishers() {
  usePageTitle('policies-publishers');

  const headerTitle = <h1>YSO Network Policies for Publishers</h1>

  return (
    <div id="root-policies-publishers">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/ysonetworkpoliciespublishers.html'/>
      </div>
      <YcFooter/>
    </div>
  );
}
