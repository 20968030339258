import moment from "moment";
import { Form, Input, Button, Select, Tabs, FormInstance, Spin, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CaretRightOutlined, CheckCircleOutlined, IssuesCloseOutlined, PauseCircleOutlined, PauseOutlined } from "@ant-design/icons";
import { YcTypes } from "yc-interfaces";

import { appsService, campaignsService, mmpsService, profileService } from '@/services';
import { MApp, MCampaign } from '@/modeles';
import { YcCard, YcTitle } from '@/components';
import { useAuths } from "@/contexts";
import { setPageTitle } from "@/hooks/use-title";

import {
  CampaignCountries,
  CampaignVideos,
  CampaignImages,
  CampaignEndcards,
  CampaignBanners,
  CampaignPlayables,
} from '../../components';

import './campaign.page.scss';

export function CampaignPage() {
  const { user } = useAuths();
  const [loading, _setLoading] = useState<number>(0);
  const [applications, _setApplications] = useState<MApp[]>([]);
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [campaign, _setCampaign] = useState<MCampaign>(new MCampaign());
  const [dates] = useState<[moment.Moment, moment.Moment]>([moment(), moment()]);
  const [mmps, _setMmps] = useState<YcTypes.PgMmps | null>(null);


  const formRef = useRef<FormInstance>(null);

  const uHistory = useHistory();
  const uParams: any = useParams();
  const uLocation = useLocation();

  const activeVideos = useMemo(() => campaign.Videos.filter(ad => ad.isActive).length, [campaign.Videos]);
  const activeImages = useMemo(() => campaign.Images.filter(ad => ad.isActive).length, [campaign.Images]);
  const activeEndcards = useMemo(() => campaign.Endcards.filter(ad => ad.isActive).length, [campaign.Endcards]);
  const activePlayables = useMemo(() => campaign.Playables.filter(ad => ad.isActive).length, [campaign.Playables]);
  const activeBanners = useMemo(() => campaign.Banners.filter(ad => ad.isActive).length, [campaign.Banners]);

  useEffect(() => {
    const search = new URLSearchParams(uLocation.search);
    if (search.has('dateStart')) {
      dates[0] = moment(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = moment(search.get('dateEnd'));
    }
    _setLoading(tasks => tasks + 1);
    if (!uParams['key']) {
      _setLoading(tasks => tasks + 1);
      appsService.listing().then((data) => {
        _setLoading(tasks => tasks - 1);
        _setApplications(data || []);
      });
    }
    profileService.mmps().then((data) => {
      _setLoading(tasks => tasks - 1);
      _setMmps(data);
    });
    onSearch(dates, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParams = () => {
    return {
      dateStart: moment(dates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    }
  }

  const onSearch = async (dates: any, disableHistory = false) => {
    if (disableHistory === false) {
      uHistory.push({
        search: new URLSearchParams(getParams()).toString(),
      });
    }
    if (!!uParams['key']) {
      _setLoading(tasks => tasks + 1);
      campaignsService.view(uParams['key']).then((data) => {
        _setLoading(tasks => tasks - 1);
        if (data) {
          _setCampaign(data);
          setPageTitle(`Campaign - ${data.name}`);
          _setApplications([data.Application]);
          _setIsReady(true);
        } else {
          uHistory.push("/advertising/campaigns");
        }
      });
    } else {
      _setIsReady(true);
    }
  }

  const checkMMPCallback = useCallback(async () => {
    _setLoading(tasks => tasks + 1);
    const isCallbackCreated = await appsService.checkCallback(campaign.Application.key);
    campaign.Application.isCallbackCreated = isCallbackCreated;
    _setCampaign(JSON.parse(JSON.stringify(campaign)));
    _setLoading(tasks => tasks - 1);
  }, [campaign]);

  const onFinish = async (values: any) => {
    _setLoading(tasks => tasks + 1);
    const c = { ...campaign, ...values };
    if (!!campaign?.createdAt) {
      const data = await campaignsService.edit(campaign?.key, c);
      if (data) {
        _setCampaign(data);
      }
    } else {
      const data = await campaignsService.add(c);
      if (data) {
        _setCampaign(data);
        uHistory.push("/advertising/campaigns");
      }
    }
    _setLoading(tasks => tasks - 1);
  };

  return (
    <Spin spinning={loading !== 0}>
      <div id='ua-campaign'>
        {isReady &&
          <>
            <YcTitle links={[{ link: "/advertising/campaigns", label: "Campaigns" }]} label={campaign.name}>
              <Tooltip title={campaign.isActive ? 'Disable' : 'Enable'}>
                <Button onClick={async () => {
                  _setCampaign({ ...campaign, isActive: !campaign.isActive });
                }} type={!campaign.isActive ? 'primary' : 'default'} shape="circle" className="float-end" danger={campaign.isActive} icon={campaign.isActive ? <PauseOutlined /> : <CaretRightOutlined />} />
              </Tooltip>
              <span style={{ fontSize: '20px' }} className="m-2">
                {campaign.isActive && ((campaign.budget || 0) <= (campaign.spendDay || 0)) && <><IssuesCloseOutlined style={{color: 'orange' }} /> Cap</>}
                {campaign.isActive && ((campaign.budget || 0) > (campaign.spendDay || 0)) && <><CheckCircleOutlined style={{color: 'green' }} /> Active</>}
                {!campaign.isActive && <><PauseCircleOutlined style={{color: 'red' }} /> Inactive</>}
              </span>
            </YcTitle>
            <Form ref={formRef} onFinish={onFinish} layout="vertical">
              <YcCard title="Basic configuration" open={true}>
                <div className='row'>
                  <div className='col-4'>
                    <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={campaign.name}>
                      <Input type="text" />
                    </Form.Item>
                  </div>
                  <div className='col-3'>
                    <Form.Item label="Application" name="applicationKey" rules={[{ required: true }]} initialValue={campaign.applicationKey}>
                      <Select disabled={!!campaign.key} showSearch onChange={(pKey) => {
                        _setCampaign({
                          ...campaign,
                          applicationKey: pKey,
                          trackingLinkClick: '',
                          trackingLinkImpression: '',
                        });
                      }}>
                        {applications.map(application => (
                          <Select.Option key={application.key} value={application.key}>{application.name} - {application.platform}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='col-3'>
                    <Form.Item label="Key" name="key" rules={[{ required: true }]} initialValue={campaign.key}>
                      <Input type="text" placeholder="TETRIS-IOS" disabled={!!campaign.key} />
                    </Form.Item>
                  </div>
                  <div className='col-2'>
                    <Form.Item label="Daily budget" name="budget" rules={[{ required: true }]} initialValue={campaign.budget}>
                      <Input type="number" min={50} />
                    </Form.Item>
                  </div>
                  {user?.accountId === 1 && !campaign.key && mmps && mmps.tenjin.isActive === true && campaign.applicationKey &&
                    <div className="col-12">
                      <div className="mmps">
                        <p>
                          Gestion tracking link.<br />
                          <u>Application Key:</u> {campaign.applicationKey}
                        </p>
                        <div className="row">
                          {mmps.tenjin.isActive &&
                            <div>
                              <h6>Tenjin</h6>
                              <Button type="primary" onClick={async () => {
                                _setLoading(tasks => tasks + 1);
                                const trackingLinks = await mmpsService.tenjinTrackingLinkImport(campaign.applicationKey!);
                                _setLoading(tasks => tasks - 1);
                                if (trackingLinks) {
                                  _setCampaign({
                                    ...campaign,
                                    ...trackingLinks,
                                  });
                                  formRef.current?.setFields([{ name: 'trackingLinkImpression', value: trackingLinks.trackingLinkImpression }]);
                                  formRef.current?.setFields([{ name: 'trackingLinkClick', value: trackingLinks.trackingLinkClick }]);
                                }
                              }}>Import</Button>&nbsp;
                              <Button type="primary" onClick={async () => {
                                _setLoading(tasks => tasks + 1);
                                const trackingLinks = await mmpsService.tenjinTrackingLinkCreateOrImport(campaign.applicationKey!);
                                _setLoading(tasks => tasks - 1);
                                if (trackingLinks) {
                                  _setCampaign({
                                    ...campaign,
                                    ...trackingLinks,
                                  });
                                  formRef.current?.setFields([{ name: 'trackingLinkImpression', value: trackingLinks.trackingLinkImpression }]);
                                  formRef.current?.setFields([{ name: 'trackingLinkClick', value: trackingLinks.trackingLinkClick }]);
                                }
                              }}>Create Or Import</Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  {user?.accountId === 1 && !!campaign.key && campaign.trackingLinkClick && !campaign.Application.isCallbackCreated &&
                    <div className='col-12'>
                      <label className="error-tracking">
                        Please create the tenjin callback. Click <Button type="link" onClick={() => checkMMPCallback()}>here</Button> to check again.
                      </label>
                    </div>
                  }
                  <div className='col-6'>
                    <Form.Item label="Tracking Link (Impression)" name="trackingLinkImpression" rules={[{ required: true }]} initialValue={campaign.trackingLinkImpression}>
                      <Input.TextArea rows={4} disabled={!!campaign.key} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item label="Tracking Link (Click)" name="trackingLinkClick" rules={[{ required: true }]} initialValue={campaign.trackingLinkClick}>
                      <Input.TextArea rows={4} disabled={!!campaign.key} />
                    </Form.Item>
                  </div>
                </div>
              </YcCard>
              <YcCard title={"Countries (" + campaign.countries.length + ")"}>
                <CampaignCountries campaign={campaign} _setCampaign={_setCampaign}></CampaignCountries>
              </YcCard>
              <YcCard title="Assets" open>
                <Tabs defaultActiveKey="1" >
                  <Tabs.TabPane tab={"Videos (" + activeVideos + " / " + campaign.Videos.length + ")"} key="1">
                    <CampaignVideos campaign={campaign} _setCampaign={_setCampaign}></CampaignVideos>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Playables (" + activePlayables + " / " + campaign.Playables?.length + ")"} key="4">
                    <CampaignPlayables campaign={campaign} _setCampaign={_setCampaign}></CampaignPlayables>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Images (" + activeImages + " / " + campaign.Images.length + ")"} key="2">
                    <CampaignImages campaign={campaign} _setCampaign={_setCampaign}></CampaignImages>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Endcards (" + activeEndcards + " / " + campaign.Endcards.length + ")"} key="3">
                    <CampaignEndcards campaign={campaign} _setCampaign={_setCampaign}></CampaignEndcards>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={"Banners (" + activeBanners + " / " + campaign.Banners.length + ")"} key="5">
                    <CampaignBanners campaign={campaign} _setCampaign={_setCampaign}></CampaignBanners>
                  </Tabs.TabPane>
                </Tabs>
              </YcCard>
            <Form.Item>
              <div className='submit-wrapper'>
                <Button type="primary" htmlType='submit' className='mt-4 submit'>
                  {!campaign.key && <>Create Campaign</>}
                  {!!campaign.key && <>Update Campaign</>}
                </Button>
              </div>
              </Form.Item>
            </Form>
          </>
        }
      </div >
    </Spin>
  );
}
