import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

import { MApp } from "../../modeles";
import { YcIcon } from "..";

import './icon-name.scss';

export function YcIconName(props: {
  app?: MApp
  showIcon?: boolean,

}) {
  return (
    <span className="c-icon-name">
      {!props.app && 
        <YcIcon app={props.app}></YcIcon>
      }
      {props.app && 
        <Link to={"/apps/app/" + props.app!.key} className={"ms-2 " + (props.showIcon ? "link-withicon" : "")}>
          <YcIcon app={props.app}></YcIcon>
          &nbsp;{props.app!.name}
          {props.showIcon && <ArrowRightOutlined rotate={-45} className='lw-icon'/>}
        </Link>
      }
    </span>
  );
}