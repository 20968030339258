/**
 * The following table lists the various options for the delivery of video or audio content.
 */

enum ContentDeliveryMethods {
  STREAMING = 1,
  PROGRESSIVE = 2,
  DOWNLOAD = 3,
}
export default ContentDeliveryMethods;
