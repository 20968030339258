import { DataNode } from 'antd/lib/tree';
import { PermissionType } from 'yc-interfaces';

export function uniques<T extends string | number>(...arrays: T[][]): T[] {
  return Array.from(new Set((arrays.reduce((acc, arr) => acc.concat(arr)))));
}

export const BASE_PERMISSIONS: PermissionType = {
  documentation: {
    children: {
      unity: false,
    },
  },
  settings: {
    children: {
      api: false,
      changePassword: false,
      informations: {
        children: {
          read: false,
          write: false,
        },
      },
    },
  },
  userManagment: {
    children: {
      read: false,
      write: false,
      activate: false,
    },
  },
  applications: {
    children: {
      read: false,
      write: false,
      documentation: false,
    },
  },
  advertising: {
    children: {
      campaigns: {
        children: {
          read: false,
          write: false,
          writeStatus: false,
          documentation: false,
        },
      },
      reports: {
        children: {
          read: false,
          documentation: false,
          filters: {
            children: {
              source: false,
              placement_type: false,
              creative_type: false,
              ad: false,
            },
          },
        },
      },
      settings: {
        children: {
          creditLine: false,
          mmpSetup: false,
        },
      },
    },
  },
  publishing: {
    children: {
      placements: {
        children: {
          read: false,
          write: false,
          writeStatus: false,
          documentation: false,
        },
      },
      reports: {
        children: {
          read: false,
          documentation: false,
        },
      },
    },
  },
  bidding: {
    children: {
      reports: {
        children: {
          read: false,
          documentation: false,
        },
      },
      settings: {
        children: {
          read: false,
          write: false,
        },
      },
    },
  },
  admin: {
    children: {
      accounts: {
        children: {
          read: false,
          write: false,
          validate: false,
          activate: false,
          activateUser: false,
          updateCreditLine: false,
        },
      },
      campaigns: {
        children: {
          read: false,
          validate: false,
          activate: false,
        },
      },
      assets: {
        children: {
          read: false,
          validate: false,
        },
      },
      ads: false,
      monitoring: false,
    },
  },
};

export class Permissions {
  private asType: PermissionType = {};
  private asPaths: string[] = [];

  private setPermissionJson(permissionType: PermissionType, paths: string[]) {
    const [pathSlice, ...rest] = paths;
    if (rest.length) {
      this.setPermissionJson((permissionType[pathSlice] as { children: PermissionType }).children, rest);
      return;
    }
    permissionType[pathSlice] = true;
  }

  private flattenPaths(permissions: PermissionType, paths: string[], basePath: string) {
    Object.keys(permissions).forEach((k) => {
      if (typeof permissions[k] === 'boolean') {
        if (permissions[k] === true) paths.push(`${basePath}/${k}`);
      } else {
        this.flattenPaths((permissions[k] as { children: PermissionType }).children, paths, `${basePath}/${k}`);
      }
    });

    return uniques(paths);
  }

  private buildAntdNode(asType: PermissionType, prefix: string[] = []): DataNode[] {
    return Object.entries(asType).map(([key, value]) => (typeof value === 'boolean' ? {
      title: key,
      key: [...prefix, key].join('/'),
    } : {
      title: key,
      key: [...prefix, key].join('/'),
      children: this.buildAntdNode(value.children as PermissionType, [...prefix, key])
    }))
  }

  public parseFromPaths(paths: string[]) {
    this.asPaths = paths;
    this.asType = BASE_PERMISSIONS;

    paths.forEach((p) => {
      this.setPermissionJson(this.asType, p.split('/'));
    });
  }

  public parseFromPermissionType(permissions: PermissionType) {
    this.asType = permissions;

    this.asPaths = this.flattenPaths(permissions, [], '');
  }

  public addPermission(path: string) {
    this.asPaths = uniques(this.asPaths, [path]);
  }

  public removePermission(path: string) {
    this.asPaths = this.asPaths.filter((p) => p !== path);
  }

  public isAllowed(path: string): boolean {
    return this.asPaths.includes(path);
  }

  public getAsArray(): string[] {
    return this.asPaths;
  }

  public getAsObject(): PermissionType {
    return this.asType;
  }

  public getAsAntd(): DataNode[] {
    return this.buildAntdNode(this.asType)
  }
}
