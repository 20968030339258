import { BaseService } from "./base.service";
import { MApp } from '../modeles';

class AppsService extends BaseService {

  PATH = "apps/";

  async listing(): Promise<MApp[]> {
    return await this._listing(this.PATH + "listing");
  }

  async listingShort(): Promise<MApp[]> {
    return await this._listing(this.PATH + "listing-short");
  }

  async listingBidder(): Promise<MApp[]> {
    return await this._listing(this.PATH + "listing-bidder");
  }

  async add(app: MApp): Promise<MApp | null> {
    return await this._add(this.PATH + "add", app);
  }

  async view(key: string): Promise<MApp | null> {
    return await this._view(this.PATH + "view/" + key);
  }

  async edit(key: string, app: MApp): Promise<MApp | null> {
    return await this._edit(this.PATH + "edit/" + key, app);
  }

  async checkCallback(key: string): Promise<boolean> {
    const { data } = await this.get(this.PATH + "/check-callback/" + key, null) || false;
    return data?.isSetup;
  }

}

export const appsService = new AppsService();