import { BaseService } from "../base.service";
import { MCampaignAsset } from '../../modeles';

class AdminAssetsService extends BaseService {

  PATH = "superadmin/assets/";

  async listing(): Promise<MCampaignAsset[] | null> {
    return await super._listing(this.PATH + "listing");
  }

  async lastToValidate(type: string): Promise<MCampaignAsset | null> {
    return await super._view(this.PATH + "last-to-validate/" + type);
  }

  async validate(id: string): Promise<MCampaignAsset[] | null> {
    return await super.get(this.PATH + "validate/" + id, {});
  }

  async devalidate(id: string): Promise<MCampaignAsset[] | null> {
    return await super.get(this.PATH + "devalidate/" + id, {});
  }

  async validateAndLastToValidate(id: string, type: string): Promise<MCampaignAsset | null> {
    return await super._view(this.PATH + "validate-and-last-to-validate/" + id + "/" + type, {});
  }

  async devalidateAndLastToValidate(id: string, type: string): Promise<MCampaignAsset | null> {
    return await super._view(this.PATH + "devalidate-and-last-to-validate/" + id + "/" + type, {});
  }

}

export const adminAssetsService = new AdminAssetsService();