import { BaseService } from "./base.service";
import { MApp, MUnifiedApp } from '../modeles';

class UnifiedAppsService extends BaseService {

  PATH = "unified-apps/";

  async listing(): Promise<MUnifiedApp[]> {
    return await this._listing(this.PATH + "listing");
  }

  async listingUnattributedApps(): Promise<MApp[]> {
    const apps = await this._listing<MApp>(this.PATH + "listing-unattributed-apps");

    return apps.sort((appA, appB) => appA.name.localeCompare(appB.name));
  }

  async add(app: MUnifiedApp): Promise<MUnifiedApp | null> {
    return await this._add(this.PATH + "add", app);
  }

  async view(key: string): Promise<MUnifiedApp | null> {
    return await this._view(this.PATH + "view/" + key);
  }

  async edit(key: string, app: MUnifiedApp): Promise<MUnifiedApp | null> {
    return await this._edit(this.PATH + "edit/" + key, app);
  }
}

export const unifiedAppsService = new UnifiedAppsService();