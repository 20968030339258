import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';

import { PlacementCreateMetadataType } from './types';

export function PlacementCreateMetadata({ metadata }: PlacementCreateMetadataType) {
  const [platform] = metadata.applicationKey.split('-');
  return (
    <div>
      <span>{platform === 'ios' ? <AppleOutlined /> : <AndroidOutlined />}</span>
      <span className='mx-2'>{metadata.type}</span>
      {metadata.name}
    </div>
  );
}