import { MApp } from "../../modeles";

// styles
import './icon.scss';

export function YcIcon(props: {
  app?: MApp
}) {

  return (
    <span className="c-icon">
      {!!props.app?.Icon?.url && <img alt="icon" src={props.app?.Icon?.url} />}
      {!props.app?.Icon?.url && <div className="dicon"></div>}
    </span>
  );
}