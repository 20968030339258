import { Link } from 'react-router-dom';

import { PlacementUpdateMetadataType } from './types';

export function PlacementUpdateMetadata({ metadata }: PlacementUpdateMetadataType) {
  return (
    <div>
      {[false, true].includes(metadata.isActive!) && <span>New status : {metadata.isActive ? 'Active' : 'Inactive'}</span>}
      {metadata.name && <span>New name : {metadata.name}</span>}
      <Link className='mx-2' to={`/publishing/placements/placement/${metadata.placementKey}`}>{metadata.placementKey}</Link>
    </div>
  );
}