import { useReportingTable } from '@/hooks';
import { getMetricColumn, getStringColumn } from '@/modules/reporting/helpers';
import { Spin } from 'antd';
import moment from 'moment/moment';
import qs from 'qs';
import { useMemo } from 'react';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from "@/components";
import { FilterTool, NumberTools } from '@/tools';
import { reportingPublishingService } from '@/services';
import { usePageTitle } from '@/hooks/use-title';

import { ReportingFilter } from "../../components";

import './reporting-publishing.page.scss';


interface Total {
  requests: number;
  responses: number;
  fillRate: number;
  wins: number;
  winRate: number;
  impressions: number;
  revenue: number;
  ecpm: number;
}

interface ResultLine extends Total {
  id: number;

  date?: string;
  hourOfDay?: string;
  publisher?: string;
  platform?: string;
  platformVersion?: string;
  sdkVersion?: string;
  source?: string;
  placement?: string;
  placementType?: string;
  countryCode?: string;
  isMediation?: boolean;
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

const PARAMS_FILTERS = ['granularities', 'hours', 'platforms', 'platformVersions', 'placements', 'placementTypes', 'countries', 'sources'];
const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlatformVersion', searchKey: 'platformVersion' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isPlacement', searchKey: 'placement' },
];

function getParams(stringify: boolean, dates: [moment.Moment, moment.Moment], filters: Filters, params: any = {}) {
  const b = {
    ...(params || {}),
    dateStart: moment(dates[0]).format("YYYY-MM-DD"),
    dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    granularity: filters?.isGranularity ? filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
    hours: FilterTool.getParamsFilter(filters?.hours),
    countries: FilterTool.getParamsFilter(filters?.countries),
    sources: FilterTool.getParamsFilter(filters?.sources),
    platforms: FilterTool.getParamsFilter(filters?.platforms),
    platformVersions: FilterTool.getParamsFilter(filters?.platformVersions),
    placementTypes: FilterTool.getParamsFilter(filters?.placementTypes),
    placements: FilterTool.getParamsFilter(filters?.placements),
    is: FilterTool.getParamsIs(IS_FILTERS, filters),
  };
  if (stringify) {
    return qs.stringify(b, { encode: false });
  }
  return b;
}

export function ReportingPublishingPage() {
  usePageTitle('Reporting - Publishing');

  const {
    filters, results, tableData, dates,
    loading, onSetFilter, _setTableData, onDatesChange,
    onSetFilters, onSearchReportingFilter, onSearchDownloadData,
  } = useReportingTable<Result, ResultLine>({
    service: reportingPublishingService,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates: FilterTool.initFCItemStates,
  });

  const columnsMemo = useMemo(() => {
    const allBCols = {
      granularity: getStringColumn<ResultLine>({ title: 'Date', dataKey: 'date', filters, onSetFilter }),
      hourOfDay: getStringColumn<ResultLine>({ title: 'Hour', dataKey: 'hourOfDay', itemKey: 'hours', filters, onSetFilter }),
      platform: getStringColumn<ResultLine>({ title: 'Platform', dataKey: 'platform', itemKey: 'platforms', filters, onSetFilter }),
      platformVersion: getStringColumn<ResultLine>({ title: 'Platform Version', dataKey: 'platformVersion', itemKey: 'platformVersions', filters, onSetFilter }),
      source: getStringColumn<ResultLine>({ title: 'Source', dataKey: 'source', itemKey: 'sources', filters, onSetFilter }),
      placement: getStringColumn<ResultLine>({ title: 'Placement', dataKey: 'placement', itemKey: 'placements', filters, onSetFilter }),
      placementType: getStringColumn<ResultLine>({ title: 'Placement Type', dataKey: 'placementType', itemKey: 'placementTypes', filters, onSetFilter }),
      country: getStringColumn<ResultLine>({ title: 'Country', dataKey: 'countryCode', itemKey: 'countries', filters, onSetFilter }),
    }

    const columns: YcColumnType<ResultLine>[] = [
      getMetricColumn({ title: 'Requests', dataKey: 'requests', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Responses', dataKey: 'responses', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Fill Rate', dataKey: 'fillRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Wins', dataKey: 'wins', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Win Rate', dataKey: 'winRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Impressions', dataKey: 'impressions', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Revenue', dataKey: 'revenue', numberFormat: NumberTools.roundPrice3Dec, config: { defaultSortOrder: 'descend' }, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'eCPM', dataKey: 'ecpm', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
    ];

    let bCols: YcColumnType<ResultLine>[] = Object.entries(allBCols).filter(([key]) => results?.is.includes(key)).map(([_, col]) => col);
    if (bCols.length) {
      columns.unshift({ title: 'Breakdown', dataIndex: 'breakdown', key: 'breakdown', ycCanNotHide: true, children: bCols });
    }
    return columns;
  }, [_setTableData, filters, onSetFilter, results?.is, results?.results]);

  const summarys = results ? [
    { key: 'generic', value: undefined, colSpan: results!.is.length },
    { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
    { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
    { key: 'fillRate', value: NumberTools.roundPercentage(results!.total.fillRate, 2) },
    { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
    { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
  ] : undefined;

  const filterProps: FilterPropType[] = useMemo(
    () => [
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularity' },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const },
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const },
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const },
      { key: 'platformVersions', itemKey: 'platformVersions', isKey: 'isPlatformVersion', label: 'Platform Versions', mode: 'multiple' as const },
      { key: 'placements', itemKey: 'placements', isKey: 'isPlacement', label: 'Placements', mode: 'multiple' as const },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters, show: true })),
    [filters, onSetFilters],
  );

  return (
    <div id='reporting-publishing'>
      <YcTitle label="Reporting - Publishing" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter
            datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
            dates={dates}
            filterProps={filterProps}
            loading={loading}
            onDatesChange={onDatesChange}
            onSearch={onSearchReportingFilter}
            onSearchDownloadData={onSearchDownloadData}
          />
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue']} />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-publishing"
              rowKey="id"
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
