import { usePageTitle } from '@/hooks/use-title';
import { YcFooter, YcHeaderTitle, YcDocReader } from '@/components';

import './policies-advertisers.page.scss';

export function PoliciesAdvertisers() {
  usePageTitle('policies-advertisers');

  const headerTitle = <h1>YSO Network Policies for Advertisers</h1>

  return (
    <div id="root-policies-advertisers">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/ysonetworkpoliciesadvertisers.html'/>
      </div>
      <YcFooter/>
    </div>
  );
}
