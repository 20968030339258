import { useMemo } from 'react'
import { useAuths } from '@/contexts/auths.context';
import { HomeAdvertiserPage } from './dashboard-advertiser';
import { HomeBidderPage } from './dashboard-bidder';
import { HomePublisherPage } from './dashboard-publisher';
import { usePageTitle } from '@/hooks/use-title';
import { YcTitle } from '@/components';

import './dashboard.page.scss';

export function DashboardPage() {
  usePageTitle('Dashboard');

  const { user } = useAuths();

  const className = useMemo(() => {
    const nbGraphs = (+(user?.Account.isAdvertiser || false)) + (+(user?.Account.isPublisher || false)) + (+(user?.Account.isBidder || false));
    const graphSize = nbGraphs > 0 ? 12 / nbGraphs : 12;

    return `col-lg-${graphSize} col-md-6 col-sm-12`;
  }, [user]);

  return (
    <div id='root-dashboard'>
      <YcTitle label="Dashboard"></YcTitle>
      <div className='row'>
        <div className={className}>
          {user?.Account.isAdvertiser && <HomeAdvertiserPage />}
        </div>
        <div className={className}>
          {user?.Account.isPublisher && <HomePublisherPage />}
        </div>
        <div className={className}>
          {user?.Account.isBidder && <HomeBidderPage />}
        </div>
      </div>
    </div>
  );
}
