/**
 * The following table lists the services and/or vendors used for resolving IP addresses to geolocations.
 */
enum IPLocationServices {
  IP2LOCATION = 1,
  NEUSTAR = 2,
  MAXMIND = 3,
  NETACUITY = 4,
}

export default IPLocationServices;
