/**
 * The following table specifies a standard list of creative attributes that can describe an ad being served or
 * serve as restrictions of thereof.
 */
enum CreativeAttributes {
  AUDIO_AD_AUTO_PLAY = 1,
  AUDIO_AD_USER_INITIATED = 2,
  EXPANDABLE_AUTOMATIC = 3,
  EXPANDABLE_USER_INITIATED_CLICK = 4,
  EXPANDABLE_USER_INITIATED_ROLLOVER = 5,
  IN_BANNER_VIDEO_AD_AUTO_PLAY = 6,
  IN_BANNER_VIDEO_AD_USER_INITIATED = 7,

  /** Over, Under, or Upon Exit */
  POP = 8,
  PROVOCATIVE_OR_SUGGESTIVE_IMAGERY = 9,
  SHAKY = 10, FLASHING = 10, FLICKERING = 10, EXTREME_ANIMATION = 10, SMILEYS = 10,
  SURVEYS = 11,
  TEXT_ONLY = 12,

  /** like Embedded Games */
  USER_INTERACTIVE = 13,

  WINDOWS_DIALOG = 14, ALERT_STYLE = 14,
  HAS_AUDIO_ONOFF_BUTTON = 15,

  /** (e.g. VPAID-rendered skip button on pre-roll video) */
  AD_PROVIDES_SKIP_BUTTON = 16,
  ADOBE_FLASH = 17,
}
export default CreativeAttributes;
