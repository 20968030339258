import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';

// styles
import './date-picker.scss';

const allPresets = {
  "Today": [moment(), moment()] as [Moment, Moment],
  "Yesterday": [moment().add(-1, 'days'), moment().add(-1, 'days')] as [Moment, Moment],
  "Last 7d": [moment().add(-6, 'days'), moment()] as [Moment, Moment],
  "Last 30d": [moment().add(-29, 'days'), moment()] as [Moment, Moment],
  "Current month": [moment().startOf('month'), moment()] as [Moment, Moment],
  "Previous month": [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')] as [Moment, Moment],
  "Year to date": [moment().startOf('year'), moment()] as [Moment, Moment],
  "Previous year": [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')] as [Moment, Moment],
}

export function YcDatePicker(props: {
  value: [moment.Moment, moment.Moment],
  onChange: (e: any) => void,
  disabledDate?: (d: moment.Moment) => boolean,
  presets?: readonly (keyof typeof allPresets)[];
}) {
  const [open, _setOpen] = useState(false);

  const selectedPresets = useMemo(() => {
    return Object.fromEntries(Object.entries(allPresets).filter(([key]) => !props.presets || props.presets.includes(key as keyof typeof allPresets)))
  }, [props.presets]);

  const GetValue = () => {
    if (!open) {
      return props.value;
    }
    return null;
  }

  const OnChange = (e: any) => {
    props.onChange(e);
    _setOpen(false);
  }

  return (
    <span className="c-datepicker">
      <DatePicker.RangePicker value={GetValue()}
      onChange={OnChange}
      ranges={selectedPresets}
      disabledDate={props.disabledDate || (d => !d || d.isAfter(moment().utc()))}/>
    </span>
  );
}
