import { ReactChild } from 'react';

import './header-title.scss';

export function YcHeaderTitle(props: {
  title: ReactChild;
}) {
  return (
    <div className="header-title">
      <div className="row container m-auto reverse">
        <div className="col-12 header-titleblock text-center">
          {props.title}
        </div>
      </div>
    </div>
  );
}