import { useAuths } from '@/contexts';
import { Spin } from 'antd';
import moment from 'moment/moment';
import qs from 'qs';
import { useCallback, useMemo } from 'react';

import { YcTitle, ReportLineGraph, YcTable, YcColumnType } from "@/components";
import { FilterTool, NumberTools } from '@/tools';
import { reportingAdvertisingService } from '@/services';
import { usePageTitle, useReportingTable } from '@/hooks';

import { getStringColumn, getMetricColumn } from '@/modules/reporting/helpers';
import { ReportingFilter } from '../../components';

import './reporting-advertising.page.scss';

interface Total {
  impressions: number;
  clicks: number;
  installs: number;
  spend: number;
  ctr: number;
  ir: number;
  ecpi: number;
  ecpm: number;
}

interface ResultLine extends Total {
  id: number;

  date?: string;
  hourOfDay?: string;
  platform?: string;
  applicationKey?: string;
  campaignKey?: string;
  countryCode?: string;
  source?: string;
  placementType?: string;
  creativeType?: string;
  adName?: string;
}

interface Result {
  is: string[];
  total: Total;
  results: ResultLine[];
}

const PARAMS_FILTERS = ['granularities', 'hours', 'countries', 'platforms', 'applications', 'campaigns', 'creativeTypes'];
const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isApplication', searchKey: 'application' },
  { filterKey: 'isCampaign', searchKey: 'campaign' },
  { filterKey: 'isAd', searchKey: 'ad' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isCreativeType', searchKey: 'creativeType' },
];

export function ReportingAdvertisingPage() {
  usePageTitle('Reporting - Advertising');
  const { permissions, isSuperAdmin } = useAuths();

  const {
    canSeeSourceAdv,
    canSeePlacementTypeAdv,
    canSeeCreativeTypeAdv,
    canSeeAdAdv,
  } = useMemo(() => ({
    canSeeSourceAdv: FilterTool.canAccess('advertising/reports/filters/source', permissions, isSuperAdmin()),
    canSeePlacementTypeAdv: FilterTool.canAccess('advertising/reports/filters/placement_type', permissions, isSuperAdmin()),
    canSeeCreativeTypeAdv: FilterTool.canAccess('advertising/reports/filters/creative_type', permissions, isSuperAdmin()),
    canSeeAdAdv: FilterTool.canAccess('advertising/reports/filters/ad', permissions, isSuperAdmin()),
  }), [permissions, isSuperAdmin]);

  const getParams = useCallback((stringify: boolean, pDates: [moment.Moment, moment.Moment], pFilters: Filters, params: any = {}) => {
    const b = {
      ...(params || {}),
      dateStart: moment(pDates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(pDates[1]).format("YYYY-MM-DD"),
      granularity: pFilters?.isGranularity ? pFilters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
      hours: FilterTool.getParamsFilter(pFilters?.hours),
      countries: FilterTool.getParamsFilter(pFilters?.countries),
      sources: canSeeSourceAdv && FilterTool.getParamsFilter(pFilters?.sources),
      platforms: FilterTool.getParamsFilter(pFilters?.platforms),
      placementTypes: canSeePlacementTypeAdv && FilterTool.getParamsFilter(pFilters?.placementTypes),
      applications: FilterTool.getParamsFilter(pFilters?.applications),
      campaigns: FilterTool.getParamsFilter(pFilters?.campaigns),
      creativeTypes: FilterTool.getParamsFilter(pFilters?.creativeTypes),
      is: FilterTool.getParamsIs(IS_FILTERS, pFilters),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  }, [canSeePlacementTypeAdv, canSeeSourceAdv]);

  const initFCItemStates = useCallback(({ data, search }: {
    data: Filters;
    search: URLSearchParams;
  }) => {
    if (canSeeSourceAdv) {
      PARAMS_FILTERS.push('sources');
    }
    if (canSeePlacementTypeAdv) {
      PARAMS_FILTERS.push('placementTypes');
    }
    FilterTool.initFCItemStates({ paramsFilters: PARAMS_FILTERS, isFilters: IS_FILTERS, data, search });
  }, [canSeePlacementTypeAdv, canSeeSourceAdv]);

  const {
    filters, results, tableData, dates,
    loading, onSetFilter, _setTableData, onDatesChange,
    onSetFilters, onSearchReportingFilter, onSearchDownloadData,
  } = useReportingTable<Result, ResultLine>({
    service: reportingAdvertisingService,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates,
  });

  const columnsMemo = useMemo(() => {
    const allBCols = {
      granularity: getStringColumn<ResultLine>({ title: 'Date', dataKey: 'date', filters, onSetFilter }),
      hourOfDay: getStringColumn<ResultLine>({ title: 'Hour', dataKey: 'hourOfDay', itemKey: 'hours', filters, onSetFilter }),
      platform: getStringColumn<ResultLine>({ title: 'Platform', dataKey: 'platform', itemKey: 'platforms', filters, onSetFilter }),
      application: getStringColumn<ResultLine>({ title: 'Application', dataKey: 'applicationKey', itemKey: 'applications', filters, onSetFilter }),
      campaign: getStringColumn<ResultLine>({ title: 'Campaign', dataKey: 'campaignKey', itemKey: 'campaigns', filters, onSetFilter }),
      country: getStringColumn<ResultLine>({ title: 'Country', dataKey: 'countryCode', itemKey: 'countries', filters, onSetFilter }),
      ad: getStringColumn<ResultLine>({ title: 'Ad Name', dataKey: 'adName', filters, onSetFilter }),
      creativeType: getStringColumn<ResultLine>({ title: 'Creative Type', dataKey: 'creativeType', itemKey: 'creativeTypes', filters, onSetFilter }),
      source: getStringColumn<ResultLine>({ title: 'Source', dataKey: 'source', itemKey: 'sources', filters, onSetFilter }),
      placementType: getStringColumn<ResultLine>({ title: 'Placement Type', dataKey: 'placementType', itemKey: 'placementTypes', filters, onSetFilter }),
    }

    const columns: YcColumnType<ResultLine>[] = [
      getMetricColumn({ title: 'Impressions', dataKey: 'impressions', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Clicks', dataKey: 'clicks', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Installs', dataKey: 'installs', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'CTR', dataKey: 'ctr', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'IR', dataKey: 'ir', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'eCPI', dataKey: 'ecpi', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'eCPM', dataKey: 'ecpm', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Spend', dataKey: 'spend', numberFormat: NumberTools.roundPrice3Dec , config: { defaultSortOrder: 'descend' }, results: results?.results || [], _setTableData }),
    ];
    let bCols: YcColumnType<ResultLine>[] = Object.entries(allBCols).filter(([key]) => results?.is.includes(key)).map(([_, col]) => col);
    if (bCols.length) {
      columns.unshift({ title: 'Breakdown', dataIndex: 'breakdown', key: 'breakdown', ycCanNotHide: true, children: bCols });
    }
    return columns;
  }, [_setTableData, filters, onSetFilter, results?.is, results?.results]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'clicks', value: NumberTools.largeNumber(results!.total.clicks) },
    { key: 'installs', value: NumberTools.largeNumber(results!.total.installs) },
    { key: 'ctr', value: NumberTools.roundPercentage(results!.total.ctr, 2) },
    { key: 'ir', value: NumberTools.roundPercentage(results!.total.ir, 2) },
    { key: 'ecpi', value: NumberTools.roundPrice(results!.total.ecpi, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
    { key: 'spend', value: NumberTools.roundPrice(results!.total.spend, 3) },
  ] : undefined;
  const filterProps: FilterPropType[] = useMemo(
    () => [
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularities', show: true },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const, show: true },
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const, show: true },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const, show: canSeeSourceAdv },
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const, show: true },
      { key: 'applications', itemKey: 'applications', isKey: 'isApplication', label: 'Applications', mode: 'multiple' as const, show: true },
      { key: 'campaigns', itemKey: 'campaigns', isKey: 'isCampaign', label: 'Campaigns', mode: 'multiple' as const, show: true },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const, show: canSeePlacementTypeAdv },
      { key: 'creativeTypes', itemKey: 'creativeTypes', isKey: 'isCreativeType', label: 'Creative Types', mode: 'multiple' as const, show: canSeeCreativeTypeAdv },
      { key: 'ads', itemKey: 'ads', isKey: 'isAd', label: 'Ads', disabledSelect: true, show: canSeeAdAdv },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters })),
    [canSeeAdAdv, canSeeCreativeTypeAdv, canSeePlacementTypeAdv, canSeeSourceAdv, filters, onSetFilters],
  );

  return (
    <div id='reporting-advertising'>
      <YcTitle label="Reporting - Advertising" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter
            datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
            dates={dates}
            filterProps={filterProps}
            loading={loading}
            onDatesChange={onDatesChange}
            onSearch={onSearchReportingFilter}
            onSearchDownloadData={onSearchDownloadData}
          />
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['spend']} />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-advertising"
              rowKey="id"
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
