export class CsvTool {
  static downloadData<T>(filename: string, data: T[]) {
    const csv = CsvTool.convertToCsv(data);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  private static convertToCsv<T>(data: T[]): string {
    if (!data || !data.length) {
      return '';
    }

    // @ts-ignore
    const headers = Object.keys(data[0]).filter((h) => !['id'].includes(h));

    const rows = data.map(row =>
      headers.map(header => {
        const value = row[header as keyof T];

        if (/[",\n]/.test(String(value))) {
          return `"${String(value).replace(/"/g, '""')}"`;
        }

        return value;
      }).join(',')
    );

    return [headers.join(','), ...rows].join('\n');
  }
}
