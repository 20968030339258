import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

// styles
import './index.scss';

// pages
import { PlacementsPage, PlacementPage } from './pages';

export function PublishingModule() {

  const { path } = useRouteMatch();

  return (
    <div id="publishing">
      <Switch>
        <Route exact path={`${path}/placements`}><PlacementsPage /></Route>
        <Route path={`${path}/placements/placement/:key`}><PlacementPage /></Route>
        <Route path={`${path}/placements/placement`}><PlacementPage /></Route>
        <Route path={path}><Redirect to={`${path}/placements`} /></Route>
      </Switch>
    </div>
  );

}