import { Form, Upload, message } from 'antd';
import { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

// styles
import './upload-icon.scss';
import { MApp, MDocument } from '../../modeles';

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.readAsDataURL(img);
  return reader.addEventListener('load', () => {
    return callback(reader.result);
  });
}

export function YcUploadIcon(props: { app: MApp; onUpload: (doc: MDocument) => void }) {
  const [loading, _setLoading] = useState(false);
  const [icon, _setIcon] = useState<MDocument | undefined>(props.app.Icon);

  useEffect(() => {
    _setIcon(props.app.Icon);
  }, [props.app]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  async function beforeUpload(file: any) {
    return new Promise<boolean>((resolve, reject) => {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        message.error('Icon file type need to be image/jpeg or image/png');
        resolve(false);
      } else {
        getBase64(file, (imageUrl: any) => {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => {
            if (!['1024x1024', '512x512'].includes(`${img.width}x${img.height}`)) {
              message.error('Icon size need to be 1024x1024 or 512x512');
              resolve(false);
            } else {
              resolve(true);
            }
          };
        });
      }
    });
  }

  function onChange(info: any) {
    if (info.file.status === 'uploading') {
      _setLoading(true);
    } else if (info.file.status === 'done') {
      _setLoading(false);
      _setIcon(info.file.response.data);
      props.onUpload(info.file.response.data);
    } else if (info.file.status === 'error') {
      _setLoading(false);
      message.error('Error uploading icon');
    }
  }

  return (
    <div id="upload-avatar" className="app-icon">
      <Form.Item name="icon" initialValue={icon?.url}>
        <Upload
          name="icon"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={onChange}
          headers={{
            authorization: localStorage.getItem('user-token') || '',
          }}
          action={`${process.env.REACT_APP_API_URL}/apps/upload-icon${!!props.app.key ? '/' + props.app.key : ''}`}
        >
          {!!icon?.url ? <img src={icon?.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
    </div>
  );
}
