import { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { ColumnType } from "antd/lib/table";

import { MPlatformAction, PlatformActionEntity, PlatformActionType, uniques } from '@/modeles';
import { PlatformActionMetadata, YcTitle } from '@/components';
import { platformActionsService } from '@/services';

import './platform-actions.page.scss';
import { usePageTitle } from '@/hooks/use-title';
import { Link } from 'react-router-dom';

export function SAPlatformActionsPage() {
  usePageTitle('Actions History');

  const [actions, _setActions] = useState<MPlatformAction[]>([]);
  const [users, _setUsers] = useState<string[]>([]);
  const [accounts, _setAccounts] = useState<string[]>([]);
  const [loading, _setLoading] = useState<boolean>(false);

  useEffect(() => {
    _setLoading(true);
    platformActionsService.listingSuperadmin().then((a) => {
      _setActions(a || []);
      _setUsers(uniques((a || []).map(a => a.User!.email)).sort((a, b) => a.localeCompare(b)));
      _setAccounts(uniques((a || []).map(a => a.User!.Account!.name)).sort((a, b) => a.localeCompare(b)));
      _setLoading(false);
    });
  }, []);

  const columns: ColumnType<MPlatformAction>[] = [
    {
      title: 'ID', key: 'id',
      render: (text: string, action: MPlatformAction) => action.id,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Date', key: 'date',
      render: (text: string, action: MPlatformAction) => new Date(action.createdAt!).toISOString(),
      sorter: (a, b) => a.createdAt!.getTime() - b.createdAt!.getTime(),
    },
    {
      title: 'Entity', key: 'entity',
      render: (text: string, action: MPlatformAction) => action.entity,
      filters: Object.entries(PlatformActionEntity).map(([text, value]) => ({ text, value })),
      onFilter: (value, record) => record.entity === value,
      sorter: (a, b) => a.entity!.localeCompare(b.entity!),
    },
    {
      title: 'Type', key: 'type',
      render: (text: string, action: MPlatformAction) => action.type,
      filters: Object.entries(PlatformActionType).map(([text, value]) => ({ text, value })),
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => a.type!.localeCompare(b.type!),
    },
    {
      title: 'User', key: 'user',
      render: (text: string, action: MPlatformAction) => <Link to={"/users/user/" + action.userId}>{action.User!.email}</Link>,
      filters: users.map((v) => ({ text: v, value: v })),
      onFilter: (value, record) => record.User!.email === value,
      sorter: (a, b) => a.User!.email.localeCompare(b.User!.email),
    },
    {
      title: 'Account', key: 'account',
      render: (text: string, action: MPlatformAction) => <Link to={"/superadmin/accounts/account/" + action.User!.accountId}>{action.User!.Account.name}</Link>,
      filters: accounts.map((v) => ({ text: v, value: v })),
      onFilter: (value, record) => record.User!.Account.name === value,
      sorter: (a, b) => a.User!.Account.name.localeCompare(b.User!.Account.name),
    },
    {
      title: 'Metadata', key: 'metadata',
      render: (text: string, action: MPlatformAction) => <PlatformActionMetadata entity={action.entity!} type={action.type!} metadata={action.metadata} />,
    },
  ];

  return (
    <div id="root-platform-actions">
      <Spin spinning={loading}>
        <YcTitle label="Actions"></YcTitle>
        <Table bordered size="small" dataSource={actions} columns={columns} rowKey="id"></Table>
      </Spin>
   </div>
  );
}
