/**
 * The following table lists the types of feeds, typically for audio.
 */

enum FeedTypes {
  MUSIC_SERVICE = 1,
  FM_AM_BROADCAST = 2,
  PODCAST = 3,
}

export default FeedTypes;
