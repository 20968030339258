import { Link } from 'react-router-dom';

import './contact-us-block.scss';

export function YcContactUsBlock() {
  return (
    <Link to="/contact-us">
      <div className="row container m-auto mt-5">
        <div className="col-lg-12 solutions-contain">
          <div className="contact-us-block">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <div className="content-contact">
                  <img alt="contact-us-ico" className="ico mb-3" src="/homepage/contact-assets.svg" />
                  <br />
                  <h2>Do you have a question or a special request?</h2>
                  <br />
                  <div className="btn-primary-network">CONTACT US NOW !</div>
                </div>
              </div>
              <div className="col-lg-6">
                <img alt="contact-us" className="keyvisual" src="/homepage/contact-us.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
