import { Form, Input, Button } from 'antd';
import { MUser } from '@/modeles';

import { YcTitle } from '@/components';

export interface SelfUserProps<T> {
  user: MUser;
  onFinish: (values: T) => void | Promise<void>;
}

export function SelfUser<T>({ user, onFinish }: SelfUserProps<T>) {

  return (
    <div id='root-user'>
      <YcTitle links={[{ link: "/users", label: "Users" }]} label={user.email}></YcTitle>
      <Form onFinish={onFinish} layout="vertical">
        <div className='row'>
          <div className='col-4'>
            <Form.Item label="Email" name="email" rules={[{ required: !user.id }]} initialValue={user.email}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className='col-4'>
            <Form.Item label="Password" name="password" rules={[{ required: !user.id }]}>
              <Input type="text" />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" htmlType='submit' className='mt-4'>
            {!!user.id && <>Edit User</>}
          </Button>
        </Form.Item>
      </Form>
    </div >
  );
}