import React from 'react';

import { AddFilter, HavingFilter, YcColumnType } from '@/components';

export function getStringColumn<ResultLine>({ filters, title, dataKey, itemKey, onSetFilter }: {
  filters: Filters;
  title: string;
  dataKey: string;
  itemKey?: string;
  onSetFilter: ({ key, value, checked }) => void;
}) {
  return {
    title,
    key: dataKey,
    dataIndex: dataKey,
    ycSort: 'string' as const,
    ycCanNotHide: true,
    ...(itemKey ? {
      render: (_text, record) => (<div>
        {record[dataKey]}
        <AddFilter key={itemKey} item={itemKey} value={record[dataKey]} filters={filters} onSetFilter={onSetFilter} />
      </div>)
    } : {}),
  } as YcColumnType<ResultLine>;
}

function Filter<ResultLine>({ filterKey, results, _setTableData }: { results: ResultLine[], filterKey: string, _setTableData: (value: React.SetStateAction<ResultLine[]>) => void }) {
  return <HavingFilter
    onFilter={(v, c) => _setTableData(results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
    onReset={() => _setTableData(results)}
    filterKey={filterKey}
  />;
}

export function getMetricColumn<ResultLine, T>({ title, dataKey, numberFormat, config, results, _setTableData }: {
  title: string;
  dataKey: string;
  numberFormat: (nb: number) => string | number | undefined;
  config?: T;
  results: ResultLine[];
  _setTableData: (value: React.SetStateAction<ResultLine[]>) => void;
}) {
  return {
    title, dataIndex: dataKey, key: dataKey, ycSort: 'number' as const,
    render: (_text, record) => numberFormat(record[dataKey] as number),
    filterDropdown: <Filter filterKey={dataKey} results={results} _setTableData={_setTableData} />,
    ...(config || {}),
  } as YcColumnType<ResultLine>;
}
