/**
 * The following table lists the various playback methods.
 */
enum PlaybackCessationModes {
  /** On Video Completion or when Terminated by User */
  ON_VIDEO_COMPLETION = 1,

  /** On Video Completion or when Terminated by User */
  ON_LEAVING_VIEWPORT = 2,

  /**
   * On Leaving Viewport Continues as a Floating/Slider Unit until Video Completion or when
   * Terminated by User
   */
  ON_VIEWPORT_TO_FLOATING_UNTIL_VIDEO_COMPLETION = 3,
}

export default PlaybackCessationModes;
