import { BaseService } from "./base.service";
import { MPlatformAction } from '../modeles';

class PlatformActionsService extends BaseService {

  PATH = "platform-actions/";

  async listing(): Promise<MPlatformAction[] | null> {
    return await this._listing(this.PATH + "listing");
  }

  async listingSuperadmin(): Promise<MPlatformAction[] | null> {
    return await this._listing(this.PATH + "listing-superadmin");
  }

  async view(id: number): Promise<MPlatformAction | null> {
    return await this._view(this.PATH + "view/" + id);
  }
}

export const platformActionsService = new PlatformActionsService();