
// styles
import './loading.scss';

export function YcLoading(props: {
  loading: boolean,
  children: any,
}) {

  return (
    <div className="c-loading">
      {props.children}
      {props.loading && <div className="c-loading-over"></div>}
    </div>
  );
}