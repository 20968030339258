import moment from "moment";
import { Button, Form, Spin } from 'antd';

import { YcFieldCheck, YcDatePicker, } from "@/components";

import "./reporting-filter.component.scss";

function FilterFormItem({ itemKey, isKey, filters, onSetFilters, ...rest }: {
  itemKey: string;
  isKey: string;
  label: string;
  onSetFilters: (filters: any) => void;
  filters: any;
  mode?: "multiple" | "tags" | undefined;
  disabledSelect?: boolean;
}) {
  return (
    <Form.Item>
      <YcFieldCheck
        is={filters![isKey]}
        items={filters![itemKey] || []}
        onChange={(itemValue, isValue) => onSetFilters({ ...filters, [itemKey]: itemValue, [isKey]: isValue })}
        {...rest}
      />
    </Form.Item>
  );
}

export function ReportingFilter(props: {
  loading: boolean;
  dates: [moment.Moment, moment.Moment];
  onDatesChange: (e: any) => void;
  datePresets: readonly ['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'];
  onSearch: () => void;
  onSearchDownloadData?: () => void;
  filterProps: FilterPropType[];
}) {
  return (
    <div id='reporting-filter'>
      <Spin spinning={props.loading}>
        <Form>
          <Form.Item>
            <YcDatePicker value={props.dates} onChange={props.onDatesChange} presets={props.datePresets} />
          </Form.Item>
          {props.filterProps.filter((f) => f.show).map((f) => <FilterFormItem {...f} />)}
          <Form.Item>
            <Button type="primary" onClick={props.onSearch}>Search</Button>
            {props.onSearchDownloadData && <Button type="primary" className='ms-2' onClick={props.onSearchDownloadData}>Search and download</Button>}
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}
