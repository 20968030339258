/**
 * The following table lists the various options for the type of device connectivity.
 */

enum ConnectionType {
  UNKNOWN = 0,
  ETHERNET = 1,
  WIFI = 2,
  CELLULAR_NETWORK_UNKNOWN = 3,
  CELLULAR_NETWORK_2G = 4,
  CELLULAR_NETWORK_3G = 5,
  CELLULAR_NETWORK_4G = 6,
}

export default ConnectionType;
