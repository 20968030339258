import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { homeService } from '@/services';
import { ColumnLineGraph } from '@/components';

function formatSpend(s?: number | null) {
  return `${s?.toFixed(2)} $`;
}

interface AppAdvertiserPageProps {
  bundleId: string;
  platform: string;
}

export function AppAdvertiserPage(props: AppAdvertiserPageProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    {
      date: string;
      installs: number;
      spend: number;
    }[]
  >([]);

  function updateAdEventsByMinute() {
    setLoading(true);

    homeService
      .getInstallsSpendAdvertiser(props.bundleId, props.platform)
      .then((data) => {
        setData(data?.last7days || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAdEventsByMinute, []);

  return (
    <Spin spinning={loading}>
      <div className="network-card">
        <ColumnLineGraph data={data} xKey="date" barKey="installs" lineKey="spend" title="Advertiser" formatLineKey={formatSpend} />
      </div>
    </Spin>
  );
}
