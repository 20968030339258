import React, { ReactNode } from 'react';
import { Redirect } from "react-router-dom";
import { useAuths } from '@/contexts/auths.context';
import { Permissions } from '@/modeles';

export interface ProtectedComponentProps {
  permission?: string;
  admin?: boolean;
  superAdmin?: boolean;
  redirect?: string;
  children?: ReactNode;
}

function validate(props: ProtectedComponentProps, permissions: Permissions | null, admin: boolean, superAdmin: boolean) {
  return (admin && props.admin)
    || (superAdmin)
    || (props.permission && permissions && permissions.isAllowed(props.permission));
}

export const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ permission, admin, superAdmin, children, redirect = '/' }) => {
  const { permissions, isAdmin, isSuperAdmin } = useAuths();

  if (validate(
    { permission, admin, superAdmin },
    permissions,
    isAdmin(),
    isSuperAdmin(),
  )) {
    return <>{children}</>;
  }
  return <Redirect to={redirect} />;
}
