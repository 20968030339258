import { BaseService } from "./base.service";
import { MUser } from '../modeles';

class UsersService extends BaseService {

  PATH = "users/";

  async listing(): Promise<MUser[] | null> {
    return await this._listing(this.PATH + "listing");
  }

  async add(user: MUser, accountId?: string | null): Promise<MUser | null> {
    return await this._add(this.PATH + "add", {
      user,
      accountId,
    });
  }

  async view(id: number): Promise<MUser | null> {
    return await this._view(this.PATH + "view/" + id);
  }

  async edit(id: number, user: MUser): Promise<MUser | null> {
    return await this._edit(this.PATH + "edit/" + id, user);
  }

  async activate(id: number, isActive: boolean): Promise<MUser[]> {
    return await this._listing(this.PATH + "activate/" + id + "/" + isActive) || [];
  }
}

export const usersService = new UsersService();