import { BaseService } from "./base.service";

export class ReportingBaseService extends BaseService {
  PATH = "TO REPLACE";

  async filters(data: any): Promise<any | null> {
    return await this._view(`${this.PATH}filters`, data);
  }
  async search(data: any): Promise<any | null> {
    return await this._view(`${this.PATH}search`, data);
  }
}
