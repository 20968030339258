import { Card } from 'antd';
import { PropsWithChildren, useState } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import './card.scss';

const Title = (props: { 
  title: string; 
  open: boolean; 
  disableOpen: boolean;
  onClick: () => void;
}) => (
  <div className={"yc-title d-flex justify-content-between " + (props.disableOpen === true ? '' : "pointerCursor")} onClick={props.onClick}>
    <div>{props.title}</div>
    {props.disableOpen === false && <div>{props.open ? <UpOutlined /> : <DownOutlined />}</div>}
  </div>
)

export function YcCard(props: PropsWithChildren<{
  title: string;
  className?: string;
  open?: boolean;
  disableOpen?: boolean;
}>) {
  const [open, _setOpen] = useState(props.open || false);

  return (
    <Card
      title={<Title title={props.title} open={open} disableOpen={props.disableOpen || false} onClick={() => {
        if (props.disableOpen !== true) {
          _setOpen(!open);
        }
      }} />}
      className={"yc-card " + props.className + " " + (open ? "open" : "close")}
    >
      {open && props.children}
    </Card>
  );
}