/**
 * The following table is a list of API frameworks supported by the publisher.
 */
enum APIFrameworks {
  VPAID_1_0 = 1,
  VPAID_2_0 = 2,
  MRAID_1 = 3,
  ORMMA = 4,
  MRAID_2 = 5,
  MRAID_3 = 6,
}
export default APIFrameworks;
