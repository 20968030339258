import { usePageTitle } from '@/hooks';
import { YcFooter, YcHeaderTitle, YcDocReader } from '@/components';

import './sdk-opt-out.page.scss';

export function SdkOptOut() {
  usePageTitle('sdk-opt-out');

  const headerTitle = <h1>SDK Opt-Out</h1>

  return (
    <div id="root-sdk-opt-out">
      <YcHeaderTitle title={headerTitle} />
      <div className='container d-flex justify-content-center mt-5'>
        <YcDocReader file='/sdk-opt-out.html'/>
      </div>
      <YcFooter />
    </div>
  );
}


