import { Button, Input, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import qs from 'qs'

import { apiTryService } from '@/services';

import './query.scss';
import Checkbox from 'antd/lib/checkbox/Checkbox';

export interface YcQueryData {
  method: "get" | "post",
  url: string,
  bodyParams: [],
  queryParams: [],
  returnType: [],
  queryParamsExample: {},
  queryParamsExampleRequire: {},
  bodyParamsExample: {},
  bodyParamsExampleRequire: {},
  returnTypeExample: {},
  returnTypeExampleRequire: {},
}

export function YcQuery(props: {
  data: YcQueryData,
  headers: Record<string, string>
}) {

  const [required, _setRequired] = useState(true);
  const [result, _setResult] = useState<{} | [] | null>(null);
  const [bodyParams, _setBodyParams] = useState<string>(JSON.stringify(props.data.bodyParamsExampleRequire, null, 2));
  const [queryParams, _setQueryParams] = useState<string>(JSON.stringify(props.data.queryParamsExampleRequire, null, 2));
  const [values, _setValues] = useState<{ [key: string]: string }>({});
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    _setBodyParams(JSON.stringify(required ? props.data.bodyParamsExampleRequire : props.data.bodyParamsExample, null, 2));
    _setQueryParams(JSON.stringify(required ? props.data.queryParamsExampleRequire : props.data.queryParamsExample, null, 2));
  }, [props.data, required]);

  const showUrl = () => {
    const results = props.data.url.match(/(:[a-z]+)/g);
    let url: (string | React.ReactNode)[] = [props.data.url];
    results?.forEach((res, index) => {
      const tmp = ("" + url[url.length - 1]).split(res);
      res = res.replace(':', '');
      url[url.length - 1] = tmp[0];
      url.push(<Input key={index} placeholder={res} className="pinput" value={values[res]}
        onChange={(e) => {
          const tt = {
            ...values
          };
          tt[res] = e.target.value;
          _setValues(tt);
        }}
      />);
      if (tmp[1]) {
        url.push(tmp[1]);
      }
    });
    return url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const getUrl = () => {
    const results = props.data.url.match(/(:[a-z]+)/g);
    let url = props.data.url;
    results?.forEach((e) => {
      url = url.replace(e, values[e.replace(':', '')]);
    });
    return url;
  }

  const parseString = useCallback(() => {
    try {
      return qs.stringify(JSON.parse(queryParams), { arrayFormat: 'comma', encode: false });
    } catch (e) {
      return "";
    }
  }, [queryParams]);

  const onTry = async () => {
    _setLoading(true);
    try {
      let r: any = {};
      if (props.data.method === "get") {
        r = await apiTryService.tryGet(getUrl(), JSON.parse(queryParams), props.headers!);
      } else if (props.data.method === "post") {
        r = await apiTryService.tryPost(getUrl(), JSON.parse(bodyParams), props.headers!);
      }
      if (r.data && Array.isArray(r.data)) {
        r.data = r.data.slice(0, 10);
      }
      _setResult(r);
    } catch (e) {
      message.error("Error parser");
    }
    _setLoading(false);
  }

  return (
    <Spin spinning={loading}>
      <span className="c-query">
        <pre className="url">
          <span className="plabel">Url</span>
          [<b>{props.data.method.toUpperCase()}</b>] https://api.ysonetwork.com{showUrl()}?{parseString()} &nbsp;
        </pre>
        {!!props.headers &&
          <pre className="header">
            <span className="plabel">Header</span>
            {Object.entries(props.headers).map(([key, value]) =>
              <div key={key}>
                {key} : {value}
              </div>
            )}
          </pre>
        }
        {queryParams !== "null" &&
          <pre className="query-param">
            <span className="plabel">Query Params</span>
            <div className="row">
              <div className="col-6">
                <div className="dtextarea">
                  <span className="plabel">Data</span>
                  <textarea value={queryParams} onChange={(e) => {
                    _setQueryParams(e.target.value);
                  }}></textarea>
                </div>
                <Checkbox checked={required} onChange={() => _setRequired(!required)}>Required fields only</Checkbox>
              </div>
              <div className="col-6">
                <div className="dtextarea">
                  <span className="plabel">Example</span>
                  <textarea defaultValue={JSON.stringify(props.data.queryParamsExample, null, 2)} disabled={true}></textarea>
                </div>
              </div>
            </div>
          </pre>
        }
        {bodyParams !== "null" &&
          <pre className="query-param">
            <span className="plabel">Body Params</span>
            <div className="row">
              <div className="col-6">
                <div className="dtextarea">
                  <span className="plabel">Data</span>
                  <textarea value={bodyParams} onChange={(e) => {
                    _setBodyParams(e.target.value);
                  }}></textarea>
                </div>
                <Checkbox checked={required} onChange={() => _setRequired(!required)}>Required fields only</Checkbox>
              </div>
              <div className="col-6">
                <div className="dtextarea">
                  <span className="plabel">Example</span>
                  <textarea value={JSON.stringify(props.data.bodyParamsExample, null, 2)} disabled={true}></textarea>
                </div>
              </div>
            </div>
          </pre>
        }
        <pre className="try">
          <Button type="primary" onClick={onTry}>Run</Button>
        </pre>
        <pre className="Result">
          <span className="plabel">Result</span>
          <div className="row">
            <div className="col-6">
              <div className="dtextarea">
                <span className="plabel">Data (limited to 10 elements for lists)</span>
                {!!result && <textarea value={JSON.stringify(result, null, 2)}></textarea>}
                {!result && <textarea></textarea>}
              </div>
            </div>
            <div className="col-6">
              <div className="dtextarea">
                <span className="plabel">Example</span>
                <textarea disabled={true} value={JSON.stringify({ data: props.data.returnTypeExample, date: 0 }, null, 2)}></textarea>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
            </div>
            <div className="col-6">
            </div>
          </div>
        </pre>
      </span >
    </Spin >
  );
}
