import Producer from './Producer';
import ContentCategories from '../enum/ContentCategories';
import ProductionQuality from '../enum/ProductionQuality';
import ContentContext from '../enum/ContentContext';
import IQGMediaRatings from '../enum/IQGMediaRatings';
import Data from './Data';

export enum Livestream {
  LIVE = 1,
  NOT_LIVE = 0,
}

export enum SourceRelationship {
  DIRECT = 1,
  INDIRECT = 0,
}

export enum Embeddable {
  YES = 1,
  NO = 0,
}

/**
 * Content:
 * This object describes the content in which the impression will appear, which may be syndicated or non-
 * syndicated content. This object may be useful when syndicated content contains impressions and does
 * not necessarily match the publisher’s general content. The exchange might or might not have
 * knowledge of the page where the content is running, as a result of the syndication method. For
 * example might be a video impression embedded in an iframe on an unknown web property or device.
 */

export default interface Content {
  /**
   * ID uniquely identifying the content.
   */
  id?: string;

  /**
   * Episode number.
   */
  episode?: number;

  /**
   * Content title
   *
   * @example
   *    Video examples:
   *      - "Search Committee" (television),
   *      - "A NewHope" (movie),
   *      - "Endgame"(made for web)
   *    Non-Video example:
   *      - "Why an Antarctic Glacier Is Melting So Quickly" (Time magazine article).
   */
  title?: string;

  /**
   * Content series
   *
   * @example
   *    Video examples:
   *      - "The Office" (television),
   *      - "Star Wars" (movie),
   *      - "Arby ‘N’ The Chief" (made for web),
   *    Non-Video Example:
   *      - "Ecocentric" (Time Magazine blog).
   */
  series?: string;

  /**
   * Content season
   *
   * @example "Season 3"
   */
  season?: string;

  /**
   * Artist credited with the content.
   */
  artist?: string;

  /**
   * Genre that best describes the content
   *
   * @example "rock", "pop"
   */
  genre?: string;

  /**
   * Album to which the content belongs; typically for audio.
   */
  album?: string;

  /**
   * International Standard Recording Code conforming to ISO3901.
   */
  isrc?: string;

  /**
   * Details about the content Producer (Section 3.2.17).
   */
  producer?: Producer;

  /**
   * URL of the content, for buy-side contextualization or review.
   */
  url?: string;

  /**
   * Array of IAB content categories that describe the content
   * producer. Refer to List 5.1
   */
  cat?: Array<ContentCategories>;

  /**
   * Production quality. Refer to List 5.13.
   */
  prodq?: ProductionQuality;

  /**
   * @deprecated
   *
   * Note: Deprecated in favor of prodq.
   * Video quality. Refer to List 5.13.
   */
  videoquality?: ProductionQuality;

  /**
   * Type of content (game, video, text, etc.). Refer to List 5.18.
   */
  context?: ContentContext;

  /**
   * Content rating (e.g., MPAA).
   */
  contentrating?: string;

  /**
   * User rating of the content (e.g., number of stars, likes, etc.).
   */
  userrating?: string;

  /**
   * Media rating per IQG guidelines. Refer to List 5.19.
   */
  qagmediarating?: IQGMediaRatings;

  /**
   * Comma separated list of keywords describing the content.
   */
  keywords?: string;

  /**
   * 0 = not live, 1 = content is live (e.g., stream, live blog).
   */
  livestream?: Livestream;

  /**
   * 0 = indirect, 1 = direct.
   */
  sourcerelationship?: SourceRelationship;

  /**
   * Length of content in seconds; appropriate for video or audio.
   */
  len?: number;

  /**
   * Content language using ISO-639-1-alpha-2.
   */
  language?: string;

  /**
   * Indicator of whether or not the content is embeddable (e.g.,
   * an embeddable video player), where 0 = no, 1 = yes.
   */
  embeddable?: Embeddable;

  /**
   * Additional content data. Each Data object (Section 3.2.21)
   * represents a different data source.
   */
  data?: Array<Data>;

  /**
   * Placeholder for exchange-specific extensions to OpenRTB.
   */
  ext?: any;
}
