import { BaseService } from "./base.service";
import { MPlacement } from '../modeles';

class PlacementsService extends BaseService {

  PATH = "publishing/placements/";

  async listing(data: any): Promise<MPlacement[] | null> {
    return await this._listing(this.PATH + "listing", data);
  }

  async add(campaign: MPlacement): Promise<MPlacement | null> {
    return await this._add(this.PATH + "add", campaign);
  }

  async view(key: string): Promise<MPlacement | null> {
    return await this._view(this.PATH + "view/" + key);
  }

  async edit(key: string, app: MPlacement): Promise<MPlacement | null> {
    return await this._edit(this.PATH + "edit/" + key, app);
  }

  async activate(key: string, isActive: boolean): Promise<boolean> {
    return await this._getSuccess(this.PATH + "activate/" + key + "/" + isActive);
  }

}

export const placementsService = new PlacementsService();