import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useUrlParams() {
  const { search } = useLocation();
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams());

  useEffect(() => {
    setParams(new URLSearchParams(search));
  }, [search]);

  const getUrlParam = useCallback((paramName: string) => {
    return params.get(paramName);
  }, [params]);

  return {
    getUrlParam,
  }
}
