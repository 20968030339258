import { adsLearningService_SA } from '@/services/superadmin/ads-learning-sa.service';
import { useCallback, useEffect, useState } from 'react';
import { YcEnums } from 'yc-interfaces';

export interface AdLearning {
  key: string;
  content: string;
  state: {
    total: number;
    running: number;
    prerunning: number;
    learning: number;
  } | YcEnums.AdPhases;
  stats: {
    impressions: number;
    clicks: number;
    installs: number;
  };
  children?: AdLearning[];
}

export function useAdsLearning() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AdLearning[]>([]);
  const [displayData, setDisplayData] = useState<AdLearning[]>([]);

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const { value } = event.target;

    if (value) {
      const dApps = data.filter((a) => {
        const checkName = a.content.toLowerCase().includes(value?.toLowerCase());
        const checkKey = a.key.toLowerCase().includes(value?.toLowerCase());

        return checkName || checkKey;
      });

      setDisplayData(dApps);
    } else {
      setDisplayData(data);
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    adsLearningService_SA.listing().then((d) => {
      setData(d);
      setDisplayData(d);
      setLoading(false);
    });
  }, []);

  return {
    loading,
    data: displayData,
    onSearch,
  };
}
