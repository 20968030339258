import Deal from './Deal';

export enum PrivateAuction {
  ALL_BIDS_ACCEPTED = 0,
  RESTRICTED_DEALS = 1,
}

/**
 * Pmp:
 * This object is the private marketplace container for direct deals between buyers and sellers that may
 * pertain to this impression. The actual deals are represented as a collection of Deal objects.
 */
export default interface Pmp {
  /**
   * private_auction: Indicator of auction eligibility to seats named in the Direct Deals object,
   * where 0 = all bids are accepted,
   * 1 = bids are restricted to the deals specified and the terms thereof.
   */
  private_auction?: PrivateAuction;

  /** deals: */
  deals?: Array<Deal>;

  /** ext: Placeholder for exchange-specific extensions to OpenRTB. */
  ext?: any;
}
