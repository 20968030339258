/**
 * The following table lists the various options for indicating the type of content being used or consumed
 * by the user in which the impression will appear. This OpenRTB table has values derived from the
 * Inventory Quality Guidelines (IQG). Practitioners should keep in sync with updates to the IQG values.
 */
enum ContentContext {
  VIDEO = 1,
  GAME = 2,
  MUSIC = 3,
  APPLICATION = 4,
  TEXT = 5,
  OTHER = 6,
  UNKNOWN = 7,
}
export default ContentContext;
