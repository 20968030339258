import { useEffect, useState } from "react";
import { VideoCameraOutlined, FileImageOutlined, GifOutlined, Html5Outlined } from '@ant-design/icons';

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import { docsService } from "../../../../services/docs.service";
import { YcQuery, YcQueryData } from "../../components";

import './docs-manage-creatives.page.scss';

export function DocsManageCreativesPage() {

  const PATH = "/api/campaigns";

  const [tab, _setTab] = useState("video");
  const [headers, _setHeaders] = useState<{} | null>(null);
  const [docs, _setDocs] = useState<{ [key: string]: YcQueryData } | null>(null);

  useEffect(() => {
    async function init() {
      _setDocs((await docsService.view(PATH)).routes);
      const managerKey = await profileService.viewManagerOrReportKey("managerKey");
      if (managerKey) {
        _setHeaders({
          authorization: managerKey,
        });
      } else {
        _setHeaders({ authorization: "NO managerKey active FOUND IN PROFILE" });
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='docs-manage-creatives'>
      <YcTitle label="Docs > Manage Creatives"></YcTitle>
      <p>
        In this page you can manage creatives.<br/><br/>

        For all upload routes, you need to send exactly 1 file with FormData in 'asset' key.<br/>
        For campaign edition you must add the campaign key '/:key' at the end of the upload url, this campaign key parameter is not needed for campaign creation.
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "video", label: "Video", icon: <VideoCameraOutlined /> },
        { key: "image", label: "Image", icon: <FileImageOutlined /> },
        { key: "endcard", label: "Endcard", icon: <GifOutlined /> },
        { key: "playable", label: "Playable", icon: <Html5Outlined /> },
        { key: "banner", label: "Banner", icon: <FileImageOutlined /> },
      ]} />
      <div className="doc-container">
        {!!headers &&
          <>
            {tab === "video" &&
              <YcQuery data={docs![PATH + '/upload-video']} headers={headers} />
            }
            {tab === "image" &&
              <YcQuery data={docs![PATH + '/upload-image']} headers={headers} />
            }
            {tab === "endcard" &&
              <YcQuery data={docs![PATH + '/upload-endcard']} headers={headers} />
            }
            {tab === "playable" &&
              <YcQuery data={docs![PATH + '/upload-playable']} headers={headers} />
            }
            {tab === "banner" &&
              <YcQuery data={docs![PATH + '/upload-banner']} headers={headers} />
            }
          </>
        }
      </div>
    </div>
  );
}