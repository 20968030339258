import { AspectRatios, Orientations } from './enums';

export interface VideoSizeInterface {
  w: number;
  h: number;
  r: AspectRatios;
  o: Orientations;
}

export const VideoSizes: VideoSizeInterface[] = [
  {
    w: 640, h: 360, r: AspectRatios['16:9'], o: Orientations.LANDSCAPE,
  },
  {
    w: 854, h: 480, r: AspectRatios['16:9'], o: Orientations.LANDSCAPE,
  },
  {
    w: 1024, h: 576, r: AspectRatios['16:9'], o: Orientations.LANDSCAPE,
  },
  {
    w: 1280, h: 720, r: AspectRatios['16:9'], o: Orientations.LANDSCAPE,
  },
  {
    w: 1920, h: 1080, r: AspectRatios['16:9'], o: Orientations.LANDSCAPE,
  },
  {
    w: 640, h: 480, r: AspectRatios['4:3'], o: Orientations.LANDSCAPE,
  },
  {
    w: 768, h: 576, r: AspectRatios['4:3'], o: Orientations.LANDSCAPE,
  },
  {
    w: 1440, h: 1080, r: AspectRatios['4:3'], o: Orientations.LANDSCAPE,
  },
];
