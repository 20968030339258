import { YcAssetPreview } from '../../../../components';
import { MCampaign, MCampaignAsset } from '../../../../modeles';
import { YcCampaignAssets } from '../a-assets';

// styles
import './campaign-banners.scss';
import { YcCampaignAssetRequirements } from '../a-requirements';

export function CampaignBanners(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {

  async function beforeUpload(file: File) {
    return true;
  }

  return (
    <div className="c-campaign-banners">
      <YcCampaignAssets
        dataKey='Banners'
        requirements={<YcCampaignAssetRequirements
          fileType='JPEG, PNG'
          size={{ max: 1 }}
          resolution={[{ name: '320x50' }, { name: '728x90' }]}
        />}
        campaign={props.campaign}
        _setCampaign={props._setCampaign}
        beforeUpload={beforeUpload}
        path="banner"
        render={(asset: MCampaignAsset) => (<div className="c-banner">
          <YcAssetPreview src={asset?.Document?.url!} width={320} alt="Banner" fileName={asset.Document?.originalFileName!}></YcAssetPreview>
        </div>)}
      ></YcCampaignAssets>
    </div >
  );
}