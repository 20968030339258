import { Button, Form, FormInstance, Input, Select, Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { YcIcon, YcTitle } from '@/components';
import { unifiedAppsService } from '@/services';
import { MApp, MUnifiedApp } from '@/modeles';
import { usePageTitle } from '@/hooks/use-title';

import './unified-app.page.scss';

export function SAUnifiedAppPage() {
  usePageTitle('Unified App');

  const [loading, setLoading] = useState(false);
  const [showMappedApps] = useState(false);
  const [unifiedApp, setUnifiedApp] = useState<MUnifiedApp>(new MUnifiedApp());
  const [apps, setApps] = useState<MApp[]>([]);
  const uParams = useParams();
  const uHistory = useHistory();
  const formRef = useRef<FormInstance>(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      uParams['key'] && unifiedAppsService.view(uParams['key']),
      unifiedAppsService.listingUnattributedApps(),
    ]).then(([unified, as]) => {
      setApps(as);
      if (!!uParams['key']) {
        setUnifiedApp(unified!);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedApps = useMemo(() => {
    return unifiedApp.apps.map((a) => a.key);
  }, [unifiedApp]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFields([
        { name: 'name', value: unifiedApp.name },
        { name: 'apps', value: selectedApps },
      ]);
    }
  }, [selectedApps, unifiedApp, formRef]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const u = { ...unifiedApp, ...values };
    if (!!uParams['key']) {
      const data = await unifiedAppsService.edit(unifiedApp.id.toString(), u);
      if (data) {
        setUnifiedApp(data);
      }
    } else {
      const data = await unifiedAppsService.add(u);
      if (data) {
        setUnifiedApp(data);
        uHistory.push("/superadmin/unified-apps/unified-app/" + data?.id);
      }
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <div id='unified-app'>
        <YcTitle links={[{ link: "/superadmin/unified-apps", label: "Unified apps" }]} label={unifiedApp.name} ></YcTitle>
        <Form ref={formRef} onFinish={onFinish} layout="vertical">
          <div className='row'>
            <div className='col-4'>
              <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={unifiedApp.name}>
                <Input type="text" disabled={!!uParams['key']} />
              </Form.Item>
            </div>
            <div className='col-8'>
              <Form.Item label="Applications" name="apps" rules={[{ required: true }]} initialValue={selectedApps}>
                <Select showSearch mode='multiple' allowClear>
                  {apps.filter((application) => showMappedApps || !application.unifiedApplicationId).map(application => (
                    <Select.Option key={application.key} value={application.key}>
                      <span className='mx-2'><YcIcon app={application}></YcIcon></span>
                      {application.name} - {application.platform}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button type="primary" htmlType='submit' className='mt-4 submit'>
              {!uParams['key'] && <>Create Unified App</>}
              {!!uParams['key'] && <>Update Unified App</>}
            </Button>
          </Form.Item>
        </Form>
      </div >
    </Spin>
  )
}
