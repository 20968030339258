/**
 * The following table indicates the options for video linearity.
 * "In-stream" or "linear" video refers to preroll, post-roll, or mid-roll video ads where the user
 * is forced to watch ad in order to see the video
 * content. "Overlay" or "non-linear" refer to ads that are shown on top of the video content.
 * This OpenRTB table has values derived from the Inventory Quality Guidelines (IQG). Practitioners should
 * keep in sync with updates to the IQG values.
 */
enum VideoLinearity {
  LINEAR = 1,
  IN_STREAM = 1,
  NON_LINEAR = 2,
  OVERLAY = 2,
}

export default VideoLinearity;
