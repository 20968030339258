import CreativeAttributes from '../enum/CreativeAttributes';
import StartDelay from '../enum/StartDelay';
import ContentDeliveryMethods from '../enum/ContentDeliveryMethods';
import Banner from './Banner';
import APIFrameworks from '../enum/APIFrameworks';
import CompanionTypes from '../enum/CompanionTypes';
import Protocols from '../enum/Protocols';
import FeedTypes from '../enum/FeedTypes';
import VolumeNormalizationModes from '../enum/VolumeNormalizationModes';

export enum Stiched {
  NO = 0,
  YES = 1,
}

/**
 * Audio:
 * This object represents an audio type impression. Many of the fields are non-essential for minimally
 * viable transactions, but are included to offer fine control when needed. Audio in OpenRTB generally
 * assumes compliance with the DAAST standard. As such, the notion of companion ads is supported by
 * optionally including an array of Banner objects that define these companion ads.
 * The presence of a Audio as a subordinate of the Imp object indicates that this impression is offered as
 * an audio type impression. At the publisher’s discretion, that same impression may also be offered as
 * banner, video, and/or native by also including as Imp subordinates objects of those types. However, any
 * given bid for the impression must conform to one of the offered types.
 */
export default interface Audio {
  /**
   * Content MIME types supported
   * @example `audio/mp4`
   */
  mimes: Array<string>;

  /**
   * Minimum audio ad duration in seconds.
   */
  minduration?: number;

  /**
   * Maximum audio ad duration in seconds.
   */
  maxduration?: number;

  /**
   * Array of supported audio protocols. Refer to List 5.8.
   */
  protocols?: Array<Protocols>;

  /**
   * Indicates the start delay in seconds for pre-roll, mid-roll, or
   * post-roll ad placements. Refer to List 5.12.
   */
  startdelay?: StartDelay | number;

  /**
   * If multiple ad impressions are offered in the same bid request,
   * the sequence number will allow for the coordinated delivery
   * of multiple creatives.
   */
  sequence?: number;

  /**
   * Blocked creative attributes. Refer to List 5.3.
   */
  battr?: Array<CreativeAttributes>;

  /**
   * Maximum extended ad duration if extension is allowed. If
   * blank or 0, extension is not allowed. If -1, extension is
   * allowed, and there is no time limit imposed. If greater than 0,
   * then the value represents the number of seconds of extended
   * play supported beyond the maxduration value.
   */
  maxextended?: number;

  /**
   * Minimum bit rate in Kbps.
   */
  minbitrate?: number;

  /**
   * Maximum bit rate in Kbps.
   */
  maxbitrate?: number;

  /**
   * Supported delivery methods (e.g., streaming, progressive). If
   * none specified, assume all are supported. Refer to List 5.15.
   */
  delivery?: Array<ContentDeliveryMethods>;

  /**
   * Array of Banner objects (Section 3.2.6) if companion ads are
   * available.
   */
  companionad?: Array<Banner>;

  /**
   * List of supported API frameworks for this impression. Refer to
   * List 5.6. If an API is not explicitly listed, it is assumed not to be
   * supported.
   */
  api?: Array<APIFrameworks>;

  /**
   * Supported DAAST companion ad types. Refer to List 5.14.
   * Recommended if companion Banner objects are included via
   * the companionad array.
   */
  companiontype?: Array<CompanionTypes>;

  /**
   * The maximum number of ads that can be played in an ad pod.
   */
  maxseq?: number;

  /**
   * Type of audio feed. Refer to List 5.16
   */
  feed?: FeedTypes;

  /**
   * Indicates if the ad is stitched with audio content or delivered
   * independently, where 0 = no, 1 = yes.
   */
  stitched?: Stiched;

  /**
   * Volume normalization mode. Refer to List 5.17.
   */
  nvol?: VolumeNormalizationModes;

  /**
   * Placeholder for exchange-specific extensions to OpenRTB.
   */
  ext?: any;
}
