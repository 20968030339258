export enum ARTS_ENTERTAINMENT {
  BASE = 'IAB1',
  BOOKS_LITERATURE = 'IAB1-1',
  CELEBRITY_FAN_GOSSIP = 'IAB1-2',
  FINE_ART = 'IAB1-3',
  HUMOR = 'IAB1-4',
  MOVIES = 'IAB1-5',
  MUSIC = 'IAB1-6',
  TELEVISION = 'IAB1-7',
}

export enum AUTOMOTIVE {
  BASE = 'IAB2',
  AUTO_PARTS = 'IAB2-1',
  AUTO_REPAIR = 'IAB2-2',
  BUYING_SELLING_CARS = 'IAB2-3',
  CAR_CULTURE = 'IAB2-4',
  CERTIFIED_PRE_OWNED = 'IAB2-5',
  CONVERTIBLE = 'IAB2-6',
  COUPE = 'IAB2-7',
  CROSSOVER = 'IAB2-8',
  DIESEL = 'IAB2-9',
  ELECTRIC_VEHICLE = 'IAB2-10',
  HATCHBACK = 'IAB2-11',
  HYBRID = 'IAB2-12',
  LUXURY = 'IAB2-13',
  MINIVAN = 'IAB2-14',
  MOTORCYCLES = 'IAB2-15',
  OFF_ROAD_VEHICLES = 'IAB2-16',
  PERFORMANCE_VEHICLES = 'IAB2-17',
  PICKUP = 'IAB2-18',
  ROAD_SIDE_ASSISTANCE = 'IAB2-19',
  SEDAN = 'IAB2-20',
  TRUCKS_ACCESSORIES = 'IAB2-21',
  VINTAGE_CARS = 'IAB2-22',
  WAGON = 'IAB2-23',
}
export enum BUSINESS {
  BASE = 'IAB3',
  ADVERTISING = 'IAB3-1',
  AGRICULTURE = 'IAB3-2',
  BIOTECH_BIOMEDICAL = 'IAB3-3',
  BUSINESS_SOFTWARE = 'IAB3-4',
  CONSTRUCTION = 'IAB3-5',
  FORESTRY = 'IAB3-6',
  GOVERNMENT = 'IAB3-7',
  GREEN_SOLUTIONS = 'IAB3-8',
  HUMAN_RESOURCES = 'IAB3-9',
  LOGISTICS = 'IAB3-10',
  MARKETING = 'IAB3-11',
  METALS = 'IAB3-12',
}
export enum CAREERS {
  BASE = 'IAB4',
  CAREER_PLANNING = 'IAB4-1',
  COLLEGE = 'IAB4-2',
  FINANCIAL_AID = 'IAB4-3',
  JOB_FAIRS = 'IAB4-4',
  JOB_SEARCH = 'IAB4-5',
  RESUME_WRITING_ADVICE = 'IAB4-6',
  NURSING = 'IAB4-7',
  SCHOLARSHIPS = 'IAB4-8',
  TELECOMMUTING = 'IAB4-9',
  US_MILITARY = 'IAB4-10',
  CAREER_ADVICE = 'IAB4-11',
}
export enum EDUCATION {
  BASE = 'IAB5',
  YOUNG_EDUCATION = 'IAB5-1',
  ADULT_EDUCATION = 'IAB5-2',
  ART_HISTORY = 'IAB5-3',
  COLLEGE_ADMINISTRATION = 'IAB5-4',
  COLLEGE_LIFE = 'IAB5-5',
  DISTANCE_LEARNING = 'IAB5-6',
  ENGLISH_AS_SEGOND_LANGUAGE = 'IAB5-7',
  LANGUAGE_LEARNING = 'IAB5-8',
  GRADUATE_SCHOOL = 'IAB5-9',
  HOMESCHOOLING = 'IAB5-10',
  HOMEWORK_STUDY_TIPS = 'IAB5-11',
  KSIX_EDUCATORS = 'IAB5-12',
  PRIVATE_SCHOOL = 'IAB5-13',
  SPECIAL_EDUCATION = 'IAB5-14',
  STUDYING_BUSINESS = 'IAB5-15',
}
export enum FAMILY_PARENTING {
  BASE = 'IAB6',
  ADOPTION = 'IAB6-1',
  BABIES_TODDLERS = 'IAB6-2',
  DAYCARE_PRE_SCHOOL = 'IAB6-3',
  FAMILY_INTERNET = 'IAB6-4',
  PARENTING_KSIX_KIDS = 'IAB6-5',
  PARENTING_TEENS = 'IAB6-6',
  PREGNANCY = 'IAB6-7',
  SPECIAL_NEEDS_KIDS = 'IAB6-8',
  ELDERCARE = 'IAB6-9',
}

export enum HEALTH_FITNESS {
  BASE = 'IAB7',
  EXERCISE = 'IAB7-1',
  ADD = 'IAB7-2',
  AIDS_HIV = 'IAB7-3',
  ALLERGIES = 'IAB7-4',
  ALTERNATIVE_MEDICINE = 'IAB7-5',
  ARTHRITIS = 'IAB7-6',
  ASTHMA = 'IAB7-7',
  AUTISM_PDD = 'IAB7-8',
  BIPOLAR_DISORDER = 'IAB7-9',
  BRAIN_TUMOR = 'IAB7-10',
  CANCER = 'IAB7-11',
  CHOLESTEROL = 'IAB7-12',
  CHRONIC_FATIGUE_SYNDROME = 'IAB7-13',
  CHRONIC_PAIN = 'IAB7-14',
  COLD_FLU = 'IAB7-15',
  DEAFNESS = 'IAB7-16',
  DENTAL_CARE = 'IAB7-17',
  DEPRESSION = 'IAB7-18',
  DERMATOLOGY = 'IAB7-19',
  DIABETES = 'IAB7-20',
  EPILEPSY = 'IAB7-21',
  GERD_ACID_REFLUX = 'IAB7-22',
  HEADACHES_MIGRAINES = 'IAB7-23',
  HEART_DISEASE = 'IAB7-24',
  HERBS_FOR_HEALTH = 'IAB7-25',
  HOLISTIC_HEALING = 'IAB7-26',
  IBS_CROHNS_DISEASE = 'IAB7-27',
  INCEST_ABUSE_SUPPORT = 'IAB7-28',
  INCONTINENCE = 'IAB7-29',
  INFERTILITY = 'IAB7-30',
  MENS_HEALTH = 'IAB7-31',
  NUTRITION = 'IAB7-32',
  ORTHOPEDICS = 'IAB7-33',
  PANIC_ANXIETY_DISORDERS = 'IAB7-34',
  PEDIATRICS = 'IAB7-35',
  PHYSICAL_THERAPY = 'IAB7-36',
  PSYCHOLOGY_PSYCHIATRY = 'IAB7-37',
  SENIOR_HEALTH = 'IAB7-38',
  SEXUALITY = 'IAB7-39',
  SLEEP_DISORDERS = 'IAB7-40',
  SMOKING_CESSATION = 'IAB7-41',
  SUBSTANCE_ABUSE = 'IAB7-42',
  THYROID_DISEASE = 'IAB7-43',
  WEIGHT_LOSS = 'IAB7-44',
  WOMENS_HEALTH = 'IAB7-45',
}

export enum FOOD_DRINK {
  BASE = 'IAB8',
  AMERICAN_CUISINE = 'IAB8-1',
  BARBECUES_GRILLING = 'IAB8-2',
  CAJUN_CREOLE = 'IAB8-3',
  CHINESE_CUISINE = 'IAB8-4',
  COCKTAILS_BEER = 'IAB8-5',
  COFFEE_TEA = 'IAB8-6',
  CUISINE_SPECIFIC = 'IAB8-7',
  DESSERTS_BAKING = 'IAB8-8',
  DINING_OUT = 'IAB8-9',
  FOOD_ALLERGIES = 'IAB8-10',
  FRENCH_CUISINE = 'IAB8-11',
  HEALTH_LOW_FAT_COOKING = 'IAB8-12',
  ITALIAN_CUISINE = 'IAB8-13',
  JAPANESE_CUISINE = 'IAB8-14',
  MEXICAN_CUISINE = 'IAB8-15',
  VEGAN = 'IAB8-16',
  VEGETARIAN = 'IAB8-17',
  WINE = 'IAB8-18',
}

export enum HOBBIES_INTERESTS {
  BASE = 'IAB9',
  ART_TECHNOLOGY = 'IAB9-1',
  ARTS_CRAFTS = 'IAB9-2',
  BEADWORK = 'IAB9-3',
  BIRD_WATCHING = 'IAB9-4',
  BOARD_GAMES_PUZZLES = 'IAB9-5',
  CANDLE_SOAP_MAKING = 'IAB9-6',
  CARD_GAMES = 'IAB9-7',
  CHESS = 'IAB9-8',
  CIGARS = 'IAB9-9',
  COLLECTING = 'IAB9-10',
  COMIC_BOOKS = 'IAB9-11',
  DRAWING_SKETCHING = 'IAB9-12',
  FREELANCE_WRITING = 'IAB9-13',
  GENEALOGY = 'IAB9-14',
  GETTING_PUBLISHED = 'IAB9-15',
  GUITAR = 'IAB9-16',
  HOME_RECORDING = 'IAB9-17',
  INVESTORS_PATENTS = 'IAB9-18',
  JEWELRY_MAKING = 'IAB9-19',
  MAGIC_ILLUSION = 'IAB9-20',
  NEEDLEWORK = 'IAB9-21',
  PAINTING = 'IAB9-22',
  PHOTOGRAPHY = 'IAB9-23',
  RADIO = 'IAB9-24',
  ROLEPLAYING_GAMES = 'IAB9-25',
  SCIFI_FANTASY = 'IAB9-26',
  SCRAPBOOKING = 'IAB9-27',
  SCREENWRITING = 'IAB9-28',
  STAMPS_COINS = 'IAB9-29',
  VIDEO_COMPUTER_GAMES = 'IAB9-30',
  WOODWORKING = 'IAB9-31',
}

export enum HOME_GARDEN {
  BASE = 'IAB10',
  APPLIANCES = 'IAB10-1',
  ENTERTAINING = 'IAB10-2',
  ENVIRONMENTAL_SAFETY = 'IAB10-3',
  GARDENING = 'IAB10-4',
  HOME_REPAIR = 'IAB10-5',
  HOME_THEATER = 'IAB10-6',
  INTERIOR_DECORATING = 'IAB10-7',
  LANDSCAPING = 'IAB10-8',
  REMODELING_CONSTRUCTION = 'IAB10-9',
}

export enum LAW_GOVERNMENT_POLITICS {
  BASE = 'IAB11',
  IMMIGRATION = 'IAB11-1',
  LEGAL_ISSUES = 'IAB11-2',
  US_GOVERNMENT_RESOURCES = 'IAB11-3',
  POLITICS = 'IAB11-4',
  COMMENTARY = 'IAB11-5',
}
export enum NEWS {
  BASE = 'IAB12',
  INTERNATIONAL_NEWS = 'IAB12-1',
  NATIONAL_NEWS = 'IAB12-2',
  LOCAL_NEWS = 'IAB12-3',
}

export enum PERSONAL_FINANCE {
  BASE = 'IAB13',
  BEGINNING_INVESTING = 'IAB13-1',
  CREDIT_DEBT_LOANS = 'IAB13-2',
  FINANCIAL_NEWS = 'IAB13-3',
  FINANCIAL_PLANNING = 'IAB13-4',
  HEDGE_FUND = 'IAB13-5',
  INSURANCE = 'IAB13-6',
  INVESTING = 'IAB13-7',
  MUTUAL_FUNDS = 'IAB13-8',
  OPTIONS = 'IAB13-9',
  RETIREMENT_PLANNING = 'IAB13-10',
  STOCKS = 'IAB13-11',
  TAX_PLANNING = 'IAB13-12',
}
export enum SOCIETY {
  BASE = 'IAB14',
  DATING = 'IAB14-1',
  DIVORCE_SUPPORT = 'IAB14-2',
  GAY_LIFE = 'IAB14-3',
  MARRIAGE = 'IAB14-4',
  SENIOR_LIVING = 'IAB14-5',
  TEENS = 'IAB14-6',
  WEDDINGS = 'IAB14-7',
  ETHNIC_SPECIFIC = 'IAB14-8',
}

export enum SCIENCE {
  BASE = 'IAB15',
  ASTROLOGY = 'IAB15-1',
  BIOLOGY = 'IAB15-2',
  CHEMISTRY = 'IAB15-3',
  GEOLOGY = 'IAB15-4',
  PARANORMAL_PHENOMENA = 'IAB15-5',
  PHYSICS = 'IAB15-6',
  SPACE_ASTRONOMY = 'IAB15-7',
  GEOGRAPHY = 'IAB15-8',
  BOTANY = 'IAB15-9',
  WEATHER = 'IAB15-10',
}

export enum PETS {
  BASE = 'IAB16',
  AQUARIUMS = 'IAB16-1',
  BIRDS = 'IAB16-2',
  CATS = 'IAB16-3',
  DOGS = 'IAB16-4',
  LARGE_ANIMALS = 'IAB16-5',
  REPTILES = 'IAB16-6',
  VETERINARY_MEDICINE = 'IAB16-7',
}

export enum SPORTS {
  BASE = 'IAB17',
  AUTO_RACING = 'IAB17-1',
  BASEBALL = 'IAB17-2',
  BICYCLING = 'IAB17-3',
  BODYBUILDING = 'IAB17-4',
  BOXING = 'IAB17-5',
  CANOEING_KAYAKING = 'IAB17-6',
  CHEERLEADING = 'IAB17-7',
  CLIMBING = 'IAB17-8',
  CRICKET = 'IAB17-9',
  FIGURE_SKATING = 'IAB17-10',
  FLY_FISHING = 'IAB17-11',
  FOOTBALL = 'IAB17-12',
  FRESHWATER_FISHING = 'IAB17-13',
  GAME_FISH = 'IAB17-14',
  GOLF = 'IAB17-15',
  HORSE_RACING = 'IAB17-16',
  HORSES = 'IAB17-17',
  HUNTING_SHOOTING = 'IAB17-18',
  INLINE_SKATING = 'IAB17-19',
  MARTIAL_ARTS = 'IAB17-20',
  MOUNTAIN_BIKING = 'IAB17-21',
  NASCAR_RACING = 'IAB17-22',
  OLYMPICS = 'IAB17-23',
  PAINTBALL = 'IAB17-24',
  POWER_MOTORCYCLES = 'IAB17-25',
  PRO_BASKETBALL = 'IAB17-26',
  PRO_ICE_HOCKEY = 'IAB17-27',
  RODEO = 'IAB17-28',
  RUGBY = 'IAB17-29',
  RUNNING_JOGGING = 'IAB17-30',
  SAILING = 'IAB17-31',
  SALTWATER_FISHING = 'IAB17-32',
  SCUBA_DIVING = 'IAB17-33',
  SKATEBOARDING = 'IAB17-34',
  SKIING = 'IAB17-35',
  SNOWBOARDING = 'IAB17-36',
  SURFING_BODY_BOARDING = 'IAB17-37',
  SWIMMING = 'IAB17-38',
  TABLE_TENNIS_PING_PONG = 'IAB17-39',
  TENNIS = 'IAB17-40',
  VOLLEYBALL = 'IAB17-41',
  WALKING = 'IAB17-42',
  WATERSKI_WAKEBOARD = 'IAB17-43',
  WORLD_SOCCER = 'IAB17-44',
}

export enum STYLE_FASHION {
  BASE = 'IAB18',
  BEAUTY = 'IAB18-1',
  BODY_ART = 'IAB18-2',
  FASHION = 'IAB18-3',
  JEWELRY = 'IAB18-4',
  CLOTHING = 'IAB18-5',
  ACCESSORIES = 'IAB18-6',
}

export enum TECHNOLOGY_COMPUTING {
  BASE = 'IAB19',
  THREE_D_GRAPHICS = 'IAB19-1',
  ANIMATION = 'IAB19-2',
  ANTIVIRUS_SOFTWARE = 'IAB19-3',
  C_CPLUSPLUS = 'IAB19-4',
  CAMERAS_CAMCORDERS = 'IAB19-5',
  CELL_PHONES = 'IAB19-6',
  COMPUTER_CERTIFICATION = 'IAB19-7',
  COMPUTER_NETWORKING = 'IAB19-8',
  COMPUTER_PERIPHERALS = 'IAB19-9',
  COMPUTER_REVIEWS = 'IAB19-10',
  DATA_CENTERS = 'IAB19-11',
  DATABASES = 'IAB19-12',
  DESKTOP_PUBLISHING = 'IAB19-13',
  DESKTOP_VIDEO = 'IAB19-14',
  EMAIL = 'IAB19-15',
  GRAPHICS_SOFTWARE = 'IAB19-16',
  HOME_VIDEO_DVD = 'IAB19-17',
  INTERNET_TECHNOLOGY = 'IAB19-18',
  JAVA = 'IAB19-19',
  JAVASCRIPT = 'IAB19-20',
  MAC_SUPPORT = 'IAB19-21',
  MP3_MIDI = 'IAB19-22',
  NET_CONFERENCING = 'IAB19-23',
  NET_FOR_BEGINNERS = 'IAB19-24',
  NETWORK_SECURITY = 'IAB19-25',
  PALMTOPS_PDAS = 'IAB19-26',
  PC_SUPPORT = 'IAB19-27',
  PORTABLE = 'IAB19-28',
  ENTERTAINMENT = 'IAB19-29',
  SHAREWARE_FREEWARE = 'IAB19-30',
  UNIX = 'IAB19-31',
  VISUAL_BASIC = 'IAB19-32',
  WEB_CLIP_ART = 'IAB19-33',
  WEB_DESIGN_HTML = 'IAB19-34',
  WEB_SEARCH = 'IAB19-35',
  WINDOWS = 'IAB19-36',
}

export enum TRAVEL {
  BASE = 'IAB20',
  ADVENTURE_TRAVEL = 'IAB20-1',
  AFRICA = 'IAB20-2',
  AIR_TRAVEL = 'IAB20-3',
  AUSTRALIA_NEW_ZEALAND = 'IAB20-4',
  BED_BREAKFASTS = 'IAB20-5',
  BUDGET_TRAVEL = 'IAB20-6',
  BUSINESS_TRAVEL = 'IAB20-7',
  BY_US_LOCALE = 'IAB20-8',
  CAMPING = 'IAB20-9',
  CANADA = 'IAB20-10',
  CARIBBEAN = 'IAB20-11',
  CRUISES = 'IAB20-12',
  EASTERN_EUROPE = 'IAB20-13',
  EUROPE = 'IAB20-14',
  FRANCE = 'IAB20-15',
  GREECE = 'IAB20-16',
  HONEYMOONS_GETAWAYS = 'IAB20-17',
  HOTELS = 'IAB20-18',
  ITALY = 'IAB20-19',
  JAPAN = 'IAB20-20',
  MEXICO_CENTRAL_AMERICA = 'IAB20-21',
  NATIONAL_PARKS = 'IAB20-22',
  SOUTH_AMERICA = 'IAB20-23',
  SPAS = 'IAB20-24',
  THEME_PARKS = 'IAB20-25',
  TRAVELING_WITH_KIDS = 'IAB20-26',
  UNITED_KINGDOM = 'IAB20-27',
}

export enum REAL_ESTATE {
  BASE = 'IAB21',
  APARTMENTS = 'IAB21-1',
  ARCHITECTS = 'IAB21-2',
  BUYING_SELLING_HOMES = 'IAB21-3',
}
export enum SHOPPING {
  BASE = 'IAB22',
  CONTESTS_FREEBIES = 'IAB22-1',
  COUPONING = 'IAB22-2',
  COMPARISON = 'IAB22-3',
  ENGINES = 'IAB22-4',
}

export enum RELIGION_SPIRITUALITY {
  BASE = 'IAB23',
  ALTERNATIVE_RELIGIONS = 'IAB23-1',
  ATHEISM_AGNOSTICISM = 'IAB23-2',
  BUDDHISM = 'IAB23-3',
  CATHOLICISM = 'IAB23-4',
  CHRISTIANITY = 'IAB23-5',
  HINDUISM = 'IAB23-6',
  ISLAM = 'IAB23-7',
  JUDAISM = 'IAB23-8',
  LATTER_DAY_SAINTS = 'IAB23-9',
  PAGAN_WICCAN = 'IAB23-10',
}
export enum UNCATEGORIZED {
  BASE = 'IAB24',
}

export enum NON_STANDARD_CONTENT {
  BASE = 'IAB25',
  UNMODERATED_UGC = 'IAB25-1',
  EXTREME_GRAPHIC_EXPLICIT_VIOLENCE = 'IAB25-2',
  PORNOGRAPHY = 'IAB25-3',
  PROFANE_CONTENT = 'IAB25-4',
  HATE_CONTENT = 'IAB25-5',
  UNDER_CONSTRUCTION = 'IAB25-6',
  INCENTIVIZED = 'IAB25-7',
}

export enum ILLEGAL_CONTENT {
  BASE = 'IAB26',
  ILLEGAL_CONTENT = 'IAB26-1',
  WAREZ = 'IAB26-2',
  SPYWARE_MALWARE = 'IAB26-3',
  COPYRIGHT_INFRINGEMENT = 'IAB26-4',
}

/**
 * The following list represents the IAB’s contextual taxonomy for categorization. Standard IDs have been
 * adopted to easily support the communication of primary and secondary categories for various objects.
 * This OpenRTB table has values derived from the IAB Tech Lab Content Taxonomy. Practitioners should
 * keep in sync with updates as published on www.iab.com.
 */
type ContentCategories =
  | ARTS_ENTERTAINMENT
  | AUTOMOTIVE
  | BUSINESS
  | CAREERS
  | EDUCATION
  | FAMILY_PARENTING
  | HEALTH_FITNESS
  | FOOD_DRINK
  | HOBBIES_INTERESTS
  | HOME_GARDEN
  | LAW_GOVERNMENT_POLITICS
  | NEWS
  | PERSONAL_FINANCE
  | SOCIETY
  | SCIENCE
  | PETS
  | SPORTS
  | STYLE_FASHION
  | TECHNOLOGY_COMPUTING
  | TRAVEL
  | REAL_ESTATE
  | SHOPPING
  | RELIGION_SPIRITUALITY
  | UNCATEGORIZED
  | NON_STANDARD_CONTENT
  | ILLEGAL_CONTENT;

export default ContentCategories;

export const ContentCategoriesObject = {
  ARTS_ENTERTAINMENT,
  AUTOMOTIVE,
  BUSINESS,
  CAREERS,
  EDUCATION,
  FAMILY_PARENTING,
  HEALTH_FITNESS,
  FOOD_DRINK,
  HOBBIES_INTERESTS,
  HOME_GARDEN,
  LAW_GOVERNMENT_POLITICS,
  NEWS,
  PERSONAL_FINANCE,
  SOCIETY,
  SCIENCE,
  PETS,
  SPORTS,
  STYLE_FASHION,
  TECHNOLOGY_COMPUTING,
  TRAVEL,
  REAL_ESTATE,
  SHOPPING,
  RELIGION_SPIRITUALITY,
  UNCATEGORIZED,
  NON_STANDARD_CONTENT,
  ILLEGAL_CONTENT,
};
