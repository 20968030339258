import { ReportingAdvertisingService, ReportingBiddingService, ReportingPublishingService } from '@/services';
import { FilterTool, CsvTool } from '@/tools';
import moment from 'moment/moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useReportingTable<Result, ResultLine>({service, getParams, paramsFilters, isFilters, initFCItemStates}: {
  service: ReportingAdvertisingService | ReportingBiddingService | ReportingPublishingService;
  getParams: (stringify: boolean, paramDates: [moment.Moment, moment.Moment], filters: Filters, params?: any) => any;
  isFilters: { filterKey: string; searchKey: string; }[];
  paramsFilters: string[];
  initFCItemStates: typeof FilterTool['initFCItemStates'];
}) {
  const [filters, _setFilters] = useState<Filters>({} as Filters);
  const [results, _setResults] = useState<Result | null>(null);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);
  const [dates, _setDates] = useState<[moment.Moment, moment.Moment]>([moment().subtract(1, 'day'), moment().subtract(1, 'day')]);
  const [loading, _setLoading] = useState<boolean>(false);

  const onSetFilters = useCallback((newFilters: Filters) => {
    _setFilters(newFilters);
  }, []);

  const onSearch = useCallback((data: any, download = false) => {
    _setLoading(true);
    service.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
        if (download) {
          CsvTool.downloadData(`report_${new Date().toISOString()}.csv`, res.results);
        }
      }
    });
  }, [service]);

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({
      ...filters,
      [key]: filters![key].map((item: FCItem) => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) || [],
    });
  }
  const uLocation = useLocation();
  const uHistory = useHistory();

  const onDatesChange = useCallback((e) => {
    if (e && e.length >= 2) {
      _setDates([e[0]!, e[1]!]);
    }
  }, [_setDates]);

  const onSearchReportingFilter = useCallback(() => {
    uHistory.push({ search: getParams(true, dates, filters) as string });
    onSearch(getParams(false, dates, filters));
  }, [dates, filters, getParams, onSearch, uHistory]);

  const onSearchDownloadData = useCallback(() => {
    uHistory.push({ search: getParams(true, dates, filters) as string });
    onSearch(getParams(false, dates, filters), true);
  }, [dates, filters, getParams, onSearch, uHistory]);

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    const params: { granularity: string | null } = { granularity: null };
    const { dateStart, dateEnd, isGranularity, granularity } = FilterTool.parseSearch(search);
    if (dateStart || dateEnd) {
      _setDates([moment(dateStart), moment(dateEnd)]);
    }
    if (isGranularity) {
      params.granularity = granularity;
    }
    service.filters(getParams(false, dates, filters, params)).then((data: Filters) => {
      _setLoading(false);
      if (data) {
        initFCItemStates({ paramsFilters, isFilters, data, search });
        onSetFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    filters,
    results,
    tableData,
    dates,
    loading,
    onSearch,
    onSetFilter,
    _setTableData,
    _setDates,
    _setLoading,
    onSetFilters,
    onSearchReportingFilter,
    onSearchDownloadData,
    onDatesChange,
  };
}
