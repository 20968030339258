export enum PlatformActionEntity {
  APPLICATION = 'APPLICATION',
  PLACEMENT = 'PLACEMENT',
  CAMPAIGN = 'CAMPAIGN',
  CREATIVE = 'CREATIVE',
  REPORT_ADVERTISING_API = 'REPORT_ADVERTISING_API',
  REPORT_PUBLISHNG_API = 'REPORT_PUBLISHNG_API',
  REPORT_BIDDING_API = 'REPORT_BIDDING_API',
  USER = 'USER',
};

export enum PlatformActionType {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
};

export class MPlatformAction {
  id: number = 0;
  entity?: PlatformActionEntity;
  type?: PlatformActionType;
  userId: number = 0;
  metadata: any = null;
  createdAt?: Date;
  User?: {
    email: string;
    accountId: number;
    Account: {
      name: string;
    }
  }
};
