import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { homeService } from '@/services';
import { ColumnLineGraph } from '@/components';

function formatRevenues(s?: number | null) {
  return `${s?.toFixed(2)} $`;
}

interface AppPublisherPageProps {
  bundleId: string;
  platform: string;
}

export function AppPublisherPage(props: AppPublisherPageProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    {
      date: string;
      impressions: number;
      revenues: number;
    }[]
  >([]);

  function updateAdEventsByMinute() {
    setLoading(true);

    homeService
      .getImpressionRevenuePublisher(props.bundleId, props.platform)
      .then((data) => {
        setData(data?.last7days || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAdEventsByMinute, []);

  return (
    <Spin spinning={loading}>
      <div className="network-card">
        <ColumnLineGraph data={data} xKey="date" barKey="impressions" lineKey="revenues" title="Publisher" formatLineKey={formatRevenues} />
      </div>
    </Spin>
  );
}
