export class UtilTools {

  static deepCopy<T>(object: T) {
    return JSON.parse(JSON.stringify(object)) as T;
  }

  static formatPercent(amount: number, precision = 2) {
    return `${(amount * 100).toFixed(precision)}%`;
  }
  
  static computeAndFormatPercent(total: number, sample: number, decimals = 1) {
    return `${(sample * 100 / (total)).toFixed(decimals)}%`;
  }
}