export enum AdTypes {
  // deprecated types
  INTERSTITIAL = 'interstitial',
  REWARDED = 'rewarded',
  ICON = 'icon',
  // current types
  VIDEO = 'video',
  MATCH = 'match',
  RATHER = 'rather',
  PAD_G = 'pad-g',
  ICONWALL = 'iconwall',
  ICONWALL_Q = 'iconwall-q',
  TIMELINE_Q = 'timeline-q',
  VIDEO_ICONWALL = 'video-iconwall',
  VIDEO_ICONWALL_Q = 'video-iconwall-q',
  VIDEO_TIMELINE_Q = 'video-timeline-q',
  ENDCARD_ICONWALL = 'endcard-iconwall',
  ENDCARD_ICONWALL_Q = 'endcard-iconwall-q',
  ENDCARD_TIMELINE_Q = 'endcard-timeline-q',
  ICONWALL_ANIME = 'iconwall-anime',
  ICONWALL_ANIME_Q = 'iconwall-anime-q',
  TIMELINE_ANIME_Q = 'timeline-anime-q',
  VIDEO_ICONWALL_ANIME = 'video-iconwall-anime',
  VIDEO_ICONWALL_ANIME_Q = 'video-iconwall-anime-q',
  VIDEO_TIMELINE_ANIME_Q = 'video-timeline-anime-q',
  ENDCARD_ICONWALL_ANIME = 'endcard-iconwall-anime',
  ENDCARD_ICONWALL_ANIME_Q = 'endcard-iconwall-anime-q',
  ENDCARD_TIMELINE_ANIME_Q = 'endcard-timeline-anime-q',
  CAROUSEL_ICONWALL_Q = 'carousel-iconwall-q',
  BANNER = 'banner',
  ICONROW = 'iconrow',
  UNKNOWN = 'unknown'
}

export default AdTypes;
