import { DualAxes } from '@ant-design/charts';
import { useMemo } from 'react';
import { ColumnLineGraphTooltip } from './column-line-graph-tooltip';
import { capitalize } from './utils';
import { Empty } from 'antd';

import css from './column-line-graph.module.css';

export interface ColumnLineGraphProps<X extends string, Y1 extends string, Y2 extends string, T = { [key in X]: string } & { [key in Y1 | Y2]: number }> {
  data: T[];
  title: string;
  xKey: X;
  barKey: Y1;
  lineKey: Y2;
  formatXKey?: (v?: string | null) => string | null;
  formatBarKey?: (v?: number | null) => string | null;
  formatLineKey?: (v?: number | null) => string | null;
}

export function ColumnLineGraph<X extends string, Y1 extends string, Y2 extends string>({
  data,
  title,
  xKey,
  barKey,
  lineKey,
  formatXKey,
  formatBarKey,
  formatLineKey,
}: ColumnLineGraphProps<X, Y1, Y2>) {
  const geometry = useMemo(() => [{ geometry: 'column' }, { geometry: 'line', point: { size: 3, shape: 'circle' }, color: '#008800' }], []);
  const meta = useMemo(
    () => ({
      [barKey]: {
        alias: capitalize(barKey),
      },
      [lineKey]: {
        alias: capitalize(lineKey),
      },
    }),
    [barKey, lineKey],
  );

  if (data.length === 0) {
    return (
      <div>
        <h5>{title}</h5>
        <Empty className={css.emptyGraph} />
      </div>
    );
  }

  return (
    <div>
      <h5>{title}</h5>
      <DualAxes
        data={[data, data]}
        height={400}
        xField={xKey}
        yField={[barKey, lineKey]}
        geometryOptions={geometry}
        meta={meta}
        tooltip={{
          customContent: (t, d) => {
            return (
              <ColumnLineGraphTooltip
                t={t}
                d={d}
                barKey={barKey}
                lineKey={lineKey}
                formatXKey={formatXKey}
                formatBarKey={formatBarKey}
                formatLineKey={formatLineKey}
              />
            );
          },
        }}
      />
    </div>
  );
}
