import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { homeService } from '@/services';
import { ColumnLineGraph } from '@/components';
import Table, { ColumnType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

function formatSpend(s?: number | null) {
  return `${s?.toFixed(2)} $`;
}

const columns: ColumnType<{
  campaign: string;
  installs: number;
  spend: number;
}>[] = [
    { key: 'campaign', title: 'Campaign', render: (_, line) => <Link to={`advertising/campaigns/campaign/${line.campaign}`}>{line.campaign}</Link> },
    { key: 'installs', title: 'Installs', dataIndex: 'installs' },
    { key: 'spend', title: 'Spends', render: (_, line) => formatSpend(line.spend) },
  ];

export function HomeAdvertiserPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    date: string;
    installs: number;
    spend: number;
  }[]>([]);
  const [topCampaigns, setTopCampaigns] = useState<{
    campaign: string;
    installs: number;
    spend: number;
  }[]>([]);

  function updateAdEventsByMinute() {
    setLoading(true);

    homeService.getInstallsSpendAdvertiser().then((data) => {
      if (data) {
        const { last7days, topCampaigns } = data;
        setData(last7days);
        setTopCampaigns(topCampaigns);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setData([]);
    });
  }

  useEffect(updateAdEventsByMinute, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '8px' }}>
        <ColumnLineGraph data={data} xKey='date' barKey='installs' lineKey='spend' title='Advertiser' formatLineKey={formatSpend} />
      </div>
      <div style={{ marginTop: '16px ' }}>
        <div><h5>Top 5 campaigns</h5></div>
        <Table columns={columns} dataSource={topCampaigns} pagination={false} />
      </div>
    </Spin>
  )
}