import './asset-video.scss';
import React from 'react';

interface YcAssetVideoProps {
  url: string;
  controls?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLVideoElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLVideoElement>;
}

export function YcAssetVideo(props: YcAssetVideoProps) {
  return (
    <video
      className='video'
      src={props.url}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      controls={props.controls}
    />);
}
