import { YcAssetVideo } from '@/components';
import { useState } from 'react';
import { message } from 'antd';
import { MCampaign, MCampaignAsset } from '@/modeles';
import { YcCampaignAssets } from '../a-assets';

// styles
import './campaign-videos.scss';
import { YcCampaignAssetRequirements } from '../a-requirements';

export function CampaignVideos(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {

  const [hover, setHover] = useState({});
  const toggleHover = (asset: MCampaignAsset, state = false) => setHover({ [asset?.id as unknown as string]: state });

  async function beforeUpload(file: File) {
    return new Promise<boolean>((resolve, reject) => {
      if (file.type !== "video/mp4") {
        message.error("Video file type need to be video/mp4");
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  return (
    <div className="c-campaign-videos">
      <YcCampaignAssets
        dataKey='Videos'
        requirements={<YcCampaignAssetRequirements
          fileType='MP4'
          size={{ max: 30, recommended: 5 }}
          duration={{ min: 15, max: 30 }}
          resolution={[{ name: '720x1280', isRecommended: true }, { name: '1280x720' }]}
        />}
        campaign={props.campaign}
        _setCampaign={props._setCampaign}
        beforeUpload={beforeUpload}
        path="video"
        render={(asset: MCampaignAsset) => (<div className='c-video'>
          {asset.Document && <YcAssetVideo
            url={asset.Document.url}
            onMouseEnter={() => toggleHover(asset, true)}
            onMouseLeave={() => toggleHover(asset, false)}
            controls={!!hover?.[asset?.id as unknown as string]}
          />}
        </div>)}
      ></YcCampaignAssets>
    </div >
  );
}
