import { useEffect, useState } from "react";
import { UnorderedListOutlined, PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import { docsService } from "../../../../services/docs.service";
import { YcQuery, YcQueryData } from "../../components";

import './docs-manage-apps.page.scss';

export function DocsManageAppsPage() {

  const PATH = "/api/apps";

  const [tab, _setTab] = useState("listing");
  const [headers, _setHeaders] = useState<{} | null>(null);
  const [docs, _setDocs] = useState<{ [key: string]: YcQueryData } | null>(null);

  useEffect(() => {
    async function init() {
      _setDocs((await docsService.view(PATH)).routes);
      const managerKey = await profileService.viewManagerOrReportKey("managerKey");
      if (managerKey) {
        _setHeaders({
          authorization: managerKey,
        });
      } else {
        _setHeaders({ authorization: "NO managerKey active FOUND IN PROFILE" });
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='docs-manage-apps'>
      <YcTitle label="Docs > Manage Applications"></YcTitle>
      <p>
        In this page you can manage applications
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "listing", label: "Listing", icon: <UnorderedListOutlined /> },
        { key: "view", label: "View", icon: <EyeOutlined /> },
        { key: "add", label: "Add", icon: <PlusOutlined /> },
        { key: "edit", label: "Edit", icon: <EditOutlined /> },
      ]} />
      <div className="doc-container">
        {!!headers &&
          <>
            {tab === "listing" &&
              <YcQuery data={docs![PATH + '/listing']} headers={headers} />
            }
            {tab === "view" &&
              <YcQuery data={docs![PATH + '/view/:key']} headers={headers} />
            }
            {tab === "add" &&
              <YcQuery data={docs![PATH + '/add']} headers={headers} />
            }
            {tab === "edit" &&
              <YcQuery data={docs![PATH + '/edit/:key']} headers={headers} />
            }
          </>
        }
      </div>
    </div>
  );
}