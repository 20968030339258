import { BaseService } from "../base.service";
import { MBonus } from '../../modeles';


class BonusesService_SA extends BaseService {

  PATH = "superadmin/bonuses/";

  async listing(): Promise<MBonus[]> {
    return await super._listing(this.PATH + "list");
  }

  async view(id: number): Promise<MBonus | null> {
    return await super._view(this.PATH + "view/" + id);
  }

  async edit(id: number, data: any): Promise<MBonus | null> {
    return await super._edit(this.PATH + "edit/" + id, data);
  }

  async create(data: any): Promise<MBonus | null> {
    return await super._add(this.PATH + "create", data);
  }

  async createMultiple(data: any[]): Promise<MBonus[] | null> {
    return await super._add(this.PATH + "create-multiple", data);
  }

  async upsertAll(data: MBonus[]) {
    return await super._edit(this.PATH + "upsert-all", data);
  }

}

export const bonusesService_SA = new BonusesService_SA();