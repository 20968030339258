import App from './App';

import Device from './Device';
import ContentCategories from '../enum/ContentCategories';
import Imp from './Imp';
import Site from './Site';
import Regs from './Regs';
import Source from './Source';
import User from './User';
import At from '../enum/At';

export enum TestMode {
  LIVE = 0,
  TEST = 1,
}

export enum AllImps {
  NO = 0,
  UNKNOWN = 0,
  YES = 1,
}

/**
 * BidRequest:
 * The top-level bid request object contains a globally unique bid request or auction ID. This id attribute is
 * required as is at least one impression object. Other attributes in this top-level object
 * establish rules and restrictions that apply to all impressions being offered.
 * There are also several subordinate objects that provide detailed data to potential buyers. Among these
 * are the Site and App objects, which describe the type of published media in which the impression(s)
 * appear. These objects are highly recommended, but only one applies to a given bid request depending
 * on whether the media is browser-based web content or a non-browser application, respectively.
 */

export interface BidRequest {

  /** id: Unique ID of the bid request, provided by the exchange. */
  id: string;

  /** imp: Array of Imp objects representing the impressions offered. At least 1 Imp object is required. */
  imp: Array<Imp>;

  /** site: Details via a Site object about the publisher’s website. Only applicable and recommended for websites. */
  site?: Site;

  /** app: Details via an App object about the publisher’s app (i.e., non-browser applications). Only applicable and recommended for apps. */
  app?: App;

  /** device: Details via a Device object about the user’s device to which the impression will be delivered. */
  device?: Device;

  /** user: Details via a User object about the human user of the device; the advertising audience. */
  user?: User;

  /** test: Indicator of test mode in which auctions are not billable, where 0 = live mode, 1 = test mode. */
  test?: TestMode;

  /** at: Auction type, where 1 = First Price, 2 = Second Price Plus. Exchange-specific auction types can be defined using values greater than 500. */
  at?: At | number;

  /**
   * tmax: Maximum time in milliseconds the exchange allows for bids to be received
   * including Internet latency to avoid timeout.
   * This value supersedes any a priori guidance from the exchange.
   */
  tmax?: number;

  /**
   * wseat: White list of buyer seats (e.g., advertisers, agencies) allowed to bid on this impression.
   * IDs of seats and knowledge of the buyer’s customers to which they refer
   * must be coordinated between bidders and the exchange a priori.
   * At most, only one of wseat and bseat should be used in the same request.
   * Omission of both implies no seat restrictions.
   */
  wseat?: Array<string>;

  /**
   * bseat: Block list of buyer seats (e.g., advertisers, agencies) restricted from bidding on this impression.
   * IDs of seats and knowledge of the buyer’s customers to which they refer must be coordinated between bidders
   * and the exchange a priori. At most, only one of wseat and bseat should be used in the same request.
   * Omission of both implies no seat restrictions.
   */
  bseat?: Array<string>;

  /**
   * allimps: Flag to indicate if Exchange can verify that the impressions offered represent
   * all of the impressions available in context (e.g., all on the web page, all video spots such as pre/mid/post roll)
   * to support road-blocking. 0 = no or unknown, 1 = yes,
   * the impressions offered represent all that are available.
   */
  allimps?: AllImps;

  /** cur: Array of allowed currencies for bids on this bid request using ISO-4217 alpha codes. Recommended only if the exchange accepts multiple currencies. */
  cur?: Array<string>;

  /**
   * wlang: White list of languages for creatives using ISO-639-1-alpha-2.
   * Omission implies no specific restrictions,
   * but buyers would be advised to consider language attribute in the Device and/or Content objects if available.
   */
  wlang?: Array<string>;

  /** bcat: Blocked advertiser categories using the IAB content categories. Refer to List 5.1. */
  bcat?: Array<ContentCategories>;

  /**
   * badv: Block list of advertisers by their domains
   *
   * @example "ford.com".
   * */
  badv?: Array<string>;

  /**
   * bapp: Block list of applications by their platform-specific exchange-independent application identifiers.
   * On Android, these should be bundle or package names.
   *
   * @example "com.foo.mygame". On iOS, these are numeric IDs.
   */
  bapp?: Array<string>;

  /** source: A Sorce object that provides data about the inventory source and which entity makes the final decision. */
  source?: Source;

  /** regs: A Regs object that specifies any industry, legal, or governmental regulations in force for this request. */
  regs?: Regs;

  /** ext: Placeholder for exchange-specific extensions to OpenRTB. */
  ext?: any;
}
