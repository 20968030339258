/**
 * The following table specifies the position of the ad as a relative measure of visibility or prominence. This
 * OpenRTB table has values derived from the Inventory Quality Guidelines (IQG). Practitioners should
 * keep in sync with updates to the IQG values as published on IAB.com. Values "4" - "7" apply to apps per
 * the mobile addendum to IQG version 2.1
 */
enum AdPosition {
  UNKNOWN = 0,
  ABOVE_THE_FOLD = 1,

  /** @deprecated - May or may not be initially visible depending on screen size/resolution.  */
  DEPRECATED = 2,

  BELOW_THE_FOLD = 3,
  HEADER = 4,
  FOOTER = 5,
  SIDEBAR = 6,
  FULLSCREEN = 7,
}
export default AdPosition;
