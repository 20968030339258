import { useEffect, useState } from 'react';
import { Button } from 'antd';

import { YcAssetPlayable, YcAssetVideo, YcCard, YcLoading, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { adminAssetsService } from '@/services';
import { MCampaignAsset } from '@/modeles';

import './assets-validation.page.scss';

function Block(props: {
  title: string,
  type: string,
}) {
  const [asset, _setAsset] = useState<MCampaignAsset | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);

  useEffect(() => {
    _setLoading(true);
    adminAssetsService.lastToValidate(props.type).then((data) => {
      _setLoading(false);
      if (data) {
        _setAsset(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <YcCard title={props.title} open={true} disableOpen={true}>
    <YcLoading loading={loading}>
      <div className="block-content">
        {asset &&
          <>
            {(props.type === "VIDEO" && asset.Document) && <YcAssetVideo controls url={asset.Document?.url} />}
            {(props.type === "PLAYABLE" && asset.Document) && <YcAssetPlayable url={asset.Document.url} />}
            {props.type === "ENDCARD" && <img src={asset.Document?.url} className="e-endcard" alt="endcard" />}
            {props.type === "BANNER" && <img src={asset.Document?.url} className="e-banner" alt="banner" />}
            {props.type === "IMAGE" && <img src={asset.Document?.url} className="e-image" alt="imag" />}
            <div className='mt-3'>
              <Button type='primary' onClick={async () => {
                _setLoading(true);
                _setAsset(await adminAssetsService.validateAndLastToValidate(asset.id + "", props.type));
                _setLoading(false);
              }}>Validate</Button>
              &nbsp;&nbsp;&nbsp;
              <Button type='default' onClick={async () => {
                _setLoading(true);
                _setAsset(await adminAssetsService.devalidateAndLastToValidate(asset.id + "", props.type));
                _setLoading(false);
              }}>Devalidate</Button>
            </div>
          </>
        }
        {!asset && "No more to check"}
      </div>
    </YcLoading>
  </YcCard>;
}

export function SAAssetsValidationPage() {
  usePageTitle('Assets Validation');

  return (
    <div id='admin-asset'>
      <YcTitle label="Assets Validation" ></YcTitle>
      <div className='row'>
        <div className='col-lg-3 mb-4'><Block title="Video" type="VIDEO" /></div>
        <div className='col-lg-3 mb-4'><Block title="Playable" type="PLAYABLE" /></div>
        <div className='col-lg-3 mb-4'><Block title="Endcard" type="ENDCARD" /></div>
        <div className='col-lg-3 mb-4'><Block title="Banner" type="BANNER" /></div>
        <div className='col-lg-3 mb-4'><Block title="Image" type="IMAGE" /></div>
      </div>
    </div>
  );
}
