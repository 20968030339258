/**
 * The following table lists the options to indicate how the geographic information was determined.
 */
enum LocationType {
  GPS_LOCATION_SERVICES = 1,
  IP_ADDRESS = 2,
  USER_PROVIDED = 3,
}

export default LocationType;
