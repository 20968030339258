import { useEffect, useState } from 'react';
import { Button, DatePicker, InputNumber, Select, Spin } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { MAccount, MApp, MBonus } from '@/modeles';
import { bonusesService_SA } from '@/services';
import { YcTable, YcTitle } from '@/components';

import './bonuses.page.scss';
import { accountsService_SA, appsService } from '@/services';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { usePageTitle } from '@/hooks/use-title';

function smartSearch(search: string, test: string) {
  return search.split(' ').every((searchElem) => test.toLowerCase().includes(searchElem.toLowerCase()));
}

export function SABonusPage() {
  usePageTitle('Bonus');

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<MAccount[]>([]);
  const [applications, setApplications] = useState<MApp[]>([]);
  const [bonuses, setBonuses] = useState<MBonus[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      bonusesService_SA.listing(),
      accountsService_SA.listing(),
      appsService.listingBidder(),
    ]).then(([b, accs, apps]) => {
      setAccounts(accs);
      setApplications(apps);
      setBonuses(b);
      setLoading(false);
    });
  }, []);

  const handleAdd = () => {
    const newData = new MBonus();
    setBonuses([...bonuses, newData]);
  };

  const handleSave = () => {
    setLoading(true);
    bonusesService_SA.upsertAll(bonuses).then(() => {
      setLoading(false);
    });
  };

  const columns: ColumnType<MBonus>[] = [
    {
      key: 'type', title: 'Type', render: (_, b) => (
        <Select
          defaultValue={b.type}
          style={{ width: 120 }}
          onChange={(v) => { b.type = v; }}
          options={[{ value: 'ua', label: 'UA' }, { value: 'monet', label: 'Monet' }]}
        />
      )
    },
    {
      key: 'account', title: 'Account', render: (_, b) => (
        <Select
          defaultValue={b.accountId}
          allowClear
          style={{ width: 160 }}
          onChange={(v) => { b.accountId = v; }}
          options={accounts.map(a => ({ value: a.id, label: a.name }))}
          showSearch
          filterOption={(input, option) => smartSearch(input, option?.label || '')}
        />
      )
    },
    {
      key: 'app', title: 'Application', render: (_, b) => (
        <Select
          defaultValue={b.applicationId}
          allowClear
          style={{ width: 240 }}
          onChange={(v) => { b.applicationId = v; }}
          options={applications.map(a => ({ value: a.id, label: `${a.name} - ${a.platform}` }))}
          showSearch
          filterOption={(input, option) => smartSearch(input, option?.label || '')}
        />
      )
    },
    { key: 'value', title: 'Value', render: (_, b) => <InputNumber min={-50} max={50} defaultValue={b.value} onChange={(v) => {
        if (v) {
          b.value = v;
        }
      }} /> },
    {
      key: 'expireAt', title: 'Expire At', render: (_, b) => (
        <DatePicker
          defaultValue={b.expireAt ? moment.utc(b.expireAt) : undefined}
          onChange={(v) => b.expireAt = v ? moment.utc(v).startOf('day').toDate() : undefined}
          allowClear
        />
      )
    },
    { key: 'actions', title: 'Actions', render: (_, b) => <Button type="link" danger onClick={() => setBonuses(bonuses.filter(b2 => b2 !== b))}><DeleteOutlined /></Button> },
  ];

  return (
    <Spin spinning={loading}>
      <YcTitle label='Bonuses' />
      <div className='d-flex justify-content-end'>
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Add a bonus
        </Button>
        <Button onClick={handleSave} type="primary" style={{ marginBottom: 16, marginLeft: 16 }}>
          Save changes
        </Button>
      </div>
      <YcTable
        bordered
        size="small"
        dataSource={bonuses}
        columns={columns}
        ycTableKey="bonuses"
        rowKey="id"
      />
    </Spin>
  )
}
