import { BaseService } from "../base.service";
import { MAccount } from '../../modeles';


class AccountsService_SA extends BaseService {

  PATH = "superadmin/accounts/";

  async listing(): Promise<MAccount[]> {
    return await super._listing(this.PATH + "listing");
  }

  async view(id: number): Promise<MAccount | null> {
    return await super._view(this.PATH + "view/" + id);
  }
  async edit(id: number, data: any): Promise<MAccount | null> {
    return await super._edit(this.PATH + "edit/" + id, data);
  }

  async userActivate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "user-activate/" + id, {});
  }

  async userDeactivate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "user-deactivate/" + id, {});
  }

  async accountActivate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "account-activate/" + id, {});
  }

  async accountDeactivate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "account-deactivate/" + id, {});
  }

  async accountValidate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "account-validate/" + id, {});
  }

  async accountDevalidate(id: number): Promise<MAccount[] | null> {
    return await super.get(this.PATH + "account-devalidate/" + id, {});
  }

}

export const accountsService_SA = new AccountsService_SA();