/**
 * The following table lists the various options for the video or audio start delay. If the start delay value is
 * greater than 0, then the position is mid-roll and the value indicates the start delay.
 */

enum StartDelay {
  GENERIC_POST_ROLL = -2,
  GENERIC_MID_ROLL = -1,
  PRE_ROLL = 0,
}
export default StartDelay;
