import { Form, Input, Button, Spin } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuths } from '@/contexts';

import './sign-up.page.scss';
import { usePageTitle } from '@/hooks';
import { YcTitle } from '@/components';

export function SignUpPage() {
  usePageTitle('Sign Up');

  const [loading, _setLoading] = useState(false);

  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    _setLoading(true);
    await uAuths.signup(data);
    _setLoading(false);
  };

  return (
    <div className="root-sign-up">
      <Spin spinning={loading}>
        <div className="login-contain">
          <div className="m-auto">
            <div className="login-titleblock">
              <YcTitle label="Create account"></YcTitle>
              <br />
              <div className="block-auth">
                <Form onFinish={onFinish} layout="vertical">
                  <Form.Item label="Company Name" name="name" rules={[{ required: true }]}>
                    <Input type="text" />
                  </Form.Item>
                  <Form.Item label="Email" name={['user', 'email']} rules={[{ required: true }]}>
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item label="Password" name={['user', 'password']} rules={[{ required: true }]}>
                    <Input type="password" />
                  </Form.Item>

                  <div className="row justify-content-between">
                    <div className="col-lg-3 col-12">
                      <div className="mt-4">
                        <Button className="btn-primary-network w-100" type="primary" htmlType="submit">
                          Confirm
                        </Button>
                      </div>
                    </div>

                    {
                      <div className="col-lg-9 col-12 mt-4">
                        <div className="account-content">
                          {' '}
                          Already have an account ?{' '}
                          <Link className="account-btn" to="/login">
                            Login{' '}
                          </Link>{' '}
                        </div>
                      </div>
                    }
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="bkg-footer" />
        </footer>
      </Spin>
    </div>
  );
}
