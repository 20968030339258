/**
 * The following table lists the type of device from which the impression originated.
 * OpenRTB version 2.2 of the specification added distinct values for Mobile and Tablet. It is
 * recommended that any bidder adding support for 2.2 treat a value of 1 as an acceptable alias of 4 & 5.
 * This OpenRTB table has values derived from the Inventory Quality Guidelines (IQG). Practitioners should
 * keep in sync with updates to the IQG values.
 */

enum DeviceType {
  MOBILE_TABLET = 1,
  PERSONAL_COMPUTER = 2,
  CONNECTED_TV = 3,
  PHONE = 4,
  TABLET = 5,
  CONNECTED_DEVICE = 6,
  SET_TOP_BOX = 7,
}

export default DeviceType;
