import { BaseService } from "./base.service";

class DocsService extends BaseService {

  PATH = "docs/";

  async view(path: string): Promise<any | null> {
    return await this._view(this.PATH + "view/", {
      key: path
    });
  }

}

export const docsService = new DocsService();