export enum AdNetworks {
  YSOCORP = 'ysocorp',
  YSONETWORK = 'ysonetwork',
  APPLOVIN = 'applovin',
  OUTFIT_7 = 'outfit7',
  CAS_AI = 'cas_ai',
  GOOGLE = 'google',
  UNKNOWN = 'unknown',
}

export default AdNetworks;
