import React from 'react';

import { YcAssetPlayable } from '@/components';
import { MCampaign, MCampaignAsset } from '@/modeles';

import { YcCampaignAssets } from '../a-assets';
import { YcCampaignAssetRequirements } from '../a-requirements';

// styles
import './campaign-playables.scss';

export function CampaignPlayables(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
}) {
  async function beforeUpload(file: File) {
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  return (
    <div className="c-campaign-playables">
      <YcCampaignAssets
        dataKey='Playables'
        requirements={<YcCampaignAssetRequirements fileType='HTML' size={{max: 5}} />}
        campaign={props.campaign}
        _setCampaign={props._setCampaign}
        beforeUpload={beforeUpload}
        path="playable"
        render={(asset: MCampaignAsset) => (asset.Document ? <YcAssetPlayable url={asset.Document.url} /> : <></>)}
      ></YcCampaignAssets>
    </div >
  );
}
