import { ClockCircleOutlined, ExpandAltOutlined, FileOutlined, SaveOutlined } from '@ant-design/icons';

import './campaign-asset-requirements.scss';

const FileType = ({ fileType }: { fileType: string; }) => (
  <>
    <td className='left-requirement-title'><FileOutlined /> File type</td>
    <td className='requirement-value'>{fileType}</td>
  </>
);

const Size = ({ max, recommended }: { max: number; recommended?: number; }) => {
  const recommendedString = `${recommended}MB or less (recommended), `;
  const maxString = `${max}MB max`;

  return (
    <>
      <td className='right-requirement-title'><SaveOutlined /> Size</td>
      <td className='requirement-value'>{recommended ? recommendedString + maxString : maxString}</td>
    </>
  );
};

const Resolution = ({ resolutions }: { resolutions: { name: string; isRecommended?: boolean; }[]; }) => {
  const resolutionString = resolutions.map(r => `${r.name}${r.isRecommended ? ' (recommended)' : ''}`).join(', ');

  return (
    <>
      <td className='left-requirement-title'><ExpandAltOutlined /> Resolution</td>
      <td className='requirement-value'>{resolutionString}</td>
    </>
  );
};

const Duration = ({ min, max }: { min: number; max: number; }) => (
  <>
    <td className='right-requirement-title'><ClockCircleOutlined /> Duration</td>
    <td className='requirement-value'>{min} to {max} seconds</td>
  </>
);

export function YcCampaignAssetRequirements(props: {
  fileType: string;
  size: { recommended?: number; max: number; };
  resolution?: { name: string; isRecommended?: boolean; }[];
  duration?: { min: number; max: number; };
}) {
  return (
    <table className='c-campaign-asset-requirements'>
      <tr>
        <FileType fileType={props.fileType} />
        <Size {...props.size} />
      </tr>
      <tr>
        {props.resolution && <Resolution resolutions={props.resolution} />}
        {props.duration && <Duration {...props.duration} />}
      </tr>
    </table>
  );
}