import { BaseService } from "../base.service";

class AdsLearningService_SA extends BaseService {

  async listing(): Promise<any> {
    return await super._listingPost("superadmin/ads-learning/listing");
  }

}

export const adsLearningService_SA = new AdsLearningService_SA();