import { MDocument } from ".";

export class MApp {
  id?: number;
  key: string = "";
  name: string = "";
  platform: string = "";
  storeUrl: string = "";
  bundleId: string = "";
  appleId: string = "";
  isCallbackCreated?: boolean = false;
  unifiedApplicationId?: number;
  Icon?: MDocument;
  nbCampaigns?: number;
  nbCampaignsActive?: number;
  nbPlacements?: number;
  nbPlacementsActive?: number;
}
