/**
 * The following table lists the types of volume normalization modes, typically for audio.
 */
enum VolumeNormalizationModes {
  NONE = 0,
  AD_VOLUME_AVERAGE = 1,
  AD_VOLUME_PEAK = 2,
  AD_LOUDNESS = 3,
  CUSTOM_VOLUME_NORMALIZATION = 4,
}

export default VolumeNormalizationModes;
