import { useMemo, useState } from 'react';
import { Spin } from 'antd';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle, AddFilter, HavingFilter } from "@/components";
import { NumberTools } from "@/tools";
import { reportingBillingService_SA } from "@/services";
import { usePageTitle } from '@/hooks/use-title';

import { ReportingFilterSuperAdmin, FiltersSuperAdmin } from '../../../components';

import './reporting-billing.page.scss';


interface Total {
  revenue: number;
  spend: number;
}

interface ResultLine {
  id: number;
  revenue: number;
  spend: number;

  date?: string;
  account?: string;
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function SAReportingBillingPage() {
  usePageTitle('Reporting - Billing');

  const [filters, _setFilters] = useState<FiltersSuperAdmin | null>(null);
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tableData, _setTableData] = useState<ResultLine[]>([]);

  const onSetFilters = (filters) => {
    _setFilters(filters);
  }

  const onSearch = async (data: any) => {
    _setLoading(true);
    reportingBillingService_SA.search(data).then((res) => {
      _setLoading(false);
      if (res) {
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        });
        _setTableData(res.results);
      }
    });
  }

  const onSetFilter = ({ key, value, checked }) => {
    onSetFilters({ ...filters, [key]: filters![key].map(item => { if (item.value === value || item.label === value) { item.checked = checked; } return item; }) });
  }

  const columDate: YcColumnType<ResultLine> = {
    title: 'Date', key: 'date', dataIndex: 'date',
    ycSort: 'string',
    ycCanNotHide: true,
  };
  const columAccount: YcColumnType<ResultLine> = {
    title: 'Account', key: 'account', dataIndex: 'account',
    ycSort: 'string',
    ycCanNotHide: true,
    render: (text, record) => (<div>
      {record.account}
      <AddFilter key="accounts" item="accounts" value={record.account} filters={filters} onSetFilter={onSetFilter} />
    </div>),
  };

  function Filter({ filterKey }: { filterKey: keyof ResultLine }) {
    return <HavingFilter
      onFilter={(v, c) => _setTableData(results?.results.filter(v1 => (c(v, v1[filterKey] as number) <= 0)) || [])}
      onReset={() => _setTableData(results?.results || [])}
      filterKey={filterKey}
    />;
  }

  const columns: YcColumnType<ResultLine>[] = [
    {
      title: 'Revenue', dataIndex: 'revenue', key: 'revenue', defaultSortOrder: 'descend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.revenue, 3),
      filterDropdown: <Filter filterKey='revenue' />
    },
    {
      title: 'Spend', dataIndex: 'spend', key: 'spend', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.spend, 3),
      filterDropdown: <Filter filterKey='spend' />
    },
    {
      title: 'Delta', dataIndex: 'delta', key: 'delta', ycSort: 'number',
      render: (text, record) => NumberTools.roundPrice(record.revenue - record.spend, 3),
      filterDropdown: <Filter filterKey='spend' />
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: YcColumnType<ResultLine>[] = [...columns];
    let bCols: YcColumnType<ResultLine>[] = [];
    if (results?.is.includes("account")) {
      bCols = [columAccount, ...bCols];
    }
    if (results?.is.includes("granularity")) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        ycCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
    { key: 'spend', value: NumberTools.roundPrice(results!.total.spend, 3) },
  ] : undefined;

  return (
    <div id='reporting-billing'>
      <YcTitle label="Reporting - Billing" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilterSuperAdmin service={reportingBillingService_SA} onSearch={onSearch} onSetFilters={onSetFilters} filters={filters} type="billing"></ReportingFilterSuperAdmin>
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue', 'spend']} repeatFields />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-billing"
              rowKey="id"
            // rowClassName={(record) => (record.bidAbTestName === 'control' ? 'control-row' : '')}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
