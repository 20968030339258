import { YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { DualAxes } from '@ant-design/charts';
import { Button, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { monitoringService_SA } from '@/services';

import './monitoring.page.scss';

export function SAMonitoringPage() {
  usePageTitle('Monitoring');

  const [loading, setLoading] = useState(false);
  const [adEventsByMinute, setAdEventsByMinute] = useState<{ time: string; nbEvents: number; perSecond: number; }[]>([]);

  function updateAdEventsByMinute() {
    setLoading(true);

    monitoringService_SA.getAdEventsPerMinute().then((data) => {
      setAdEventsByMinute(data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setAdEventsByMinute([]);
    });
  }

  useEffect(updateAdEventsByMinute, []);

  return (
    <Spin spinning={loading}>
      <YcTitle label="Monitoring" ></YcTitle>
      <div style={{ padding: '8px' }}>
        <div className='refresh-button'><Button onClick={updateAdEventsByMinute}>Refresh</Button></div>
        <DualAxes data={[adEventsByMinute, adEventsByMinute]} height={400} xField='time' yField={['perSecond', 'nbEvents']} geometryOptions={[
          { geometry: 'line', point: { size: 3, shape: 'circle' } },
          { geometry: 'line', point: { size: 3, shape: 'circle' } },
        ]} />
      </div>
    </Spin>
  )
}
