import { Form, Input, Button, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { appsService } from '../../../../services/apps.service';
import { placementsService } from '../../../../services/placements.service';
import { MApp, MPlacement } from '../../../../modeles';

import { YcTitle } from '../../../../components';
import { e_PlacementType } from '../../../../enums';

import './placement.page.scss';
import { setPageTitle } from '@/hooks/use-title';
import { CaretRightOutlined, CheckCircleOutlined, PauseCircleOutlined, PauseOutlined } from '@ant-design/icons';

export function PlacementPage() {
  const [applications, _setApplications] = useState<MApp[]>([]);

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [placement, _setPlacement] = useState<MPlacement>(new MPlacement());

  const uHistory = useHistory();
  const uParams: any = useParams();

  const types = [e_PlacementType.INTERSTITIAL, e_PlacementType.REWARDED, e_PlacementType.BANNER];

  useEffect(() => {
    Promise.all([
      appsService.listingShort(),
      !!uParams['key'] && placementsService.view(uParams['key']),
    ]).then(([apps, data]) => {
      _setApplications(apps || []);
      if (!!uParams['key']) {
        if (data) {
          _setPlacement(data);
          setPageTitle(`Placement - ${data.name}`);
          _setIsReady(true);
        } else {
          uHistory.push("/publishing/placements");
        }
      } else {
        _setIsReady(true);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onFinish = async (values: any) => {
    const c = { ...placement, ...values };
    if (!!placement?.key) {
      const data = await placementsService.edit(placement?.key, c);
      if (data) {
        _setPlacement(data);
      }
    } else {
      const data = await placementsService.add(c);
      if (data) {
        _setPlacement(data);
        uHistory.push("/publishing/placements/placement/" + data?.key);
      }
    }
  };

  return (
    <div id='publishing-placement'>
      {isReady &&
        <>
          <YcTitle links={[{ link: "/publishing/placements", label: "Placements" }]} label={placement.name}>
            <Tooltip title={placement.isActive ? 'Disable' : 'Enable'}>
              <Button onClick={async () => {
                _setPlacement({ ...placement, isActive: !placement.isActive });
              }} type={!placement.isActive ? 'primary' : 'default'} shape="circle" className="float-end" danger={placement.isActive} icon={placement.isActive ? <PauseOutlined /> : <CaretRightOutlined />} />
            </Tooltip>
            <span style={{ fontSize: '20px' }} className="m-2">
              {placement.isActive && <><CheckCircleOutlined style={{color: 'green' }} /> Active</>}
              {!placement.isActive && <><PauseCircleOutlined style={{color: 'red' }} /> Inactive</>}
            </span>
          </YcTitle>
          <Form onFinish={onFinish} layout="vertical">
            <div className='row'>
              <div className='col-4'>
                <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={placement.name}>
                  <Input type="text" />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item label="Application" name="applicationKey" rules={[{ required: true }]} initialValue={placement.applicationKey}>
                  <Select disabled={!!placement.key}>
                    {applications.map(application => (
                      <Select.Option key={application.key} value={application.key}>{application.name} - {application.platform}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item label="Type" name="type" rules={[{ required: true }]} initialValue={placement.type}>
                  <Select disabled={!!placement.key}>
                    {types.map((type, index) => (
                      <Select.Option key={index} value={type}>{type}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {placement.key &&
                <div className='col-4'>
                  <Form.Item label="Key" name="key" initialValue={placement.key} >
                    <Input disabled type="text" />
                  </Form.Item>
                </div>
              }
            </div>
            <Form.Item>
              <Button type="primary" htmlType='submit' className='mt-4'>
                {!placement.key && <>Create</>}
                {!!placement.key && <>Edit</>}
              </Button>
            </Form.Item>
          </Form>
        </>
      }
    </div >
  );
}