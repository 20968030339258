/**
 * The following table lists the media ratings used in describing content based on the IQG 2.1
 * categorization. Refer to www.iab.com/guidelines/digital-video-suite for more information.
 */
enum IQGMediaRatings {
  ALL_AUDIENCES = 1,
  EVERYONE_OVER_TWELVE = 2,
  MATURE_AUDIENCES = 3,
}

export default IQGMediaRatings;
