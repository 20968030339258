import { MApp } from "@/modeles";
import { appsService } from "@/services";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export function useApps() {
  const [apps, _setListing] = useState<MApp[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayApps, setDisplayApps] = useState<MApp[]>([]);
  const uHistory = useHistory();

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback((event, overwriteApps?: MApp[]) => {
    const { value } = event.target;

    if (value) {
      const dApps = (overwriteApps || apps).filter((a) => {
        const checkName = a.name.toLowerCase().includes(value?.toLowerCase());
        const checkBundle = a.bundleId.toLowerCase().includes(value?.toLowerCase());
        const checkIosBundle = a.key.toLowerCase().includes(value?.toLowerCase());

        return checkName || checkBundle || checkIosBundle;
      });

      setDisplayApps(dApps);
    } else {
      setDisplayApps((overwriteApps || apps));
    }
  }, [apps]);

  useEffect(() => {
    setLoading(true);
    appsService.listing().then((data) => {
      setLoading(false);
      if (data) {
        _setListing(data);
        // @ts-ignore
        onSearch({ target: { value: '' } }, data);
      } else {
        uHistory.push("/");
      }
    }).catch((e) => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, onSearch, displayApps };
}