import { Form, Input, Button, Popconfirm, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { profileService } from '@/services/profile.service';

import './profile.page.scss';
import { useEffect, useState } from 'react';
import { YcCard, YcTitle } from '@/components';
import { MAccount, Permissions } from '@/modeles';
import TextArea from 'antd/lib/input/TextArea';
import { useAuths } from '@/contexts/auths.context';
import { usePageTitle } from '@/hooks/use-title';

function Field(props: { value: string; label: string; onChange: (value: string) => void }) {
  async function onGenerate() {
    const k = await profileService.generateKey();
    if (k) {
      props.onChange(k);
    }
  }

  return (
    <Form.Item label={props.label} rules={[{ required: true }]}>
      <Input.Group compact>
        <Input style={{ width: 'calc(100% - 32px)' }} value={props.value} disabled={true} />
        <Popconfirm
          title={() => (
            <>
              Do you realy want to Regenerate it ?<br />
              If you already use this key, you have to replace it every where.
            </>
          )}
          placement="topRight"
          okText="Regenerate"
          onConfirm={onGenerate}
        >
          <Button type="primary" icon={<ReloadOutlined />} />
        </Popconfirm>
      </Input.Group>
    </Form.Item>
  );
}

function showAdvertiserSettings(user, permissions: Permissions | null) {
  return user?.account === 1 && permissions?.getAsArray().some((p) => p.includes('advertising/'));
}

export function ProfilePage() {
  usePageTitle('Profile');

  const [account, _setAccount] = useState<MAccount | null>(null);
  const { user, permissions } = useAuths();

  const onUpdatePassword = async (data: any) => {
    await profileService.updatePassword(data);
  };

  const onUpdateTenjin = async (data: any) => {
    await profileService.updateTenjin(data);
  };

  const onUpdateKeys = async (data: any) => {
    if (account) {
      const usersApis = await profileService.updateKeys(account!.UsersApis!);
      if (usersApis) {
        _setAccount({
          ...account,
          UsersApis: usersApis,
        });
      }
    }
  };

  useEffect(() => {
    profileService.view().then((a) => {
      _setAccount(a);
    });
  }, []);

  return (
    <div className="root-profile">
      {!!account && (
        <div className="row">
          {showAdvertiserSettings(user, permissions) && (
            <div>
              <YcCard title="Credit Line this month" open={true}>
                <table className="table">
                  <tbody>
                    <tr>
                      <th>
                        Credit Line<span style={{ float: 'right' }}>+</span>
                      </th>
                      <td>{'$' + account?.creditLine?.toLocaleString('en')}</td>
                    </tr>
                    <tr>
                      <th>
                        Spend <span style={{ float: 'right' }}>-</span>
                      </th>
                      <td>{'$' + account?.creditLineSpend?.toLocaleString('en')}</td>
                    </tr>
                    <tr>
                      <th>
                        Remender <span style={{ float: 'right' }}>=</span>
                      </th>
                      <td>{'$' + account?.creditLineRemender?.toLocaleString('en')}</td>
                    </tr>
                  </tbody>
                </table>
              </YcCard>
            </div>
          )}

          <div>
            <div className="network-card">
              <div className="d-inline-flex">
                <img title="profile" alt="profile" className="profile-style" src="/user-profile/profile-ico.svg" />
                <YcTitle label="Profile"></YcTitle>
              </div>

              {permissions?.isAllowed('settings/changePassword') && (
                <div>
                  <YcCard title="Change password" open>
                    <Form onFinish={onUpdatePassword} layout="vertical">
                      <Form.Item label="Current Password" name="currentPassword" rules={[{ required: true }]}>
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true }]}>
                        <Input type="password" />
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Change password
                      </Button>
                    </Form>
                  </YcCard>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="network-card">
              <div className="d-inline-flex">
                <img title="account" alt="account" className="profile-style" src="/user-profile/account-ico.svg" />
                <YcTitle label="Account"></YcTitle>
              </div>

              {permissions?.isAllowed('settings/api') && (
                <div>
                  {account !== null && (
                    <YcCard title="Keys" className="keys">
                      <Form onFinish={onUpdateKeys} layout="vertical">
                        {account.UsersApis?.map((api, index) => (
                          <div className={'key' + (api.isActive ? '' : ' inactive')} key={index}>
                            {!api.id && (
                              <span className={!!api.id ? '' : 'new'}>
                                <a
                                  className="remove"
                                  onClick={() => {
                                    _setAccount({
                                      ...account,
                                      UsersApis: account.UsersApis!.filter((a) => a !== api),
                                    });
                                  }}
                                  href="#/"
                                >
                                  x
                                </a>
                                {!!api.id ? '' : 'New'}
                              </span>
                            )}
                            <Form.Item
                              label={'Name ' + (api.isActive ? '(Active)' : '(Inactive)')}
                              name={'name' + index}
                              rules={[{ required: true }]}
                              initialValue={api.name}
                            >
                              <Input
                                value={api.name}
                                onChange={(e) => {
                                  api.name = e.target.value;
                                  _setAccount({ ...account });
                                }}
                              />
                            </Form.Item>
                            <Field
                              value={api.reportKey}
                              label="Report Key"
                              onChange={(value) => {
                                api.reportKey = value;
                                _setAccount({ ...account });
                              }}
                            ></Field>
                            <Field
                              value={api.managerKey}
                              label="Manager Key"
                              onChange={(value) => {
                                api.managerKey = value;
                                _setAccount({ ...account });
                              }}
                            ></Field>
                            <Form.Item label="Is Active">
                              <Switch
                                checked={api.isActive}
                                onChange={(e) => {
                                  api.isActive = e;
                                  _setAccount({ ...account });
                                }}
                              ></Switch>
                            </Form.Item>
                          </div>
                        ))}
                        {(!account.UsersApis || !account.UsersApis!.find((e) => !e.id)) && (
                          <Button
                            onClick={async () => {
                              _setAccount({
                                ...account,
                                UsersApis: [
                                  ...(account.UsersApis || []),
                                  {
                                    name: '',
                                    reportKey: await profileService.generateKey(),
                                    managerKey: await profileService.generateKey(),
                                    isActive: true,
                                  },
                                ],
                              });
                            }}
                          >
                            + Add Keys
                          </Button>
                        )}
                        <hr />
                        <Button type="primary" htmlType="submit">
                          Update Keys
                        </Button>
                      </Form>
                    </YcCard>
                  )}
                </div>
              )}
              {showAdvertiserSettings(user, permissions) && (
                <div>
                  <YcCard title="Tenjin">
                    <Form onFinish={onUpdateTenjin} layout="vertical">
                      <Form.Item label="Active" name="isActive" initialValue={account.tenjin!.isActive}>
                        <Switch defaultChecked={account.tenjin!.isActive} />
                      </Form.Item>
                      <Form.Item label="Token" name="token" initialValue={account.tenjin!.token}>
                        <TextArea rows={7} />
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update Tenjin
                      </Button>
                    </Form>
                  </YcCard>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
