import { Link } from 'react-router-dom';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';

import { CampaignCreateMetadataType } from './types';
import { Button } from 'antd';

export function CampaignCreateMetadata({ metadata }: CampaignCreateMetadataType) {
  const [platform] = metadata.applicationKey.split('-');

  return (
    <div>
      <div>
        Budget: {metadata.budget}. {metadata.countries.length} countries.<br/>
        <Button className='mx-2' type="link" href={metadata.trackingLinkImpression}>Impression link</Button>
        <Button type="link" href={metadata.trackingLinkClick}>Click link</Button>
      </div>
      <div>
        {metadata.Videos?.length && `${metadata.Videos?.length} videos, `}
        {metadata.Images?.length && `${metadata.Images?.length} images, `}
        {metadata.Endcards?.length && `${metadata.Endcards?.length} endcards, `}
        {metadata.Playables?.length && `${metadata.Playables?.length} playables, `}
        {metadata.Banners?.length && `${metadata.Banners?.length} banners, `}
      </div>
      <div>
        {platform === 'ios' ? <AppleOutlined /> : <AndroidOutlined />}
        <Link className='mx-2' to={`/advertising/campaigns/campaign/${metadata.key}`}>{metadata.name}</Link>
        <Link className='mx-2' to={`/apps/app/${metadata.applicationKey}`}>(app)</Link>
      </div>
    </div>
  );
}