/**
 * The following table lists the various types of video placements derived largely from the IAB Digital Video Guidelines.
 */
enum VideoPlacementTypes {
  /**
   * Played before, during or after the streaming video content that the consumer has requested
   * (e.g., Pre-roll, Mid-roll, Post-roll).
   */
  IN_STREAM = 1,

  /**
   * Exists within a web banner that leverages the banner space to deliver a video experience as
   * opposed to another static or rich media format. The format relies on the existence of display
   * ad inventory on the page for its delivery.
   */
  IN_BANNER = 2,

  /**
   * Exists within a web banner that leverages the banner space to deliver a video experience as
   * opposed to another static or rich media format. The format relies on the existence of display
   * ad inventory on the page for its delivery.
   */
  IN_ARTICLE = 3,

  /**
   * Found in content, social, or product feeds
   */
  IN_FEED = 4,

  /**
   * Covers the entire or a portion of screen area, but is always on screen while displayed (i.e.
   * cannot be scrolled out of view). Note that a full-screen interstitial (e.g., in mobile) can be
   * distinguished from a floating/slider unit by the imp.instl field.
   */
  INTERSTITIAL = 5,

  /**
   * Covers the entire or a portion of screen area, but is always on screen while displayed (i.e.
   * cannot be scrolled out of view). Note that a full-screen interstitial (e.g., in mobile) can be
   * distinguished from a floating/slider unit by the imp.instl field.
   */
  SLIDER = 5,

  /**
   * Covers the entire or a portion of screen area, but is always on screen while displayed (i.e.
   * cannot be scrolled out of view). Note that a full-screen interstitial (e.g., in mobile) can be
   * distinguished from a floating/slider unit by the imp.instl field.
   */
  FLOATING = 5,
}

export default VideoPlacementTypes;
