import { useEffect, useState } from 'react';

import './doc-reader.scss';

export function YcDocReader(props: {
  file: string,
}) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(props.file)
      .then(response => response.text())
      .then(html => {
        setHtmlContent(html);
      });
  }, [props.file]);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
  );
}
