/**
 * The following table lists the options to indicate markup types allowed for companion ads that apply to
 * video and audio ads. This table is derived from VAST 2.0+ and DAAST 1.0 specifications. Refer to
 * www.iab.com/guidelines/digital-video-suite for more information.
 */

enum CompanionTypes {
  STATIC_RESOURCE = 1,
  HTML_RESOURCE = 2,
  IFRAM_RESOURCE = 3,
}
export default CompanionTypes;
