import { useEffect, useState } from "react";
import { UnorderedListOutlined, EyeOutlined } from '@ant-design/icons';

import { YcTabs, YcTitle } from "../../../../components";
import { profileService } from "../../../../services/profile.service";
import { docsService } from "../../../../services/docs.service";
import { YcQuery, YcQueryData } from "../../components";

import './docs-report-publishing.page.scss';

export function DocsReportPublishingPage() {

  const PATH = "/api/report/publishing";

  const [tab, _setTab] = useState("search");
  const [headers, _setHeaders] = useState<{} | null>(null);
  const [docs, _setDocs] = useState<{ [key: string]: YcQueryData } | null>(null);

  useEffect(() => {
    async function init() {
      _setDocs((await docsService.view(PATH)).routes);
      const reportKey = await profileService.viewManagerOrReportKey("reportKey");
      if (reportKey) {
        _setHeaders({
          authorization: reportKey,
        });
      } else {
        _setHeaders({ authorization: "NO reportKey active FOUND IN PROFILE" });
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='docs-report-publishing'>
      <YcTitle label="Docs > Report Publishing"></YcTitle>
      <p>
        In this api you can have reporting publishing (Monet).
      </p>
      <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
        { key: "search", label: "Search", icon: <UnorderedListOutlined /> },
        { key: "filter", label: "Filter", icon: <EyeOutlined /> },
      ]} />
      <div className="doc-container">
        {!!headers &&
          <>
            {tab === "search" &&
              <YcQuery data={docs![PATH + '/search']} headers={headers} />
            }
            {tab === "filter" &&
              <YcQuery data={docs![PATH + '/filters']} headers={headers} />
            }
          </>
        }
      </div>
    </div>
  );
}