import AdPosition from '../enum/AdPosition';
import APIFrameworks from '../enum/APIFrameworks';
import Banner from './Banner';
import CompanionTypes from '../enum/CompanionTypes';
import ContentDeliveryMethods from '../enum/ContentDeliveryMethods';
import CreativeAttributes from '../enum/CreativeAttributes';
import StartDelay from '../enum/StartDelay';
import VideoLinearity from '../enum/VideoLinearity';
import VideoPlacementTypes from '../enum/VideoPlacementTypes';
import PlaybackCessationModes from '../enum/PlaybackCessationModes';
import PlaybackMethods from '../enum/PlaybackMethods';
import Protocols from '../enum/Protocols';

export enum Skip {
  NO = 0,
  YES = 1,
}

export enum BoxingAllowed {
  NO = 0,
  YES = 1,
}

/**
 * Video:
 * This object represents an in-stream video impression. Many of the fields are non-essential for minimally
 * viable transactions, but are included to offer fine control when needed. Video in OpenRTB generally
 * assumes compliance with the VAST standard. As such, the notion of companion ads is supported by
 * optionally including an array of Banner objects that define these companion ads.
 * The presence of a Video as a subordinate of the Imp object indicates that this impression is offered as a
 * video type impression. At the publisher’s discretion, that same impression may also be offered as
 * banner, audio, and/or native by also including as Imp subordinates objects of those types. However,
 * any given bid for the impression must conform to one of the offered types.
 */
export default interface Video {
  /** Conten MINE types supported (e.g., "video/x-ms-wmv", "video/mp4") */
  mimes: Array<string>;

  /** Minimun duration in seconds */
  minduration?: number;

  /** Maximun video duration in seconds */
  maxduration?: number;

  /**
   * @deprecated
   * NOTE: Deprecated in favor of protocols.
   * Supported video protocol. Refer to List 5.8. At least one
   * supported protocol must be specified in either the protocol
   * or protocols attribute.
   */
  protocol?: Protocols;

  /**
   * Array of supported video protocols. Refer to List 5.8. At least
   * one supported protocol must be specified in either the
   * protocol or protocols attribute
   */
  protocols?: Array<Protocols>;

  /** Width of the video player in device independent pixels (DIPS). */
  w?: number;

  /** Height of the video player in device independent pixels (DIPS). */
  h?: number;

  /**
   * Indicates the start delay in seconds for pre-roll, mid-roll, or
   * post-roll ad placements. Refer to List 5.12 for additional
   * generic values.
   */
  startdelay?: StartDelay | number;

  /** Placement type for the impression. Refer to List 5.9 */
  placement?: VideoPlacementTypes;

  /**
   * Indicates if the impression must be linear, nonlinear, etc. If
   * none specified, assume all are allowed. Refer to List 5.7.
   */
  linearity?: VideoLinearity;

  /**
   * Indicates if the player will allow the video to be skipped,
   * where 0 = no, 1 = yes.
   * If a bidder sends markup/creative that is itself skippable, the
   * Bid object should include the attr array with an element of
   * 16 indicating skippable video. Refer to List 5.3.
   */
  skip?: Skip;

  /**
   * Videos of total duration greater than this number of seconds
   * can be skippable; only applicable if the ad is skippable.
   *
   * @defaultValue `0`
   */
  skipmin?: number;

  /**
   * Number of seconds a video must play before skipping is
   * enabled; only applicable if the ad is skippable.
   *
   * @defaultValue `0`
   */
  skipafter?: number;

  /**
   * If multiple ad impressions are offered in the same bid request,
   * the sequence number will allow for the coordinated delivery
   * of multiple creatives.
   */
  sequence?: number;

  /**
   * Blocked creative attributes. Refer to List 5.3.
   */
  battr?: Array<CreativeAttributes>;

  /**
   * Maximum extended ad duration if extension is allowed. If
   * blank or 0, extension is not allowed. If -1, extension is
   * allowed, and there is no time limit imposed. If greater than 0,
   * then the value represents the number of seconds of extended
   * play supported beyond the maxduration value.
   */
  maxextended?: number;

  /**
   * Minimun bit rate in Kbps
   */
  minbitrate?: number;

  /**
   * Maximun bit rate in Kbps
   */
  maxbitrate?: number;

  /**
   * Indicates if letter-boxing of 4:3 content into a 16:9 window is
   * allowed, where 0 = no, 1 = yes.
   *
   * @defaultValue `BoxingAllowed.YES`
   */
  boxingallowed?: BoxingAllowed;

  /**
   * Playback methods that may be in use. If none are specified,
   * any method may be used. Refer to List 5.10. Only one
   * method is typically used in practice. As a result, this array may
   * be converted to an integer in a future version of the
   * specification. It is strongly advised to use only the first
   * element of this array in preparation for this change
   */
  playbackmethod?: Array<PlaybackMethods>;

  /**
   * The event that causes playback to end. Refer to List 5.11.
   */
  playbackend?: PlaybackCessationModes;

  /**
   * Supported delivery methods (e.g., streaming, progressive).
   * If none specified, assume all are supported. Refer to List 5.15.
   */
  delivery?: Array<ContentDeliveryMethods>;

  /**
   * Ad position on screen. Refer to List 5.4.
   */
  pos?: AdPosition;

  /**
   * Array of Banner objects (Section 3.2.6) if companion ads are available.
   */
  companionad?: Array<Banner>;

  /**
   * List of supported API frameworks for this impression. Refer to
   * List 5.6. If an API is not explicitly listed, it is assumed not to be
   * supported.
   */
  api?: Array<APIFrameworks>;

  /**
   * Supported VAST companion ad types. Refer to List 5.14.
   * Recommended if companion Banner objects are included via
   * the companionad array. If one of these banners will be
   * rendered as an end-card, this can be specified using the vcm
   * attribute with the particular banner (Section 3.2.6).
   */
  companiontype?: Array<CompanionTypes>;

  /**
   * Placeholder for exchange-specific extensions to OpenRTB.
   */
  ext?: any;
  /**
   *
   */
}
