import { Route, Switch, useRouteMatch } from "react-router-dom";

// styles
import './index.scss';

// pages
import { AppsPage, AppPage } from './pages';

export function AppsModule() {
  const { path } = useRouteMatch();

  return (
    <div className="apps">
      <Switch>
        <Route exact path={path}><AppsPage /></Route>
        <Route path={`${path}/app/:key`}><AppPage /></Route>
        <Route path={`${path}/app`}><AppPage /></Route>
      </Switch>
    </div>
  );

}
