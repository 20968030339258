import { MAccount, MApp } from ".";

export class MBonus {
  id: number = 0;
  applicationId?: number;
  accountId?: number;
  expireAt?: Date;
  type: 'ua' | 'monet' = 'ua';
  value: number = 0;
  Application?: MApp;
  Account?: MAccount;
}