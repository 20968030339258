import { Permissions } from '@/modeles';
import moment from 'moment';

export class FilterTool {

  public static BOOL_filters(ok: string, ko: string, empty: string = "") {
    const list: {text: string, value: number | string | boolean}[] = [{ text: ok, value: true }, { text: ko, value: false }];
    if (empty !== "") {
      list.push({ text: empty, value: '' });
    }
    return list;
  }

  public static BOOL_onFilter(field: string) {
    return (value, record) => (value === '' && record[field] === null) || record[field] === value;
  }

  public static STRING_filters(values: string[]) {
    const list: {text: string, value: string }[] = values.map(v => ({ text: v, value: v }))
    return list;
  }

  public static STRING_onFilter(getField: (record) => string) {
    return (value, record) => (value === '' && getField(record) === null) || getField(record) === value;
  }

  public static canAccess(path: string, permissions: Permissions | null, superAdmin: boolean) {
    return (superAdmin) || !!(path && permissions && permissions.isAllowed(path));
  }

  public static setParamsFilter(items: FCItem[], paramsString: string) {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(item.value + "-Y")) {
          item.checked = true;
        } else if (params.includes(item.value + "-N")) {
          item.checked = false;
        }
      });
    }
  }

  public static getParamsFilter(items: FCItem[] = []) {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => item.value + "-" + (item.checked ? "Y" : "N")).join() || undefined;
  }

  public static parseSearch(search: URLSearchParams) {
    return {
      dateStart: search.has('dateStart') ? moment(search.get('dateStart')) : null,
      dateEnd: search.has('dateEnd') ? moment(search.get('dateEnd')) : null,
      isGranularity: search.has('is') && search.has('granularity') && search.get('is')!.split(',').includes('granularity'),
      granularity: search.has('granularity') ? search.get('granularity') : null,
    }
  }

  public static getParamsIs(isFilters: { filterKey: string; searchKey: string }[], filters: Filters) {
    const is: string[] = [];

    isFilters.forEach((item) => {
      if (filters?.[item.filterKey]) { is.push(item.searchKey); }
    });
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  }

  public static initFCItemStates({ paramsFilters, isFilters, data, search }: {
    data: Filters;
    search: URLSearchParams;
    isFilters: { filterKey: string; searchKey: string }[];
    paramsFilters: string[];
  }) {
    paramsFilters.forEach((key) => {
      FilterTool.setParamsFilter(data[key], search.get(key)!);
    });

    if (search.has('is')) {
      const is = search.get('is')!.split(',');
      isFilters.forEach((item) => {
        data[item.filterKey] = is.includes(item.searchKey);
      });
    }
  }

}
