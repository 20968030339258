/**
 * The following table indicates the types of ads that can be accepted by the exchange unless restricted by
 * publisher site settings.
 */

enum BannerAdTypes {
  /** Usually mobile */
  XHTML_TEXT_AD = 1,

  /** Usually mobile */
  XHTML_BANNER_AD = 2,

  /**  Must be valide XHTML, Script Tags included */
  XHTML_JAVASCRIPT_AD = 3,

  IFRAME = 4,
}

export default BannerAdTypes;
