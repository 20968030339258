import { PlatformActionEntity, PlatformActionType } from '@/modeles';

import { ApplicationCreateMetadata } from './application-create.component';
import { CampaignCreateMetadata } from './campaign-create.component';
import { CampaignUpdateMetadata } from './campaign-update.component';
import { PlacementCreateMetadata } from './placement-create.component';
import { PlacementUpdateMetadata } from './placement-update.component';

import { BaseMetadataType } from './types';

const COMPONENT_MAP = {
  [PlatformActionEntity.APPLICATION]: {
    [PlatformActionType.CREATE]: ApplicationCreateMetadata,
  },
  [PlatformActionEntity.PLACEMENT]: {
    [PlatformActionType.CREATE]: PlacementCreateMetadata,
    [PlatformActionType.UPDATE]: PlacementUpdateMetadata,
  },
  [PlatformActionEntity.CAMPAIGN]: {
    [PlatformActionType.CREATE]: CampaignCreateMetadata,
    [PlatformActionType.UPDATE]: CampaignUpdateMetadata,
  },
}

export function PlatformActionMetadata({ entity, type, metadata }: BaseMetadataType) {
  return COMPONENT_MAP[entity]?.[type] ? COMPONENT_MAP[entity][type]({ metadata }) : (<div>{JSON.stringify(metadata)}</div>);
}