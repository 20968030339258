import { BaseService } from "./base.service";

class MmpsService extends BaseService {

  PATH = "mmps/";

  async tenjinTrackingLinkImport(applicationKey: string): Promise<{ trackingLinkImpression: string; trackingLinkClick: string; } | null> {
    return await this._view(this.PATH + "tenjin-tracking-link-import/" + applicationKey);
  }

  async tenjinTrackingLinkCreateOrImport(applicationKey: string): Promise<{ trackingLinkImpression: string; trackingLinkClick: string; } | null> {
    return await this._view(this.PATH + "tenjin-tracking-link-create-or-import/" + applicationKey);
  }

}

export const mmpsService = new MmpsService();