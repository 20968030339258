import { Image, Space } from 'antd-v5';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

// styles
import './asset-preview.scss';

export function YcAssetPreview(props: {
  src: string,
  fileName: string,
  alt?: string,
  render?: () => JSX.Element,
  previewSrc?: string,
  hideToolbar?: boolean,
  width?: number,
  height?: number,
}) {
  const onDownload = (src: string, fileName: string) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  return (
    <Image
      src={props.src}
      alt={props.alt}
      width={props.width}
      height={props.height}
      preview={{
        src: props.previewSrc || props.src,
        imageRender: props.render,
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => props.hideToolbar ? null : (
          <Space size={12} className="toolbar-wrapper">
            <DownloadOutlined onClick={() => onDownload(props.src, props.fileName)} />
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
  );
}