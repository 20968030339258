import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

// styles
import './title.scss';
import { Tooltip } from 'antd';
import { SuperAdminTools } from '@/tools';

export function YcTitle(props: {
  links?: [{
    link: string,
    label: string,
  }],
  label: string,
  addUrl?: string,
  children?: React.ReactNode;
}) {
  return (
    <h1 className="c-title">
      {props.links && props.links.map((link, index) =>
        <span key={index}>
          <Link to={link.link}>{link.label}</Link> &gt;&nbsp;
        </span>
      )}
      {!props.label && <>New</>}
      {SuperAdminTools.isPathSuperAdmin() && <>[SA] </>}
      {props.label && <>{props.label}</>}
      {!!props.addUrl && <>
        <Tooltip title="Add">
          <Link type="primary" to={props.addUrl} className="icon float-end"><PlusOutlined /></Link>
        </Tooltip>
      </>}
      {props.children}
    </h1>
  );
}