import { Table, } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

// styles
import './assets.page.scss';
import { Link } from 'react-router-dom';
import { YcIcon, YcTitle } from '../../../../components';
import { MCampaignAsset } from '../../../../modeles';
import { useAssets } from './useAssets';
import { ColumnType } from 'antd/lib/table';
import { FilterTool } from '@/tools/filter.tool';
import { usePageTitle } from '@/hooks/use-title';

function AdminAssetPageApplication({ asset }: { asset: MCampaignAsset }) {
  return (
    <Link to={"/apps/app/" + asset.Campaign?.Application.key}>
      <YcIcon app={asset.Campaign?.Application}></YcIcon>
      {asset.Campaign?.Application.name}
    </Link>
  );
}

function AdminAssetPageAsset({ asset }: { asset: MCampaignAsset }) {
  return (
    <div className='asset'>
      {asset.type === "VIDEO" && <video controls src={asset.Document?.url} className='video' />}
      {asset.type === "IMAGE" && <img alt="FullScreen" src={asset.Document?.url} className='image' />}
      {asset.type === "BANNER" && <img alt="Banner" src={asset.Document?.url} className='banner' />}
      {asset.type === "ENDCARD" && <img alt="Endcard" src={asset.Document?.url} className='endcard' />}
    </div>
  );
}

function AdminAssetPageCampaign({ asset }: { asset: MCampaignAsset }) {
  return (
    <Link to={"/advertising/campaigns/campaign/" + asset.Campaign?.key}>
      {asset.Campaign?.name}
    </Link>
  );
}

function AdminAssetPageValidate({ asset, onValidate, onDevalidate }: {
  asset: MCampaignAsset;
  onValidate: (asset: MCampaignAsset) => Promise<void>;
  onDevalidate: (asset: MCampaignAsset) => Promise<void>;
}) {
  return (
    <>
      {asset.isValide === null &&
        <>
          <a href="#/" onClick={() => (onValidate(asset))}>
            <PlusCircleOutlined style={{ color: 'green' }} />
          </a>
          &nbsp;
          <a href="#/" onClick={() => (onDevalidate(asset))}>
            <PlusCircleOutlined style={{ color: 'red' }} />
          </a>
        </>
      }
      {asset.isValide === true &&
        <a href="#/" onClick={() => (onDevalidate(asset))}>
          <PlusCircleOutlined style={{ color: 'green' }} />
        </a>
      }
      {asset.isValide === false &&
        <a href="#/" onClick={() => (onValidate(asset))}>
          <PlusCircleOutlined style={{ color: 'red' }} />
        </a>
      }
    </>
  )
}

export function SAAssetsPage() {
  usePageTitle('Assets');

  const { assets, onValidate, onDevalidate } = useAssets();

  const assetTableColumns: ColumnType<MCampaignAsset>[] = [
    { title: 'Id', dataIndex: 'id', key: 'id', sorter: (a, b) => (a.id || 0) - (b.id || 0), sortOrder: 'descend' },
    {
      title: 'Application', key: 'applicationId',
      render: (text: string, asset) => <AdminAssetPageApplication asset={asset} />,
      sorter: (a, b) => a.Campaign?.Application.name.localeCompare(b.Campaign?.Application.name || '') || -1
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'name',
      sorter: (a, b) => a.type?.localeCompare(b.type || '') || -1,
      filters: [{ text: 'Image', value: 'IMAGE' }, { text: 'Video', value: 'VIDEO' }, { text: 'Endcard', value: 'ENDCARD' }, { text: 'Playable', value: 'PLAYABLE' }],
      onFilter: (value, record) => record.type?.toLowerCase() === (value as string).toLowerCase(),
    },
    {
      title: 'Asset', key: 'asset',
      render: (text: string, asset) => <AdminAssetPageAsset asset={asset} />,
    },
    {
      title: 'Campaign',
      dataIndex: ['Campaign', 'name'],
      key: 'name',
      sorter: (a, b) => a.Campaign?.name.localeCompare(b.Campaign?.name || '') || -1,
      render: (text: string, asset) => <AdminAssetPageCampaign asset={asset} />,
    },
    {
      title: 'Validate', key: 'isValide',
      render: (text: string, asset) => <AdminAssetPageValidate asset={asset} onValidate={onValidate} onDevalidate={onDevalidate} />,
      filters: FilterTool.BOOL_filters('Valid', 'Invalid', 'To valided'),
      onFilter: FilterTool.BOOL_onFilter('isValide'),
      defaultFilteredValue: [''],
    },
  ];

  return (
    <div id='admin-assets'>
      <YcTitle label="Assets" ></YcTitle>
      <Table dataSource={assets} columns={assetTableColumns} rowKey="id" />
    </div>
  );
}
