/**
 * The following table lists the various playback methods.
 */
enum PlaybackMethods {
  PAGE_LOAD_WITH_SOUND_ON = 1,
  PAGE_LOAD_WITH_SOUND_OFF = 2,
  CLICK_WITH_SOUND_ON = 3,
  MOUSE_OVER_WITH_SOUND_ON = 4,
  ENTERING_VIEWPORT_WITH_SOUND_ON = 5,
  ENTERING_VIEWPORT_WITH_SOUND_OFF = 6,
}

export default PlaybackMethods;
