import { Form, Input, Button, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { MApp, MDocument } from '@/modeles';
import { YcTitle, YcUploadIcon } from '@/components';
import { appsService } from '@/services';

import './app.page.scss';
import { setPageTitle } from '@/hooks/use-title';
import { useAuths } from '@/contexts/auths.context';
import { AppAdvertiserPage } from './app-advertiser';
import { AppPublisherPage } from './app-publisher';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';

export function AppPage() {
  const { user } = useAuths();
  const [app, _setApp] = useState<MApp | null>(null);

  const uHistory = useHistory();
  const uParams: any = useParams();

  const className = useMemo(() => {
    const nbGraphs = +(user?.Account.isAdvertiser || false) + +(user?.Account.isPublisher || false);
    const graphSize = nbGraphs > 0 ? 12 / nbGraphs : 12;

    return `col-lg-${graphSize} col-md-6 col-sm-12 `;
  }, [user]);

  useEffect(() => {
    if (!!uParams['key']) {
      appsService.view(uParams['key']).then((data) => {
        if (data) {
          _setApp(data);
          setPageTitle(`Application - ${data.name}`);
        } else {
          uHistory.push('/apps');
        }
      });
    } else {
      _setApp({
        key: '',
        name: '',
        platform: '',
        storeUrl: '',
        bundleId: '',
        appleId: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const platforms = [
    { label: 'iOS', key: 'ios' },
    { label: 'Android', key: 'android' },
  ];

  const onFinish = async (values: MApp) => {
    values.Icon = app?.Icon;
    if (!!app?.key) {
      const data = await appsService.edit(app.key, values);
      if (data) {
        _setApp(JSON.parse(JSON.stringify(data)));
      }
    } else {
      const data = await appsService.add(values);
      if (data) {
        _setApp(data);
        uHistory.push('/apps/app/' + data?.key);
      }
    }
  };

  return (
    <div id="apps-app">
      {app && (
        <>
          <YcTitle links={[{ link: '/apps', label: 'Applications' }]} label={app.name}></YcTitle>

          <Form
            className="network-card"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(value: MApp) => {
              _setApp({ ...app, ...value });
            }}
          >
            <div className="float-left">
              <div className="app-icon">
                <YcUploadIcon
                  app={app}
                  onUpload={(doc: MDocument) => {
                    _setApp({ ...app, Icon: doc });
                  }}
                ></YcUploadIcon>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={app.name}>
                  <Input type="text" />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item label="Bundle Id" name="bundleId" rules={[{ required: true }]} initialValue={app.bundleId}>
                  <Input disabled={!!app.key} type="text" placeholder="com.your.game" suffix={
                    app?.platform === 'android' ? (
                      <a href={`https://play.google.com/store/apps/details?id=${app?.bundleId}`} target="_blank" rel="noopener noreferrer">
                        <AndroidOutlined />
                      </a>
                    ) : null
                  } />
                </Form.Item>
              </div>
              <div className={app.platform === 'ios' ? 'col-md-2' : 'col-md-4'}>
                <Form.Item label="Platform" name="platform" rules={[{ required: true }]} initialValue={app.platform}>
                  <Select disabled={!!app.key}>
                    {platforms.map((platform) => (
                      <Select.Option key={platform.key} value={platform.key}>
                        {platform.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {app.platform === 'ios' && (
                <div className="col-md-2">
                  <Form.Item label="Apple Id" name="appleId" rules={[{ required: app.platform === 'ios' }]} initialValue={app.appleId}>
                    <Input disabled={!!app.key || app.platform !== 'ios'} type="text" placeholder="1535922800" suffix={<a href={`https://apps.apple.com/app/id${app?.appleId}`} target="_blank" rel="noopener noreferrer">
                      <AppleOutlined />
                    </a>} />
                  </Form.Item>
                </div>
              )}
              <div>
                <Form.Item style={{ clear: 'left' }}>
                  <Button type="primary" htmlType="submit">
                    {!app.key && <>Create</>}
                    {!!app.key && <>Save</>}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          {!!app?.key && (
            <div className="row">
              <div className={className}>{user?.Account.isAdvertiser && <AppAdvertiserPage bundleId={app.bundleId} platform={app.platform} />}</div>
              <div className={className}>{user?.Account.isPublisher && <AppPublisherPage bundleId={app.bundleId} platform={app.platform} />}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
