export enum KafkaTopics {
  DSP_AD_EVENT_ITEM = 'dsp-ad-event-item-new',
  DSP_AD_EVENT_REVENUE_ITEM = 'dsp-ad-event-revenue-item-new',
  DSP_AD_EVENT_FIRST = 'dsp-ad-event-first-new',
  DSP_BID_REQUEST = 'dsp-bid-request-new',
  DSP_BID_RESPONSE = 'dsp-bid-response-new',
  DSP_DEVICE_EVENT = 'dsp-device-event-new',
  SSP_PLACEMENT_EVENT_FIRST = 'ssp-placement-event-first',
  SSP_PLACEMENT_EVENT_ITEM = 'ssp-placement-event-item'
}
