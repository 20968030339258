import { Button, InputNumber, Space } from "antd";
import React from "react";
import "./having-filter.component.scss";

export function HavingFilter(props: {
  onFilter: (value: number, compare: (a: number, b: number) => number) => void;
  onReset: () => void;
  filterKey: string;
}) {
  const [min, _setMin] = React.useState(0);

  return (<div className="p-1">
    <div className="mb-2 w-100">
      <InputNumber addonBefore=">=" type="number" min={0} placeholder={`Minimum ${props.filterKey}`} defaultValue={min} onChange={v => {
        if (v) {
          _setMin(v);
        }
      }} />
    </div>
    <Space>
      <Button size="small" onClick={() => {
        _setMin(0);
        props.onReset();
      }}>Reset</Button>
      <Button size="small" type="primary" onClick={() => props.onFilter(min, (a, b) => a - b)}>Search</Button>
    </Space>
  </div>);
}
