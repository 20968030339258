import { Route, Switch, useRouteMatch } from "react-router-dom";

import {
  SAAccountsPage,
  SAAccountPage,
  SAAssetsPage,
  SAAdsPage,
  SAMonitoringPage,
  SAUnifiedAppsPage,
  SAUnifiedAppPage,
  SABonusPage,
  SAControlPanelPage,
  SAControlPanelTodayPage,
  SAAdsLearningPage,
  SAAssetsValidationPage,
  SAPlatformActionsPage,
} from './pages';

import './index.scss';
import {
  SAReportingAdvertisingPage,
  SAReportingBillingPage,
  SAReportingSspPage,
} from "../reporting/pages";
import { CampaignsPage } from "../advertising/pages";

export function SuperadminModule() {

  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/accounts/account/:id`}><SAAccountPage /></Route>
      <Route path={`${path}/accounts`}><SAAccountsPage /></Route>
      <Route path={`${path}/advertising/campaigns`}><CampaignsPage isSuperAdmin={true} /></Route>
      <Route path={`${path}/assets`}><SAAssetsPage /></Route>
      <Route path={`${path}/assets-validation`}><SAAssetsValidationPage /></Route>
      <Route path={`${path}/ads`}><SAAdsPage /></Route>
      <Route path={`${path}/ads-learning`}><SAAdsLearningPage /></Route>
      <Route path={`${path}/monitoring`}><SAMonitoringPage /></Route>
      <Route path={`${path}/unified-apps/unified-app/:key`}><SAUnifiedAppPage /></Route>
      <Route path={`${path}/unified-apps/unified-app`}><SAUnifiedAppPage /></Route>
      <Route path={`${path}/unified-apps`}><SAUnifiedAppsPage /></Route>
      <Route path={`${path}/bonuses`}><SABonusPage /></Route>
      <Route path={`${path}/control-panel`}><SAControlPanelPage /></Route>
      <Route path={`${path}/control-panel-today`}><SAControlPanelTodayPage /></Route>
      <Route path={`${path}/platform-actions`}><SAPlatformActionsPage /></Route>
      <Route exact path={`${path}/reporting/advertising`}><SAReportingAdvertisingPage /></Route>
      <Route exact path={`${path}/reporting/ssp`}><SAReportingSspPage /></Route>
      <Route exact path={`${path}/reporting/billing`}><SAReportingBillingPage /></Route>
    </Switch>
  );

}
