import { useState } from "react";

import { YcTabs, YcTitle } from "../../../../components";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import './docs-sdk-unity.page.scss';

export function DocsSdkUnityPage() {

  const [tab, _setTab] = useState("init");

  return (
    <div id='docs-sdk-unity'>
      <YcTitle label="Docs > Sdk Unity"></YcTitle>
      <div className='mb-3'>
        <YcTabs tab={tab} onClick={(e) => { _setTab(e.key); }} elems={[
          { key: "init", label: "Init" },
          { key: "interstitial", label: "Interstitial" },
          { key: "rewarded", label: "Rewarded" },
          { key: "banner", label: "Banner" },
          { key: "gdpr", label: "GDPR" },
          { key: "full", label: "Full Example" },
        ]} />
      </div>
      {tab === 'init' && // ================================= INIT
        <div>
          <p>
            Before to start,
            <ol>
              <li>Import all the package YsoNetwork_X_X_X.package</li>
              <li>Add YNManager.prefab to your first scene</li>
              <li>
                Add this using<br />
                <SyntaxHighlighter language="csharp" style={style}>{`using YsoCorp.Network;`}</SyntaxHighlighter>
              </li>
            </ol>
          </p>
          <p>
            Init YsoNetwork at the beginning of the project
            <SyntaxHighlighter language="csharp" style={style}>
              {`void Start() {
    YNManager.Init();
}`}
            </SyntaxHighlighter>
          </p>
        </div>
      }
      {tab === 'interstitial' && // ================================= INTERSTITIAL
        <div>
          <p>Init interstitial by listen all events:</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.InterstitialOnBid.AddListener((string key, float price) => {
    // Return price of the interstitial bid (CPM)
});
YNManager.InterstitialOnDisplay.AddListener((string key) => {
    // When interstitial display
});
YNManager.InterstitialOnClick.AddListener((string key) => {
    // When interstitial clicked
});
YNManager.InterstitialOnClose.AddListener((string key) => {
    // When interstitial close
    YNManager.InterstitialRequest(key); // Request new one
});
YNManager.InterstitialOnLoaded.AddListener((string key) => {
    // When interstitial loaded
});
YNManager.InterstitialOnLoadFailed.AddListener((string key) => {
    // When interstitial load failed
    YNManager.InterstitialRequest(key, 3); // Request new one in 3s
});`}
          </SyntaxHighlighter>
          <p>Request interstitial just after init, to preload it:</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.InterstialRequest("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>Show interstitial</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.InterstitialShow("YOUR_PLACEMENT_KEY", () => {
  // your code
});`}
          </SyntaxHighlighter>
          <p>If interstitial is ready</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.InterstitialIsReady("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>If interstitial is visible/display</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.InterstitialIsVisible("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
        </div>
      }
      {tab === 'rewarded' && // ================================= Rewarded
        <div>
          <p>Init rewarded by listen all events:</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.RewardedOnBid.AddListener((string key, float price) => {
    // Return price of the rewarded bid (CPM)
});
YNManager.RewardedOnDisplay.AddListener((string key) => {
    // When rewarded display
});
YNManager.RewardedOnClick.AddListener((string key) => {
    // When rewarded clicked
});
YNManager.RewardedOnClose.AddListener((string key) => {
    // When rewarded close
    YNManager.RewardedRequest(key); // Request new one
});
YNManager.RewardedOnLoaded.AddListener((string key) => {
    // When rewarded loaded
});
YNManager.RewardedOnLoadFailed.AddListener((string key) => {
    // When rewarded load failed
    YNManager.RewardedRequest(key, 3); // Request new one in 3s
});`}
          </SyntaxHighlighter>
          <p>Request rewarded just after init, to preload it:</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.RewardedRequest("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>Show rewarded</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.RewardedShow("YOUR_PLACEMENT_KEY", () => {
  // your code
});`}
          </SyntaxHighlighter>
          <p>If rewarded is ready</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.RewardedIsReady("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>If interstitial is visible/display</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.RewardedIsVisible("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
        </div>
      }
      {tab === 'banner' && // ================================= Banner
        <div>
          <p>Init banner by listen all events:</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.BannerOnBid.AddListener((string key, float price) => {
    // Return price of the banner bid (CPM)
});
YNManager.BannerOnDisplay.AddListener((string key) => {
    // When banner display
});
YNManager.BannerOnClick.AddListener((string key) => {
    // When banner clicked
});
YNManager.BannerOnClose.AddListener((string key) => {
    // When banner close
});
YNManager.BannerOnLoaded.AddListener((string key) => {
    // When banner loaded
});
YNManager.BannerOnLoadFailed.AddListener((string key) => {
    // When banner load failed
});`}
          </SyntaxHighlighter>
          <p>Show banner</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.BannerShow("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>Show banner</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.BannerShow("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
          <p>If banner is visible/display</p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.BannerIsVisible("YOUR_PLACEMENT_KEY");`}
          </SyntaxHighlighter>
        </div>
      }
      {tab === 'gdpr' && // ================================= GDPR
        <div>
          <p>
            <u>User Consent</u>: if the user has provided consent for information sharing.
          </p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.GetIsUserConsent();
YNManager.SetIsUserConsent(true | false);`}
          </SyntaxHighlighter>
          <p>
            <u>Age Restricted User</u>: if the user is age restricted (i.e. under 16).
          </p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`YNManager.GetIsAgeRestrictedUser();
YNManager.SetIsAgeRestrictedUser(true | false);`}
          </SyntaxHighlighter>
        </div>
      }
      {
        tab === 'full' && // ================================= FULL EXAMPLE
        <div>
          <p>
            In this part you can see a full example of code Init/Interstitial/Rewarded/Banner
          </p>
          <SyntaxHighlighter language="csharp" style={style}>
            {`using System;
using UnityEngine;
using YsoCorp.Network;

public class YsoNetworkManager : MonoBehaviour {

    float DELAY_BETWEEN_BANNER = 30;

    public void Start() {
        YNManager.Init();
        this.IntertistialInit();
        this.RewardedInit();
        this.BannerInit();
    }

    /********* INTERSTITIAL *********/

    private void IntertistialInit() {
        if (this.GetInterstitialKey() != "") {
            YNManager.InterstitialOnBid.AddListener((string key, float price) => {
                // Return price of the interstitial bid (CPM)
            });
            YNManager.InterstitialOnDisplay.AddListener((string key) => {
                // When interstitial display
            });
            YNManager.InterstitialOnClick.AddListener((string key) => {
                // When interstitial clicked
            });
            YNManager.InterstitialOnClose.AddListener((string key) => {
                // When interstitial close
                YNManager.InterstitialRequest(key); // Request new one
            });
            YNManager.InterstitialOnLoaded.AddListener((string key) => {
                // When interstitial loaded
            });
            YNManager.InterstitialOnLoadFailed.AddListener((string key) => {
                // When interstitial load failed
                YNManager.InterstitialRequest(key, 3); // Request new one in 3s
            });
            YNManager.InterstitialRequest(this.GetInterstitialKey());
        }
    }

    public bool InterstitialIsReady() {
        return YNManager.InterstitialIsReady(this.GetInterstitialKey());
    }

    public void InterstitialShow(Action action) {
        YNManager.InterstitialShow(this.GetInterstitialKey(), () => {
            action();
        });
    }

    /********* REWARDED *********/

    private void RewardedInit() {
        if (this.GetRewardedKey() != "") {
            YNManager.RewardedOnBid.AddListener((string key, float price) => {
                // Return price of the rewarded bid (CPM)
            });
            YNManager.RewardedOnDisplay.AddListener((string key) => {
                // When rewarded display
            });
            YNManager.RewardedOnClick.AddListener((string key) => {
                // When rewarded clicked
            });
            YNManager.RewardedOnClose.AddListener((string key) => {
                // When rewarded close
                YNManager.RewardedRequest(key); // Request new one
            });
            YNManager.RewardedOnLoaded.AddListener((string key) => {
                // When rewarded loaded
            });
            YNManager.RewardedOnLoadFailed.AddListener((string key) => {
                // When rewarded load failed
                YNManager.RewardedRequest(key, 3); // Request new one in 3s
            });
            YNManager.RewardedRequest(this.GetRewardedKey());
        }

    }

    public bool RewardedIsReady() {
        return YNManager.RewardedIsReady(this.GetRewardedKey());
    }

    public void RewardedShow(Action<bool> action) {
        YNManager.RewardedShow(this.GetRewardedKey(), action);
    }

    /********* BANNER *********/

    private void BannerInit() {
        if (this.GetBannerKey() != "") {
            YNManager.BannerOnBid.AddListener((string key, float price) => {
                // Return price of the banner bid (CPM)
            });
            YNManager.BannerOnDisplay.AddListener((string key) => {
                // When banner display
            });
            YNManager.BannerOnClick.AddListener((string key) => {
                // When banner clicked
            });
            YNManager.BannerOnClose.AddListener((string key) => {
                // When banner close
            });
            YNManager.BannerOnLoaded.AddListener((string key) => {
                // When banner loaded
            });
            YNManager.BannerOnLoadFailed.AddListener((string key) => {
                // When banner load failed
            });
        }
    }

    public void BannerShow() {
        YNManager.BannerShow(this.GetBannerKey(), DELAY_BETWEEN_BANNER);
    }

    public void BannerHide() {
        YNManager.BannerHide(this.GetBannerKey());
    }

    /********* KEYS *********/

    string GetRewardedKey() {
#if UNITY_ANDROID
        return ""; // REWARDED_ANDROID_PLACEMENT_KEY
#elif UNITY_IPHONE
        return ""; // REWARDED_IOS_PLACEMENT_KEY
#else
        return "";
#endif
    }

    string GetInterstitialKey() {
#if UNITY_ANDROID
        return ""; // INTERSTITIAL_ANDROID_PLACEMENT_KEY
#elif UNITY_IPHONE
        return ""; // INTERSTITIAL_IOS_PLACEMENT_KEY
#else
        return "";
#endif
    }

    string GetBannerKey() {
#if UNITY_ANDROID
        return ""; // BANNER_ANDROID_PLACEMENT_KEY
#elif UNITY_IPHONE
        return ""; // BANNER_IOS_PLACEMENT_KEY
#else
        return "";
#endif
    }

}`}</SyntaxHighlighter>
        </div>
      }
    </div >
  );
}