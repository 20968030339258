import Search from "antd/lib/input/Search";

import styles from './search-bar.module.css';

export const SearchBar = ({ onSearch, placeholder }) => (
  <>
    <div className={`row ${styles.filters}`}>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Search placeholder={placeholder} onChange={onSearch} className={styles.searchBar} />
      </div>
    </div>
  </>
);