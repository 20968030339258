import { BaseService } from "./base.service";
import { MBidderConfig } from '../modeles';

class BidderConfigService extends BaseService {

  PATH = "bidding/bidder-config/";

  async add(bidderConfig: MBidderConfig): Promise<MBidderConfig | null> {
    return await this._add(this.PATH + "add", bidderConfig);
  }

  async view(): Promise<MBidderConfig | null> {
    return await this._view(this.PATH + "view");
  }

  async edit(bidderConfig: MBidderConfig): Promise<MBidderConfig | null> {
    return await this._edit(this.PATH + "edit", bidderConfig);
  }

  async activate(isActive: boolean): Promise<boolean> {
    return await this._getSuccess(this.PATH + "activate/" + isActive);
  }

}

export const bidderConfigService = new BidderConfigService();