/**
 * The following table lists the options for a bidder to signal the exchange as to why it did not offer a bid
 * for the impression.
 */
enum NoBidReasonCodes {
  UNKNOWN_ERROR = 0,
  TECHNICAL_ERROR = 1,
  INVALID_REQUEST = 2,
  KNOWN_WEB_SPIDER = 3,
  SUSPECTED_NONHUMAN_TRAFFIC = 4,
  CLOUD = 5, DATA_CENTER = 5, PROXY_IP = 5,
  UNSUPPORTED_DEVICE = 6,
  BLOCKED_PUBLISHER_SITE = 7,
  UNMATCHED_USER = 8,
  DAILY_READER_CAP_MET = 9,
  DAILY_DOMAIN_CAP_MET = 10,
}

export default NoBidReasonCodes;
