import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { homeService } from '@/services';
import { ColumnLineGraph } from '@/components';
import Table, { ColumnType } from 'antd/lib/table';

function formatRevenues(s?: number | null) {
  return `${s?.toFixed(2)} $`;
}

const columns: ColumnType<{
  app: string;
  impressions: number;
  revenues: number;
}>[] = [
    { key: 'app', title: 'App', render: (_, line) => line.app },
    { key: 'impressions', title: 'Impressions', dataIndex: 'impressions' },
    { key: 'revenues', title: 'Revenues', render: (_, line) => formatRevenues(line.revenues) },
  ];

export function HomeBidderPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    date: string;
    impressions: number;
    revenues: number;
  }[]>([]);
  const [topApps, setTopApps] = useState<{
    app: string;
    impressions: number;
    revenues: number;
  }[]>([]);

  function updateAdEventsByMinute() {
    setLoading(true);

    homeService.getImpressionRevenueBidder().then((data) => {
      if (data) {
        const { last7days, topApps } = data;
        setData(last7days);
        setTopApps(topApps);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setData([]);
    });
  }

  useEffect(updateAdEventsByMinute, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '8px' }}>
        <ColumnLineGraph data={data} xKey='date' barKey='impressions' lineKey='revenues' title='Bidder' formatLineKey={formatRevenues} />
      </div>
      <div style={{ marginTop: '16px ' }}>
        <div><h5>Top 5 apps</h5></div>
        <Table columns={columns} dataSource={topApps} pagination={false} />
      </div>
    </Spin>
  )
}