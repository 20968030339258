import { BaseService } from "../base.service";

class MonitoringService_SA extends BaseService {

  PATH = "superadmin/monitoring/";

  async getAdEventsPerMinute(): Promise<{ time: string; nbEvents: number; perSecond: number; }[]> {
    return await super._listing(this.PATH + "ad-events-by-minute");
  }
}

export const monitoringService_SA = new MonitoringService_SA();