import { BaseService } from "../base.service";
import { MUser } from '../../modeles';


class UsersService_SA extends BaseService {

  PATH = "superadmin/users/";

  async edit(id: number, data: any): Promise<MUser | null> {
    return await super._edit(this.PATH + "edit/" + id, data);
  }

}

export const usersService_SA = new UsersService_SA();