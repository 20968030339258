import { Upload, message, Table, Button } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { MCampaign, MCampaignAsset } from '../../../../modeles';
import moment from 'moment';

import './campaign-assets.scss';
import { ColumnType } from 'antd/lib/table';
import { FilterTool } from '@/tools';
import { useAuths } from '@/contexts/auths.context';

export function YcCampaignAssets(props: {
  campaign: MCampaign,
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
  beforeUpload: (file: File) => Promise<boolean>,
  path: string,
  render: (asset: MCampaignAsset) => JSX.Element,
  dataKey: string
  requirements: React.ReactNode;
}) {
  const [loading, _setLoading] = useState(false);
  const { user } = useAuths();

  const columns: ColumnType<MCampaignAsset>[] = [
    ...(user?.isSuperAdmin ? [{ title: 'Id', key: 'id', render(txt, rec) { return rec.id; } }] : []),
    {
      title: 'Asset', key: 'asset',
      render: (text: string, record: MCampaignAsset) => (
        <div key={record.id} className='asset'>
          {props.render(record)}
        </div>
      ),
      sorter: (a, b) => a.Document?.originalFileName.localeCompare(b.Document?.originalFileName || '') || -1,
    },
    {
      title: 'Name', key: 'name',
      render: (text: string, record: MCampaignAsset) => (
        <div>{record?.Document?.originalFileName}</div>
      ),
      sorter: (a, b) => a.Document?.originalFileName.localeCompare(b.Document?.originalFileName || '') || -1,
    },
    {
      title: 'Created At', key: 'createdAt',
      render: (text: string, record: MCampaignAsset) => (
        <>
          {moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </>
      ),
      sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
    },
    {
      title: 'Validation', dataIndex: 'isValide', key: 'isValide',
      sorter: (a: MCampaignAsset, b: MCampaignAsset) => (String(a.isValide) || '').localeCompare(String(b.isValide) || ''),
      render: (text: string, record: MCampaignAsset) => (
        <>
          {record.isValide === undefined && <><LoadingOutlined /> <b>Waiting validation</b></>}
          {record.isValide === true && <><CheckOutlined style={{color: 'green' }} /> <b>Valid</b></>}
          {record.isValide === false && <><CloseOutlined style={{color: 'red' }} /> <b>Invalid</b></>}
        </>
      ),
      filters: FilterTool.BOOL_filters('Validated', 'Invalidated', 'Waiting validation'),
      onFilter: FilterTool.BOOL_onFilter('isValide'),
    },
    {
      title: 'Status', dataIndex: 'isActive', key: 'isActive',
      sorter: (a: MCampaignAsset, b: MCampaignAsset) => Number(a.isActive) - Number(b.isActive),
      render: (text: string, asset: MCampaignAsset) => 
        <div className="block-bt link-withicon" onClick={async () => {
              asset.isActive = !asset.isActive;
              const c: any = { ...props.campaign };
              c[props.dataKey] = [...c[props.dataKey]];
              props._setCampaign(c);
            }}>
          {asset.isActive && <div><PlayCircleOutlined style={{color: 'green' }} /> <b>Active</b><PauseCircleOutlined style={{color: 'red' }} className="lw-icon"/></div>}
          {!asset.isActive && <div><PauseCircleOutlined style={{color: 'red' }} /> <b>Inactive</b><PlayCircleOutlined  style={{color: 'green' }}  className="lw-icon"/></div>}
        </div>,
      filters: FilterTool.BOOL_filters('Active', 'Inactive'),
      onFilter: FilterTool.BOOL_onFilter('isActive'),
    },
  ];

  function onChange(info: any) {
    if (info.file.status === 'uploading') {
      _setLoading(true);
    } else if (info.file.status === 'done') {
      _setLoading(false);
      const c: any = { ...props.campaign };
      c[props.dataKey] = [new MCampaignAsset(info.file.response.data), ...c[props.dataKey]];
      props._setCampaign(c);
    } else if (info.file.status === 'error') {
      _setLoading(false);
      message.error(info.file.response.message);
    }
  };

  function getAssets(): MCampaignAsset[] {
    const c: any = props.campaign;
    return c[props.dataKey];
  }

  return (
    <div className="c-campaign-assets">
      <div style={{marginBottom: '16px'}}>
        {props.requirements}
      </div>
      <Upload
        name='asset'
        beforeUpload={props.beforeUpload}
        onChange={onChange}
        headers={{ "authorization": localStorage.getItem("user-token") || "" }}
        showUploadList={false}
        action={`${process.env.REACT_APP_API_URL}/advertising/campaigns/upload-${props.path}${!!props.campaign.key ? '/' + props.campaign.key : ''}`}
      >
        <Button className='mb-3' shape="round" loading={loading} icon={<PlusOutlined />}>
          Upload
        </Button>
      </Upload>
      <Table bordered size='small' dataSource={getAssets()} columns={columns} rowKey={(e) => e?.id || 'a'}></Table>
    </div >
  );
}