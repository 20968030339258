import { YcEnums } from "yc-interfaces";
import { BaseService } from "../base.service";

class AdsService_SA extends BaseService {

  PATH = "superadmin/ads/";

  async listing({
    country, city, bundle, placementType, os, osVersion, hasIfa, adPhase, bidAbTest, isTestDevice, ifa, deviceApps, deviceAds,
  }: {
    country: string;
    city: string;
    bundle: string;
    placementType: YcEnums.PlacementTypes;
    os: YcEnums.OS;
    osVersion: string | null;
    hasIfa: boolean;
    adPhase: YcEnums.AdPhases | null;
    bidAbTest: YcEnums.BidAbTests;
    isTestDevice: boolean;
    ifa: string | null;
    deviceApps: Array<string>;
    deviceAds: Array<{ id: string; impressions: number; clicks: number; installs: number; }>;
  }): Promise<any> {
    return await super._listingPost(this.PATH + "listing", {
      country, city, bundle, placementType, os, osVersion, hasIfa, adPhase, bidAbTest, isTestDevice, ifa, deviceApps, deviceAds,
    });
  }

}

export const adsService_SA = new AdsService_SA();