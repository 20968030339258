import { YcEnums } from "yc-interfaces";
import { UtilTools } from "../tools"

const bidderConfigEndpoint = {
  url: '',
  isActive: false,
  requestPercent: 0,
  filters: {
    adTypes: { includes: [], excludes: [] },
    platforms: { includes: [], excludes: [] },
    countries: { includes: [], excludes: [] },
    publishers: { includes: [], excludes: [] },
    bundles: { includes: [], excludes: [] },
  },
  testIfas: [],
};

export class MBidderConfig {
  id: number = 0;
  name: string = '';
  isActive: boolean = false;
  endpoints: {
    [endpoint in YcEnums.ExEndpoints]: {
      url: string;
      isActive: boolean;
      requestPercent?: number;
      filters: {
        adTypes?: { includes?: Array<YcEnums.AdTypes>; excludes?: Array<YcEnums.AdTypes>; };
        platforms?: { includes?: Array<YcEnums.OS>; excludes?: Array<YcEnums.OS>; };
        countries?: { includes?: Array<string>; excludes?: Array<string>; };
        publishers?: { includes?: Array<string>; excludes?: Array<string>; };
        bundles?: { includes?: Array<string>; excludes?: Array<string>; };
      };
      testIfas?: Array<string>;
    };
  } = {
    EU: UtilTools.deepCopy(bidderConfigEndpoint),
    US_EAST: UtilTools.deepCopy(bidderConfigEndpoint),
    US_WEST: UtilTools.deepCopy(bidderConfigEndpoint),
    APAC: UtilTools.deepCopy(bidderConfigEndpoint),
  };
  createdAt?: Date;
}
