import { Form, Input, Button, Switch, Slider, Card, Tag, Collapse } from 'antd';
import { YcFieldCheck, YcTitle } from '@/components';

import './bidder-config.page.scss';
import { useBidderConfig } from './use-bidder-config';
import { usePageTitle } from '@/hooks/use-title';

const { Panel } = Collapse;

export function BidderConfigPage() {
  usePageTitle('Bidder - Settings');

  const {
    isReady,
    bidderConfig,
    endpoints,
    onFinish,
    onSetBidderFilters,
    onChangeTestIfa,
    onAddTestIfa,
    onRemoveTestIfa,
    adTypes,
    platforms,
    countries,
    publishers,
    bundles,
    testIfas,
    testIfa,
  } = useBidderConfig();

  return (
    <div id="bidding-bidder-config">
      {isReady && (
        <>
          <YcTitle links={[{ link: '/bidding/bidder-config', label: 'Bidder - Settings' }]} label={bidderConfig.name}></YcTitle>
          <Form onFinish={onFinish} layout="vertical">
            <Card title="Bidder setup" size="small" className="mb-3">
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name required' }]} initialValue={bidderConfig.name}>
                <Input type="text" />
              </Form.Item>
              <Form.Item
                label="Activate bidding"
                name="isActive"
                rules={[{ required: true, message: 'Activate bidding required' }]}
                valuePropName="checked"
                initialValue={bidderConfig.isActive}
              >
                <Switch />
              </Form.Item>
            </Card>
            <Collapse defaultActiveKey={endpoints.filter((endpoint) => bidderConfig.endpoints[endpoint].url)}>
              {endpoints.map((endpoint) => (
                <Panel header={endpoint} key={endpoint}>
                  <div className="row">
                    <div className="col-6">
                      <Card title="Endpoint setup" size="small">
                        <Form.Item label={endpoint + ' endpoint url'} name={['endpoints', endpoint, 'url']} initialValue={bidderConfig.endpoints[endpoint].url}>
                          <Input type="text" />
                        </Form.Item>
                        <div className="row">
                          <div className="col-8">
                            <Form.Item
                              label="Traffic"
                              name={['endpoints', endpoint, 'requestPercent']}
                              initialValue={bidderConfig.endpoints[endpoint].requestPercent}
                            >
                              <Slider marks={{ 0: '0%', 20: '20%', 40: '40%', 60: '60%', 80: '80%', 100: '100%' }} />
                            </Form.Item>
                            <div style={{ marginBottom: '50px' }}>
                              Your bidder endpoint receive <b>{bidderConfig.endpoints[endpoint].requestPercent}%</b> of total available bid requests.
                            </div>
                          </div>
                          <div className="col-4">
                            <Form.Item
                              label="Activate bidding"
                              name={['endpoints', endpoint, 'isActive']}
                              valuePropName="checked"
                              initialValue={bidderConfig.endpoints[endpoint].isActive}
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                        <Form.Item label="Device IFAs">
                          <div>
                            {testIfas[endpoint].map((ifa: any) => (
                              <Tag key={endpoint + '-tag-' + ifa} className="mb-2" closable onClose={() => onRemoveTestIfa(ifa, endpoint)}>
                                {ifa}
                              </Tag>
                            ))}
                          </div>
                          <Input.Group compact>
                            <Input
                              placeholder="ifa"
                              type="text"
                              value={testIfa[endpoint]}
                              onChange={(e) => onChangeTestIfa(e.target.value, endpoint)}
                              onPressEnter={(e) => {
                                onAddTestIfa(testIfa[endpoint], endpoint);
                                e.preventDefault();
                              }}
                              style={{ width: '350px' }}
                            />
                            <Button type="primary" onClick={() => onAddTestIfa(testIfa[endpoint], endpoint)}>
                              Add
                            </Button>
                          </Input.Group>
                        </Form.Item>
                        <div style={{ marginBottom: '50px' }}>
                          Your bidder will be setup with highest chance to win the auction on bid requests from your test devices.
                        </div>
                      </Card>
                    </div>
                    <div className="col-6">
                      <Card title="Bid request filters" size="small">
                        <Form.Item label="Include or exclude ad types">
                          <YcFieldCheck
                            is={true}
                            hideIs={true}
                            label="Ad types"
                            mode="multiple"
                            items={adTypes[endpoint]}
                            onChange={(adTypes): void => {
                              onSetBidderFilters({ key: 'adTypes', items: adTypes, endpoint });
                            }}
                          ></YcFieldCheck>
                        </Form.Item>
                        <Form.Item label="Include or exclude platforms">
                          <YcFieldCheck
                            is={true}
                            hideIs={true}
                            label="Platforms"
                            mode="multiple"
                            items={platforms[endpoint]}
                            onChange={(platforms): void => {
                              onSetBidderFilters({ key: 'platforms', items: platforms, endpoint });
                            }}
                          ></YcFieldCheck>
                        </Form.Item>
                        <Form.Item label="Include or exclude countries">
                          <YcFieldCheck
                            is={true}
                            hideIs={true}
                            label="Countries"
                            mode="multiple"
                            items={countries[endpoint]}
                            onChange={(countries): void => {
                              onSetBidderFilters({ key: 'countries', items: countries, endpoint });
                            }}
                          ></YcFieldCheck>
                        </Form.Item>
                        <Form.Item label="Include or exclude publishers">
                          <YcFieldCheck
                            is={true}
                            hideIs={true}
                            label="Publishers"
                            mode="multiple"
                            items={publishers[endpoint]}
                            onChange={(publishers): void => {
                              onSetBidderFilters({ key: 'publishers', items: publishers, endpoint });
                            }}
                          ></YcFieldCheck>
                        </Form.Item>
                        <Form.Item label="Include or exclude bundles">
                          <YcFieldCheck
                            is={true}
                            hideIs={true}
                            label="Bundles"
                            mode="multiple"
                            items={bundles[endpoint]}
                            onChange={(bundles): void => {
                              onSetBidderFilters({ key: 'bundles', items: bundles, endpoint });
                            }}
                          ></YcFieldCheck>
                        </Form.Item>
                      </Card>
                    </div>
                  </div>
                </Panel>
              ))}
            </Collapse>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="mt-4">
                {!bidderConfig.id && <>Create Bidder Settings</>}
                {!!bidderConfig.id && <>Edit Bidder Settings</>}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}
