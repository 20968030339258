import { usePageTitle } from '@/hooks/use-title';

import { YcContactUsBlock, YcFooter, YcHeaderTitle } from '@/components';
import { LogoMP, LogoYSO, LogoYSON, Plus } from '@/assets/images';

import './about.page.scss';

export function About() {
  usePageTitle('About Us');


  const headerTitle = <h1>About Us</h1>

  return (
    <div id="root-about">
      <YcHeaderTitle title={headerTitle} />

      <div className='us d-flex mt-5 mx-1 row'>
        <div className='brands-logo col-3'>
          <img src={LogoYSO} alt="Logo YSO Corp" />
          <img className='ico-plus' src={Plus} alt="plus" />
          <img src={LogoYSON} alt="Logo YSO Network" />
          <img className='ico-plus' src={Plus} alt="plus" />
          <img src={LogoMP} alt="Logo Monster Playable" />
        </div>

        <div className='col-9 description'>
          <h1>We are YSO Corp</h1>
          <p>
            We are an innovative company specializing in the development of technology solutions across three main products and areas of expertise<br/>
            Video Games - Playables - Network Advertiser<br/>
            We are edging ourselves, as we believe, to meet players around the world to entertain themselves through gaming.<br/>
            We are here to inspire, conquer and make the best profits for our customers in marketing.<br/>
            We are specialised in the creation of tailor-made solutions that offer creativity and success with cutting-edge technologies.
          </p>
          <h2>We are your change!</h2>
        </div>
      </div>

      <YcContactUsBlock />

      <YcFooter />

    </div>
  );
}
