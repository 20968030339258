import { MApp } from ".";
import { e_PlacementType } from "../enums";

export class MPlacement {
  id: number = 0;
  applicationKey?: string;
  type?: e_PlacementType;
  name: string = "";
  key: string = "";
  requests: number = 0; 
  responses: number = 0; 
  impressions: number = 0;
  ecpm: number = 0;
  revenue: number = 0;
  realrevenue: number = 0;
  isActive: boolean = true;
  createdAt?: Date;
  Application?: MApp;
}
