import { Form, Button, Checkbox, Input } from 'antd';
import { MUser } from '@/modeles';

import { YcTitle } from '@/components';
import { useAuths } from '@/contexts/auths.context';

export interface OtherUserProps<T> {
  user: MUser;
  onFinish: (values: T) => void | Promise<void>;
}

export function OtherUser<T>({ user, onFinish }: OtherUserProps<T>) {
  const { isAdmin, isSuperAdmin } = useAuths();

  return (
    <div id='root-user'>
      <YcTitle links={[{ link: "/users", label: "Users" }]} label={user.email}></YcTitle>
      <Form onFinish={onFinish} layout="vertical">
        {!user.id && <div className='row'>
          <div className='col-4'>
            <Form.Item label="Email" name="email" rules={[{ required: !user.id }]} initialValue={user.email}>
              <Input type="text" />
            </Form.Item>
          </div>
          <div className='col-4'>
            <Form.Item label="Password" name="password" rules={[{ required: !user.id }]}>
              <Input type="text" />
            </Form.Item>
          </div>
        </div>}
        <div className='row'>
          {isAdmin() && <div className='col-4'>
            <Form.Item label="Admin" name="isAdmin" initialValue={user.isAdmin} valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>}
          {(isSuperAdmin() && user.accountId === 1) && <div className='col-4'>
            <Form.Item label="Super Admin" name="isSuperAdmin" initialValue={user.isSuperAdmin} valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>}
        </div>
        <Form.Item>
          <Button type="primary" htmlType='submit' className='mt-4'>
            {!user.id && <>Create User</>}
            {!!user.id && <>Edit User</>}
          </Button>
        </Form.Item>
      </Form>
    </div >
  );
}