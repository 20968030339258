import { Link } from 'react-router-dom';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';

import { ApplicationCreateMetadataType } from './types';

export function ApplicationCreateMetadata({ metadata }: ApplicationCreateMetadataType) {
  return (
    <div>
      {metadata.platform === 'ios' ? <AppleOutlined /> : <AndroidOutlined />}
      <Link className='mx-2' to={`/apps/app/${metadata.platform}-${metadata.bundleId}`}>{metadata.name}</Link>
    </div>
  );
}