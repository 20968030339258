import { useEffect, useState } from 'react';
import { Checkbox, Dropdown, Menu, Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { YcColumnType } from './table';

const { SubMenu } = Menu;

interface Props<T extends object = any> {
  tableKey: string;
  onChange(hidedFields: string[]): void;
  columns: YcColumnType<T>[];
};

function setLocalStorage(key: string, hidedFields: string[]) {
  localStorage.setItem("yctable-" + key, JSON.stringify(hidedFields));
}

function getLocalStorage(key: string): string[] {
  return JSON.parse(localStorage.getItem("yctable-" + key) || "[]");
}

export function YcTableHideMenu<T extends object = any>({ columns, tableKey, onChange }: Props<T>) {
  const [hiddenFields, _setHiddenFields] = useState(getLocalStorage(tableKey));
  const [isOpen, _setIsOpen] = useState(false);

  useEffect(() => {
    onChange(hiddenFields);
  }, [hiddenFields, onChange]);

  function showField(item: YcColumnType<T>) {
    const index = hiddenFields.indexOf(item.key as string);
    if (index !== -1) {
      hiddenFields.splice(index, 1);
    }
  }

  function hideField(item: YcColumnType<T>) {
    const index = hiddenFields.indexOf(item.key as string);
    if (index === -1 && item.key) {
      hiddenFields.push(item.key as string);
    }
  }

  function onToggleField(item: YcColumnType<T>, parent?: YcColumnType<T>) {
    const index = hiddenFields.indexOf(item.key as string);
    if (index !== -1) {
      hiddenFields.splice(index, 1);
      if (item.children) {
        item.children.forEach(child => {
          showField(child);
        })
      }
      if (parent) {
        showField(parent);
      }
    } else if (item.key) {
      hiddenFields.push(item.key as string);
      if (item.children) {
        item.children.forEach(child => {
          hideField(child);
        })
      }
      if (parent && parent.children) {
        let allHidden = true;
        parent.children.forEach(child => {
          const childIndex = hiddenFields.indexOf(child.key as string);
          if (childIndex === -1) {
            allHidden = false;
          }
        })
        if (allHidden) {
          hideField(parent);
        }
      }
    }
    const copy = [...hiddenFields];
    _setHiddenFields(copy)
    onChange(copy);
    setLocalStorage(tableKey, hiddenFields);
  }

  function onVisibleChange(visible: boolean) {
    _setIsOpen(visible);
  }

  return (<Dropdown
    onVisibleChange={onVisibleChange}
    visible={isOpen}
    trigger={['click']}
    overlay={
      <Menu mode="inline">
        {columns.map((item: any) => (
          item.children ?
            <SubMenu key={item.key || item.index} title={
              <Checkbox
                disabled={item.ycCanNotHide}
                checked={hiddenFields.indexOf(item.key) === -1}
                onChange={e => onToggleField(item)}
              >
                {item.title}
              </Checkbox>
            }>
              {item.children.map((child: any) => (
                <Menu.Item key={child.key || child.index}>
                  <Checkbox
                    disabled={child.ycCanNotHide}
                    checked={hiddenFields.indexOf(child.key) === -1}
                    onChange={e => onToggleField(child, item)}
                  >
                    {child.title}
                  </Checkbox>
                </Menu.Item>
              ))}
            </SubMenu> :
            <Menu.Item key={item.key || item.index}>
              <Checkbox
                disabled={item.ycCanNotHide}
                checked={hiddenFields.indexOf(item.key) === -1}
                onChange={e => onToggleField(item)}
              >
                {item.title}
              </Checkbox>
            </Menu.Item>
        ))}
      </Menu>
    }
  >
    <Button
      type="default"
      size={"small"}
      icon={<UnorderedListOutlined />}
    >
      Columns
    </Button>
  </Dropdown>)
}
