import { BaseService } from "./base.service";

class HomeService extends BaseService {
  async getInstallsSpendAdvertiser(bundleId?: string, platform?: string): Promise<{
    last7days: { date: string; installs: number; spend: number; }[];
    topCampaigns: { campaign: string; installs: number; spend: number; }[];
  } | null> {
    return await super._view("home/advertiser", { bundleId, platform });
  }
  async getImpressionRevenuePublisher(bundleId?: string, platform?: string): Promise<{
    last7days: { date: string; impressions: number; revenues: number; }[];
    topPlacements: { app: string; impressions: number; revenues: number; }[];
  } | null> {
    return await super._view("home/publisher", { bundleId, platform });
  }
  async getImpressionRevenueBidder(bundleId?: string, platform?: string): Promise<{
    last7days: { date: string; impressions: number; revenues: number; }[];
    topApps: { app: string; impressions: number; revenues: number; }[];
  } | null> {
    return await super._view("home/bidder", { bundleId, platform });
  }
}

export const homeService = new HomeService();